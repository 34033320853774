import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import $ from "jquery";
import { PROPS_TO_INCLUDE_IN_CANVAS } from "./constants/index";
import CapLogo from './images/CAP_Logo.svg'
import { removeStates, removeStates2, addToHistory, addToHistory2} from "./components/CanvasHistory";
import GrowExample from './components/spinner'
import jwt_decode from "jwt-decode";
import { fabric } from "fabric";
import LoadingOverlay from 'react-loading-overlay';
import {Row, Container, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import { getCanvasBySide } from "./components/Helpers";
import { Tabs, Tab, TabList } from "react-web-tabs";
import { productTypes, LOGO_URL } from "./constants";
import FabricCanvas from "./components/FabricCanvas";
import Toolbar from "./components/Toolbar";
import LeftPanel from "./components/LeftPanel";
import logo from "./images/CAP_Logo.png";
import config from "../config";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { templates } from "./constants";
import { getObjectByPropValue } from "./components/Helpers";
import {WP_LOGIN, CartLogin}  from './constants/index'
import html2canvas from 'html2canvas';
import { BsFillLayersFill } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import "./App.scss";
import "./styles/Navbar.scss";
import "./styles/TabView.scss";
import "./styles/LeftSidePanel.scss";
import "./styles/Footer.scss";
import "./styles/FabricCanvas.scss";
import "./styles/Toolbar.scss";
import "./components/modal.css";
import "./styles/Responsivemedia.scss";
import 'react-toastify/dist/ReactToastify.css';

fabric.Canvas.prototype.aspectRation = 1;

const mapStateToProps = (props) => {
    return {
        cal: props.cal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        increment: () => dispatch({ type: "INCREMENT" }),
        decrement: () => dispatch({ type: "DECREMENT" }),
    };
};
class App extends Component {
    constructor(props) {
        super(props);
        this.setMask = React.createRef();
        this.state = {
            allCanvasList: [],
            isMasking: false,
            canvas: null,
            canvasObj: [],
            setImage: null,
            isSnap: false,
            maskTab: false,
            isOverlap: false,
            productType: null,
            bookmarkType: "1",
            bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
            isGrid: false,
            sidebarWidth: 367,
            canvaswidth: "",
            canvasheight: "",
            fontBoldValue: "normal",
            fontStrikeValue: "",
            fontItalicValue: "normal",
            fontUnderlineValue: "",
            collapse: true,
            gridsize: 30,
            imggallery: [],
            top: "",
            isOpen: false,
            currentBookmark: "",
            templateKey: null,
            activeTab: "",
            toolbarVisible: false,
            toggle: false,
            nameToggle: false,
            editNameToggle: false,
            localKey: null,
            designData: null,
            idData: null,
            projectName: null,
            projectData: null,
            projectType: null,
            type: null,
            editId: null,
            isUsed: false,
            usedWord: null,
            userObject: {},
            bullet: false,
            prevZoom: null,
            prodId: null,
            imageObjects : [],
            selectedImages : [],
            canvasPreview : false,
            previewImage : '',
            isLoader : true,
            appLoader : false,
            loaderMessage : '',
            adminCanvasData : {},
            isAdmin : false,
            backgroundColor : '',
            sortedArray : [],
            draglayer : false,
            cartWarning : false,
            saveCanvasZoom : {
                canvasWidth : '',
                canvasHeight : '',
                zoomlevel : ''
            },
            cartLoader : false,
            continueButton : false,
            imageIds : [],
            previewLoader : []
        };
        this.currentBookmark = this.currentBookmark.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setMasking = this.setMasking.bind(this);
    }

    handleCallbackResponse(response) {
        var userObject = jwt_decode(response.credential);
        this.setState({ userObject: userObject });
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        let userId = query.get('u')
        let designID = query.get('designId')
        let tokenUrl = query.get('token');
        fabric.enableGLFiltering = false;
        let apiDynamicUrlAddress = config.default.api.host;
        let non = query.get('non')
        // let api=`https://cnsdrive.com/lamcraft/api/shop/v1/user-profile/?user_id=${userId}&token=${tokenUrl}`
        //  axios.get(api).then((res)=>{console.log("token res",res)}).catch((err)=>{
        //     window.location.href=`https://cnsdrive.com/lamcraft/`
        //     console.log(err,"err")})    
        if (designID) {
            this.setState({ prodId: designID });
        }
        this.currentBookmark();
        if (!designID) {
            this.setState({ toggle: true })
        }
        // (async () => {
        //     let id = query.get('designId')
        //     if (id) {
        //         let api = `${apiDynamicUrlAddress}/getImage?id=${id}`

        //         await axios.get(api).then((res) => {
        //             let htName = res.data.data[0].designName;
        //             this.setState({ highlightName: htName })

        //             let response = res.data.data[0].json

        //             // console.log("Data we got from the server",res.data.data[0],res.data.data[0].zoomlevel)
        //             // console.log("Type of data we got from the server",typeof(response))
        //             let parsedResponse = response
        //             //    this.setState({idData:id})
        //             this.setState({ projectData: res.data.data[0].designName })
        //             let jsonDataFront = response.front;
        //             let jsonDataBack = response.back;

        //             //this.setState({idData:res.data.data[0]})

        //             let frontCanvas = getCanvasBySide(
        //                 this.state?.allCanvasList,
        //                 "front"
        //             );

        //             let backCanvas = getCanvasBySide(
        //                 this.state?.allCanvasList,
        //                 "back"
        //             );

        //             let json = jsonDataFront;
        //             let json2 = jsonDataBack;



        //             const newWidth = frontCanvas.getWidth()
        //             const newHeight = frontCanvas.getHeight();

        //             let scalex = newWidth / json.width;
        //             let scaley = newHeight / json.height;


        //             frontCanvas.loadFromJSON(json, () => {

        //                 if (frontCanvas.backgroundImage) {
        //                     // Need to scale background images as well
        //                     var bi = frontCanvas.backgroundImage;

        //                     let biscalex = newWidth / bi.width;
        //                     let biscaley = newHeight / bi.height
        //                     bi.scaleX = biscalex;
        //                     bi.scaleY = biscaley;

        //                 }

        //                 let objects = frontCanvas.getObjects();

        //                 for (var j in objects) {


        //                     objects[j].set({
        //                         left: objects[j].left * scalex * res.data.data[0].zoomlevel,
        //                         top: objects[j].top * scaley * res.data.data[0].zoomlevel,
        //                         scaleX: objects[j].scaleX * scalex * res.data.data[0].zoomlevel,
        //                         scaleY: objects[j].scaleY * scaley * res.data.data[0].zoomlevel,

        //                     })
        //                     objects[j].setCoords();


        //                 }



        //                 if (objects) {

        //                     for (var i in objects) {


        //                         objects[i].setCoords();


        //                         if (objects[i]?.listType == "bullet" || objects[i].listType == "number") {
        //                             this.setState({ bullet: objects[i] })
        //                         }
        //                     }
        //                     frontCanvas.renderAll();

        //                     frontCanvas.calcOffset();
        //                 }

        //                 frontCanvas.renderAll();

        //                 frontCanvas.calcOffset();

        //             });





        //             backCanvas.loadFromJSON(json2, () => {


        //                 let objects1 = backCanvas.getObjects();
        //                 if (backCanvas.backgroundImage) {

        //                     // Need to scale background images as well
        //                     var bi = backCanvas.backgroundImage;

        //                     let biscalex = newWidth / bi.width;
        //                     let biscaley = newHeight / bi.height
        //                     bi.scaleX = biscalex;
        //                     bi.scaleY = biscaley;

        //                 }

        //                 for (var k in objects1) {


        //                     objects1[k].set({
        //                         left: objects1[k].left * scalex * res.data.data[0].zoomlevel,
        //                         top: objects1[k].top * scaley * res.data.data[0].zoomlevel,
        //                         scaleX: objects1[k].scaleX * scalex * res.data.data[0].zoomlevel,
        //                         scaleY: objects1[k].scaleY * scaley * res.data.data[0].zoomlevel,


        //                     })


        //                     objects1[k].setCoords();


        //                     objects1[k].setCoords();
        //                 }


        //                 for (var i in objects1) {

        //                     objects1[i].setCoords();
        //                     if (objects1[i]?.type === 'image' && objects1[i]?.filters.length) {
        //                         objects1[i].applyFilters();
        //                     }

        //                     if (objects1[i]?.listType == "bullet" || objects1[i].listType == "number") {
        //                         this.setState({ bullet: objects1[i] })
        //                     }

        //                 }


        //                 backCanvas.renderAll();
        //                 backCanvas.calcOffset();

        //             });





        //             //    document.getElementById("#my-range").innerHTML=res.data.data[0].zoomlevel;

        //             frontCanvas.requestRenderAll();
        //             backCanvas.requestRenderAll()
        //             this.state.canvas.renderAll();



        //         }).catch((err) => { console.log("Error", err) })
        //     }
        // })()

        // setInterval(() => {
        //     if (designID) {

        //         let frontCanvas = getCanvasBySide(
        //             this.state.allCanvasList,
        //             "front"
        //         );

        //         let backCanvas = getCanvasBySide(
        //             this.state.allCanvasList,
        //             "back"
        //         )

        //         // frontCanvas.backgroundImage.src="hello"
        //         // backCanvas.backgroundImage.src="hello"

        //         let frontJson = frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
        //         let backJson = backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
        //         let jsonCanvas = { front: frontJson, back: backJson };
        //         //   console.log(jsonCanvas,"update") 
        //         // console.log("Json data before update api",jsonCanvas)  

        //         let api = `${apiDynamicUrlAddress}/updateImage`
        //         let zoomlevel2 = frontCanvas?.getZoom();




        //         if (zoomlevel2) {
        //             axios.post(api, {
        //                 id: designID,
        //                 json: jsonCanvas,
        //                 designName: this.state.projectData,
        //                 zoomlevel: zoomlevel2
        //             }).then((res) => {

        //                 const date = new Date();
        //                 let autoTime = date.toLocaleString();

        //                 console.log("Auto Save Complete ")
        //                 document.getElementById('autoSaveMessage').innerHTML = 'Autosaved..' + autoTime
        //                 document.getElementById('autoSaveMessage').style.display = 'block';
        //                 let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`
        //                 axios.get(apiUrl2).then((response) => {
        //                     this.setState({ designData: response.data.data })
        //                 })

        //                 setTimeout(() => {
        //                     document.getElementById('autoSaveMessage').style.display = 'none';

        //                 }, (2000))


        //             }).catch(function (error) {
        //                 console.log("update error:", error);
        //             })
        //         }
        //     }

        // }, 20000);

        // let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`
        // await axios.get(apiUrl2).then((response) => {
        //     this.setState({ designData: response.data.data })
        // }).catch((err) => {
        //     console.log(err)
        // })
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const query = new URLSearchParams(window.location.search);
        let userId = query.get("u");
        if (prevState.canvas !== this.state.canvas) {
            this.state.canvas.off("selection:created", this.funcSelectionCreated);
            this.state.canvas.on("selection:created", this.funcSelectionCreated);
            this.state.canvas.off("selection:updated", this.funcSelectionUpdated);
            this.state.canvas.on("selection:updated", this.funcSelectionUpdated);
            this.state.canvas.off("selection:cleared", this.funcSelectionCleared);
            this.state.canvas.on("selection:cleared", this.funcSelectionCleared);
            this.setState({
                sortedArray : this.state.canvas._objects
            })
        }
        if (prevState.usedWord !== this.state.usedWord) {
            let apiDynamicUrlAddress = config.default.api.host;

            let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`;
            await axios.get(apiUrl2).then((response) => {
                this.setState({ designData: response.data.data });
            });
            let savedName = await this.state.designData.filter((item) => {
                return item.designName == this.state.usedWord;
            });
            if (savedName.length > 0) {
                this.setState({ isUsed: true });
            } else {
                this.setState({ projectName: this.state.usedWord });
                this.setState({ isUsed: false });
            }
        }
    };

    funcSelectionCreated = () => {
        this.setState({ toolbarVisible: true });
        this.setState({
            sortedArray : this.state.canvas._objects
        })
        this.state.canvas.requestRenderAll();
    }

    funcSelectionUpdated = () => {
        this.setState({ toolbarVisible: true });
        this.state.canvas.requestRenderAll();
    }

    funcSelectionCleared = () => {
        this.setState({ toolbarVisible: false });
        this.state.canvas.requestRenderAll();
    }

    currentBookmark = async (currentLocc = null) => {
        const query = new URLSearchParams(window.location.search);
        let template_key = query.get("t");
        const type = query.get("type");
        const pid = query.get("pid");
        const userId = query.get("u");
        const token = query.get("token");
        const cs = query.get("cs");
        let currentLoc = this.state?.productType
            ? this.state.productType
            : type;
        if (!template_key) {
            const default_template = getObjectByPropValue(
                templates,
                "default",
                true
            );
            template_key = default_template["title"];
        }
        this.setState({
            currentBookmark: currentLoc,
            templateKey: template_key,
        });
        if(pid)
        {
            const headers = {
                "Content-Type": "application/json;charset=UTF-8",
            };
            let apiDynamicUrlAddress = config.default.api.host;
            let apiUrl;
            if(userId)
            {
                apiUrl = `${apiDynamicUrlAddress}/get-product-info/?product_id=${pid}&user_id=${userId}`;
            }else{
                apiUrl = `${apiDynamicUrlAddress}/get-product-info/?product_id=${pid}`;
            }
            
            try {
                const response = await axios.get(apiUrl, {
                    mode: "cors",
                    headers: headers,
                });
                const code = response.data.data.product_details.product_dimensions;   
                const dimensions = code.split('-')[1];
                const canvastype = code.split('-')[0];
                const [width, height] = dimensions.split('x').map(parseFloat);
                this.setState({
                    canvaswidth: width,
                    canvasheight: height,
                    canvastype : canvastype
                });
                if(!userId && !token && pid && JSON.parse(localStorage.getItem('canvas'))){
                    let canvas = JSON.parse(localStorage.getItem('canvas'))
                    if(pid === canvas.productId)
                    {
                        localStorage.removeItem('canvas');
                        this.setState({
                            adminCanvasData :  canvas.json,
                            isLoader : false,
                        });
                    }else{
                        let apiDynamicUrlAddress1 = config.default.api1.host;
                        let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin`;
                        try {
                            const response = await axios.get(apiUrl1, {
                                mode: "cors",
                                headers: headers,
                            });
                            if(response.data.code === 200)
                            {
                                let canvasdata = response.data.data[0].json
                                this.setState({
                                    adminCanvasData: canvasdata,
                                    isLoader: false,
                                });
                            }
                            else{
                                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                                this.setState({
                                    isLoader : false,
                                });
                            }
                        } catch (error) {
                            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    }
                }
                if (userId && token && JSON.parse(localStorage.getItem('canvas'))) {
                    let canvas = JSON.parse(localStorage.getItem('canvas'))
                    if(pid === canvas.productId && cs)
                    {
                        this.setState({
                            adminCanvasData :  canvas.json,
                            appLoader : true,
                            loaderMessage : 'Saving your Product...',
                            isLoader : false,
                        });
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            this.setState({
                                isAdmin : true
                            })
                        }
                        // Get current date and time
                        const currentDate = new Date();
                        // Format the date and time
                        const year = currentDate.getFullYear();
                        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                        const day = String(currentDate.getDate()).padStart(2, '0');
                        const hours = String(currentDate.getHours()).padStart(2, '0');
                        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
                        // Create the formatted date and time string
                        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        let payload = {
                            "userId": userId ? userId : "23",
                            "role": response.data.data.product_details.user_role[0] === "administrator" ? "admin" : "user",
                            "name":"New design",
                            "productId": pid,
                            "date" : formattedDate,
                            "json": {
                                "version": "5.3.0",
                                "objects": canvas.json.objects,
                                "background": canvas.json.background,
                                "height":  canvas.json.height,
                                "width": canvas.json.width,
                            },
                            "imageDetails" : canvas.imageDetails
                        }
                        let apiDynamicUrlAddress = config.default.api1.host;
                        let apiUrl = `${apiDynamicUrlAddress}/saveProductImage`;
                
                        try {
                            const response = await axios.post(apiUrl, payload);
                            if (response.status === 200) {
                                toast.success("Product is Saved", { position: toast.POSITION.TOP_RIGHT });
                                localStorage.removeItem('canvas');
                                this.setState({
                                    appLoader : false
                                })
                            } else {
                                toast.error("Product is not Saved", { position: toast.POSITION.TOP_RIGHT });
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }else{
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            this.setState({
                                isAdmin : true
                            })
                        }
                        let apiDynamicUrlAddress1 = config.default.api1.host;
                        let apiUrl1;
                        if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                        {
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=admin`;
                        }else{
                            apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=user`;
                        }
                        try {
                            const response = await axios.get(apiUrl1, {
                                mode: "cors",
                                headers: headers,
                            });
                            if(response.data.code === 200)
                            {
                                let canvasdata = response.data.data[0].json
                                this.setState({
                                    adminCanvasData: canvasdata,
                                    isLoader: false,
                                });
                            }
                            else{
                                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                                this.setState({
                                    isLoader : false,
                                });
                            }
                            this.imgGallery();
                        } catch (error) {
                            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    }
                }
                if (userId && token && !JSON.parse(localStorage.getItem('canvas'))) {
                    if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                    {
                        this.setState({
                            isAdmin : true
                        })
                    }
                    let apiDynamicUrlAddress1 = config.default.api1.host;
                    let apiUrl1;
                    if(userId && response.data.data.product_details && response.data.data.product_details.user_role && response.data.data.product_details.user_role[0] === "administrator")
                    {
                        apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=admin`;
                    }else{
                        apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&userId=${userId}&role=user`;
                    }
                    try {
                        const response = await axios.get(apiUrl1, {
                            mode: "cors",
                            headers: headers,
                        });
                        if(response.data.code === 200)
                        {
                            let canvasdata = response.data.data[0].json
                            this.setState({
                                adminCanvasData: canvasdata,
                                isLoader: false,
                            });
                        }
                        else{
                            toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                        this.imgGallery();
                    } catch (error) {
                        toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                        this.setState({
                            isLoader : false,
                        });
                    }
                }
                if (!userId && !token && pid && !JSON.parse(localStorage.getItem('canvas'))) {
                    let apiDynamicUrlAddress1 = config.default.api1.host;
                    let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin`;
                    try {
                        const response = await axios.get(apiUrl1, {
                            mode: "cors",
                            headers: headers,
                        });
                        if(response.data.code === 200)
                        {
                            let canvasdata = response.data.data[0].json
                            this.setState({
                                adminCanvasData: canvasdata,
                                isLoader: false,
                            });
                        }
                        else{
                            toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                isLoader : false,
                            });
                        }
                    } catch (error) {
                        toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
                        this.setState({
                            isLoader : false,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            this.setState({
                isLoader : false,
                canvaswidth: "11",
                canvasheight: "8",
            });
        }
    };
    setTemplateKey = (key) => {
        this.setState({
            templateKey: key,
        });
    };

    setActiveTab = (activeTabKey) => {
        this.setState({
            activeTab: activeTabKey,
        });
    };

    setImages = (img) => {
        this.setState({ setImage: img });
    };
    openModal = () => {
        this.setState({
            continueButton : true
        })
        const canvas = this.state.canvas
        canvas.setOverlayImage(null, canvas.renderAll.bind(canvas));
        canvas.discardActiveObject();
        canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
        const { canvasWidth, canvasHeight, zoomlevel } = this.state.saveCanvasZoom;
        canvas.setHeight(canvasHeight);
        canvas.setWidth(canvasWidth);
        canvas.setZoom(zoomlevel);
        this.childRef.getZoom(zoomlevel, canvasWidth, canvasHeight);
        canvas.renderAll();
        if(this.state.canvastype === 'photodart')
        {
            canvas.discardActiveObject()
            const canvasfrontback = document.querySelector(".canvasfrontback");
            canvasfrontback.style.background = "#f0f0f0"
            var canvasContainers = document.getElementsByClassName("canvas-container");
            if (canvasContainers.length > 0) {
            var canvasContainer = canvasContainers[0];
            canvasContainer.style.border = "0"; // Adjust the value as per your preference
            }
            var style = document.createElement('style');
            style.innerHTML = '.canvas-container:before { border: 0; }'; // Adjust the value as per your preference
            document.head.appendChild(style);
            setTimeout(() => {
                html2canvas(canvasfrontback).then(canvas => {
                    const image = canvas.toDataURL("image/png"); // Convert canvas to base64 image data URL
                    this.setState({
                        isOpen: true,
                        previewImage : image,
                        canvasPreview : true,
                    })
                });
            }, 50);
        }else{
            const dataURL = canvas.toDataURL();
            // Log or use the dataURL as needed
            this.setState({
                previewImage : dataURL,
                canvasPreview : true,
                isOpen: true
            })
        }
    };
    closeModal = () => {
        this.childRef.setbgImage(this.state.canvas)
        if(this.state.canvastype === 'photodart')
        {
            var canvasContainers1 = document.getElementsByClassName("canvas-container");
            if (canvasContainers1.length > 0) {
            var canvasContainer1 = canvasContainers1[0];
            canvasContainer1.style.border = "1px solid #C4CDD6"; // Adjust the value as per your preference
            canvasContainer1.style.borderStyle = "dashed";
            }
            var style = document.createElement('style');
            style.innerHTML = '.canvas-container:before { border: 1px solid #00b5e2; }'; // Adjust the value as per your preference
            document.head.appendChild(style);
        }
        this.setState({
            isOpen: false,
            continueButton : false
        });
    };
    closeCartWarning = () => {
        this.setState({
            cartWarning : false
        })
    }
    cartLogin = () => {
        const canvas  = this.state.canvas
        canvas.discardActiveObject();
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        // Get current date and time
        const currentDate = new Date();
        // Format the date and time
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        // Create the formatted date and time string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const serializedObjects = canvas._objects.map((obj) => {
            return {
                ...obj.toJSON(),
                isLocked: obj.isLocked ? true : false,
                src: obj.src ? obj.src : null,
                imgName : obj.imgName ?  obj.imgName : null,
                editable : obj.editable ?  obj.editable : false,
                lockMovementX: obj.lockMovementX,
                lockMovementY: obj.lockMovementY,
                lockScalingX: obj.lockScalingX,
                lockScalingY: obj.lockScalingY,
                lockRotation: obj.lockRotation,
            };
        });
        let payload = {
            "userId": "",
            "role": 'admin',
            "name":"New design",
            "productId": pid,
            "date" : formattedDate,
            "json": {
                "version": "5.3.0",
                "objects": serializedObjects,
                "background": canvas.backgroundColor,
                "height":  canvas.height,
                "width": canvas.width,
            },
            imageDetails : this.state.imageIds
        }
        localStorage.setItem('canvas',JSON.stringify(payload))
        this.setState({
            cartWarning : false,
            continueButton : false
        })
        const currentUrl = window.location.href;
        let URL = WP_LOGIN+encodeURIComponent(currentUrl)
        window.location.href = URL;
    }
    addtocart = () => {
        this.childRef.setbgImage(this.state.canvas)
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const userId = query.get("u");
        const token = query.get("token");
        if(userId && token)
        {
            this.setState({
                cartLoader : true
            })
            this.saveCanvas('addtocart')
        }else{
            this.setState({
                cartWarning : true,
                isOpen : false,
                cartLoader : false
            })
        }
    }
    productType = (event) => {
        var prodType = event.target.value;
        if (prodType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
            });
        } else {
            prodType = JSON.parse(prodType);
            this.setState({
                bookmarkComment: prodType.bookmarkComment,
                productType: prodType.value,
            });
        }
    };
    updateCanvas = (canvas) => {
        this.setState(
            {
                canvas: canvas,
            },
            () => {
                //console.log("APP updateCanvas state:::", this.state);
                //console.log("APP updateCanvas canvas:::", canvas);
            }
        );
    };

    setMasking() {
        document.getElementById("vertical-tab-nine-tab").click();
        this.setState({ isMasking: true });
    }

    setMaskingMobile() {
        document.getElementById("tab6").click();
    }
    addCanvasInCanvasLIst = (item) => {
        const existingItem = this.state.allCanvasList.find(canvasItem => canvasItem.side === item.side);
        if (!existingItem) {
        this.state.allCanvasList.push(item);
        }
    };
    updateCanvases = (side) => {
        //var arr=[];
        const item = this.state.allCanvasList?.find(
            (item) => item.side === side
        );

        this.updateCanvas(item.canvas);
        // canvases.forEach(element => {

        //     this.state.canvasObj.push(element)
        //     if(element == 'front' ){
        //         this.updateCanvas(this.state.canvasObj[0])
        //     }
        //     if(element == 'back' && element?.getElement().id == "back"){
        //         this.updateCanvas(this.state.canvasObj[1])
        //     }
        // });
    };

    updateState = (stateoptions) => {
        this.setState(stateoptions);
        //this.currentBookmark();
        //console.log("stateoptions:::", stateoptions);
        //Optimization. This is not not required here
        //this.state.canvas.setDimensions({width: this.state.canvaswidth, height: this.state.canvasheight}, {cssOnly: true});
        //this.state.canvas.setDimensions({width: this.state.canvaswidth}, {cssOnly: true});
    };

    toggleSidebar = (type) => {
        this.setState({ collapse: type });
        this.setState({
            sidebarWidth: type ? 367 : 0,
        });
    };

    export = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        const canvasdata = document.getElementById("front");
        const canvasDataUrl = canvasdata
            .toDataURL()
            .replace(/^data:image\/[^;]*/, "data:application/octet-stream"),
            link = document.createElement("a");
        fileName = fileName + ".png";
        link.setAttribute("href", canvasDataUrl);
        link.setAttribute("crossOrigin", "anonymous");
        link.setAttribute("target", "_blank");
        link.setAttribute("download", fileName);
        if (document.createEvent) {
            let evtObj = document.createEvent("MouseEvents");
            evtObj.initEvent("click", true, true);
            link.dispatchEvent(evtObj);
        } else if (link.click) {
            link.click();
        }
    };

    downloadAsJSON = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        let canvasdata = this.state.canvas.toDatalessJSON();
        let string = JSON.stringify(canvasdata);
        let file = new Blob([string], {
            type: "application/json",
        });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    setBookmark = () => {
        if (this.state.productType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
                canvaswidth: 264,
                canvasheight: 816,
            });
        } else {
            const product = productTypes[this.state.productType];
            this.setState({
                bookmarkComment: product.bookmarkComment,
                canvaswidth: product.canvaswidth,
                canvasheight: product.canvasheight,
            });
            this.closeModal();
        }
    };
    setSnap = () => {
        this.setState({
            isSnap: !this.state.isSnap,
        });
        let offstate = document.querySelectorAll("#snapswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isSnap;
        }
    };

    showhideGrid = () => {
        let isGrid = !this.state.isGrid;
        this.setState({
            isGrid: isGrid,
        });
        if (isGrid) {
            for (let i = 0; i < 650 / this.state.gridsize; i++) {
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            i * this.state.gridsize,
                            0,
                            i * this.state.gridsize,
                            650,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            0,
                            i * this.state.gridsize,
                            650,
                            i * this.state.gridsize,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
            }
        } else {
            this.clearGrid();
        }
        let offstate = document.querySelectorAll("#gridswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isGrid;
        }
        this.state.canvas.renderAll();
    };

    clearGrid = () => {
        let objects = this.state.canvas.getObjects("line");
        for (let i in objects) {
            this.state.canvas.remove(objects[i]);
        }
    };

    setOverlap = () => {
        this.setState({
            isOverlap: !this.state.isOverlap,
        });
        let offoverlap = document.querySelectorAll("#overlapswitch");
        for (let j = 0; j < offoverlap.length; j++) {
            offoverlap[j].checked = this.state.isOverlap;
        }
    };

    startNewProject = (name) => {
        let apiDynamicUrlAddress = config.default.api.host;

        let frontCanvas = getCanvasBySide(this.state.allCanvasList, "front");

        let backCanvas = getCanvasBySide(this.state.allCanvasList, "back");

        let frontJson = frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
        let backJson = backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
        // console.log(frontJson,"before posting")

        let jsonCanvas = { front: frontJson, back: backJson };
        const query = new URLSearchParams(window.location.search);
        let non = query.get("non");
        let userId = query.get("u");
        let type = query.get("type");
        let zoomlevel1 = frontCanvas?.getZoom();

        //     console.log("type of data before posting",typeof(jsonCanvas))

        let api = `${apiDynamicUrlAddress}/storeImage`;

        if (zoomlevel1) {
            axios
                .post(api, {
                    userId: userId,
                    json: jsonCanvas,
                    designName: name,
                    type: type,
                    zoomlevel: zoomlevel1,
                })
                .then((res) => {
                    //    console.log("response",res)
                    this.setState({ idData: res.data.data?.insertId });
                    this.setState({ projectData: name });

                    let url = new URL(window.location.href);

                    url.searchParams.set("designId", res.data.data?.insertId);
                    setTimeout(() => {
                        window.location.href = url.href;
                    }, 300);
                })
                .catch(function (error) {
                    console.log("post error", error);
                });
        }
    };

    imgGallery = (id) => {
        const query = new URLSearchParams(window.location.search);
        let userId = query.get("u");
        const apiDynamicUrlAddress = config.default.api1.host;
        const apiUrl = `${apiDynamicUrlAddress}/listImage`;
        let payload;
        if(id)
        {
            this.setState({
                imageIds : id
            })
            payload = {
                id : id
            }
        }else{
            payload = {
                userId : userId,
                role : this.state.isAdmin ? 'admin':'user'
            }
        }
        axios
        .post(apiUrl, payload)
        .then((response) => {
            if (response.data.code === 200) {
                this.setState({
                    selectedImages : response.data.data,
                    previewLoader : response.data.data,
                })
            }else{
                this.setState({
                    selectedImages :[],
                    previewLoader :[]
                })
            }
        })
        .catch((error) => {
            toast.error("List Image API Failed, Try again later", { position: toast.POSITION.TOP_RIGHT });
        });
    };
    previewLoaderImage = (val, previewData) => {
        if(val === 'response')
        {
            this.setState((prevState) => {
                const updatedDataImage = prevState.previewLoader.map((img) => {
                  if (img.fileName === previewData.fileName) {
                    // Update the loading state to false for matching objects
                    return {
                      ...img,
                      isLoading: false,
                      id: previewData.id,
                    };
                  }
                  return img; // Return the original object if it doesn't match
                });
                return { previewLoader: updatedDataImage };
              });
        }else{
        this.setState(prevState => ({
            previewLoader: [...prevState.previewLoader, previewData]
        }));
    }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    maskTabToggle(value) {
        this.setState({ maskTab: value });
    }

    handleClick = (value) => {
        const query = new URLSearchParams(window.location.search);
        let non = query.get("non");
        let userId = query.get("u");
        let type = query.get("type");
        this.setState({ projectType: value.type });
        var url = new URL(window.location.href);

        url.searchParams.set("type", value.type);

        query.set("designID", value.id);
        window.location.reload();

        url.searchParams.set("designId", value.id);

        window.location.href = url.href;

        //  this.setState({type:canvastype})
    };

    handleProjectDel = (item) => {
        let apiDynamicUrlAddress = config.default.api.host;
        const query = new URLSearchParams(window.location.search);
        let non = query.get("non");
        let userId = query.get("u");

        let delApi = `${apiDynamicUrlAddress}/deteleImage`;
        axios
            .delete(delApi, { data: { id: item.id } })
            .then((response) => {
                let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`;
                axios.get(apiUrl2).then((response) => {
                    this.setState({ designData: response.data.data });
                });
            })
            .catch((err) => {
                console.log("Del Error", err);
            });
    };

    editProjectName = (name) => {
        const query = new URLSearchParams(window.location.search);
        let non = query.get("non");
        let userId = query.get("u");

        let frontCanvas = getCanvasBySide(this.state.allCanvasList, "front");

        let backCanvas = getCanvasBySide(this.state.allCanvasList, "back");

        let frontJson = frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
        let backJson = backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
        // console.log(frontJson,"before posting")

        let zoomlevel = frontCanvas?.getZoom();

        let jsonCanvas = { front: frontJson, back: backJson };

        let apiDynamicUrlAddress = config.default.api.host;

        let api = `${apiDynamicUrlAddress}/updateImage`;
        axios
            .post(api, {
                id: this.state.editId,
                designName: name,
            })
            .then((res) => {
                this.setState({ projectData: name });
                let apiUrl2 = `${apiDynamicUrlAddress}/getImage?userID=${userId}`;

                axios.get(apiUrl2).then((response) => {
                    this.setState({ designData: response.data.data });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    debounce(func, timeout = 2000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    validateUsedWord = (value) => {
        this.setState({ usedWord: value });
    };

    debouncedLog = this.debounce(this.validateUsedWord, 500);
      onBack = () => {
        this.setState({
            canvasPreview : false
        })
      }
      saveCanvas = (val) => {
        const canvas  = this.state.canvas
        canvas.discardActiveObject();
        canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
        canvas.renderAll();
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const userId = query.get("u");
        const token = query.get("token");
        // Get current date and time
        const currentDate = new Date();

        // Format the date and time
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Create the formatted date and time string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        
        if(userId && token)
        {
            this.setState({
                appLoader : true,
                loaderMessage : 'Saving your Product...'
            })
            let apiDynamicUrlAddress2 = config.default.api.host;
            let apiUrl2 = `${apiDynamicUrlAddress2}/user-profile/?user_id=${userId}&token=${token}`;
            axios
            .get(apiUrl2)
            .then((response)=>{
                const serializedObjects = canvas._objects.map((obj) => {
                    return {
                        ...obj.toJSON(),
                        isLocked: obj.isLocked ? true : false,
                        src: obj.src ? obj.src : null,
                        imgName : obj.imgName ?  obj.imgName : null,
                        editable : obj.editable ?  obj.editable : false,
                        lockMovementX: obj.lockMovementX,
                        lockMovementY: obj.lockMovementY,
                        lockScalingX: obj.lockScalingX,
                        lockScalingY: obj.lockScalingY,
                        lockRotation: obj.lockRotation,
                    };
                });
                let payload = {
                    "userId": userId ? userId : "23",
                    "role": this.state.isAdmin ? "admin" : "user",
                    "name":"New design",
                    "productId": pid,
                    "date" : formattedDate,
                    "json": {
                        "version": "5.3.0",
                        "objects": serializedObjects,
                        "background": canvas.backgroundColor,
                        "height":  canvas.height,
                        "width": canvas.width,
                    }
                }
                let apiDynamicUrlAddress = config.default.api1.host;
                let apiUrl = `${apiDynamicUrlAddress}/saveProductImage`;
                let uuid = uuidv4();
                axios
                .post(apiUrl, payload)
                .then((response)=>{
                    if(response.status === 200)
                    {
                        if(val === 'addtocart')
                        {
                            let payload= {
                                userId : userId,
                                productId : pid,
                                date : formattedDate,
                                file : this.state.previewImage,
                                design_unique_id : uuid
                            }
                            let apiDynamicUrlAddress = config.default.api1.host;
                            let apiUrl = `${apiDynamicUrlAddress}/cartImage`;
                            let apiDynamicUrlAddress1 = config.default.api.host;
                            let apiUrl1 = `${apiDynamicUrlAddress1}/add-product-to-cart?product_id=${pid}&user_id=${userId}&token=${token}&quantity=1&duid=${uuid}`;
                            axios
                            .post(apiUrl, payload)
                            .then((response)=>{
                                if(response.status === 200)
                                {
                                    this.setState({
                                        appLoader : false
                                    })
                                    axios
                                    .post(apiUrl1)
                                    .then((response)=>{
                                        if(response.status === 200)
                                        {
                                            this.setState({
                                                cartLoader : false
                                            })
                                            toast.success("Product is added to Cart", { position: toast.POSITION.TOP_RIGHT });
                                            window.location.href = CartLogin;
                                        }else{
                                            toast.error("Product is not added to Cart", { position: toast.POSITION.TOP_RIGHT });
                                        }
                                    })
                                    .catch((error)=> console.log(error))
                                }else{
                                    toast.error("Product is not added to Cart", { position: toast.POSITION.TOP_RIGHT });
                                }
                            })
                            .catch((error)=> console.log(error))
                        }
                        else{
                            toast.success("Product is Saved", { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                appLoader : false
                            })
                        }
                    }else{
                        toast.error("Product is not Saved", { position: toast.POSITION.TOP_RIGHT });
                    }
                })
                .catch((error)=> console.log(error))
            })
            .catch((error)=> {
                toast.error("Session Expired", { position: toast.POSITION.TOP_RIGHT });
                // Get the current URL
                var currentURL = window.location.href;

                // Remove the "u" and "token" query parameters
                currentURL = removeQueryParam(currentURL, 'u');
                currentURL = removeQueryParam(currentURL, 'token');

                // Function to remove a query parameter from a URL
                function removeQueryParam(url, paramToRemove) {
                var urlParts = url.split('?');
                if (urlParts.length >= 2) {
                    var params = urlParts[1].split('&');
                    var newParams = [];
                    for (var i = 0; i < params.length; i++) {
                    var param = params[i].split('=');
                    if (param[0] !== paramToRemove) {
                        newParams.push(params[i]);
                    }
                    }
                    if (newParams.length > 0) {
                    return urlParts[0] + '?' + newParams.join('&');
                    } else {
                    return urlParts[0];
                    }
                } else {
                    return url;
                }
                }
                let URL = WP_LOGIN+encodeURIComponent(currentURL)
                window.location.href = URL;
            })
        }
        else{
            const serializedObjects = canvas._objects.map((obj) => {
                return {
                    ...obj.toJSON(),
                    isLocked: obj.isLocked ? true : false,
                    src: obj.src ? obj.src : null,
                    imgName : obj.imgName ?  obj.imgName : null,
                    editable : obj.editable ?  obj.editable : false,
                    lockMovementX: obj.lockMovementX,
                    lockMovementY: obj.lockMovementY,
                    lockScalingX: obj.lockScalingX,
                    lockScalingY: obj.lockScalingY,
                    lockRotation: obj.lockRotation,
                };
            });
            let payload = {
                "userId": "",
                "role": 'admin',
                "name":"New design",
                "productId": pid,
                "date" : formattedDate,
                "json": {
                    "version": "5.3.0",
                    "objects": serializedObjects,
                    "background": canvas.backgroundColor,
                    "height":  canvas.height,
                    "width": canvas.width,
                },
                imageDetails : this.state.imageIds
            }
            localStorage.setItem('canvas',JSON.stringify(payload))
            const currentUrl = window.location.href;
            let URL = WP_LOGIN+encodeURIComponent(currentUrl)
            window.location.href = URL;
        }
      }
    triggerState = () => {
        this.setState({
            triggerState : true
        })
    }
    multipleDelete = () => {
        this.setState({
            sortedArray : this.state.canvas._objects
        })
    }
    draglayer = (result) => {
        const updatedItems = [...this.state.sortedArray];
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);
        this.setState({
            sortedArray : updatedItems
        })
        const previousBgColor = this.state.canvas.backgroundColor;
        this.state.canvas.clear();
        this.state.canvas.backgroundColor = previousBgColor;
        updatedItems.forEach((item)=>{
            this.state.canvas.setActiveObject(item)
            this.state.canvas.add(item)
            this.state.canvas.renderAll()
        })
        
        if(this.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.state.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.state.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }
    deleteLayer = (object) => {
        this.state.canvas.remove(object)
        this.setState({
            sortedArray : this.state?.canvas?._objects
        })
        const canvasData = this.state?.canvas?._objects
        canvasData.forEach((item)=>{
            this.state.canvas.setActiveObject(item)
            this.state.canvas.renderAll()
        })
        if(this.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.state.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.state.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }
    scrollToTop = () => {
        const tabList = document.querySelector('.tab-list');
        if (tabList) {
          tabList.scrollTop = 0;
        }
      };
    
    scrollToEnd = () => {
    const tabList = document.querySelector('.tab-list');
    if (tabList) {
        tabList.scrollTop = tabList.scrollHeight;
    }
    };
    saveZoom = (height,width,zoom) => {
    this.setState({
        saveCanvasZoom: {
            canvasWidth: width,
            canvasHeight: height,
            zoomlevel: zoom,
        },
        });
    }
    appLoader = (load, msg) => {
        this.setState({
            appLoader : load,
            loaderMessage : msg
        })
    }
    render() {
        const {
            sidebarWidth,
            collapse,
            imggallery,
            bookmarkComment,
            productType,
            canvasPreview,
            previewImage,
            isLoader
        } = this.state;
        return (
            <>
                <ToastContainer/>
                {/* <div>
                    {this.state.toggle ||
                        this.state.nameToggle ||
                        this.state.editNameToggle ? (
                        <div
                            className="modal-backdrop  show"
                            style={{ zIndex: 1000 }}
                        ></div>
                    ) : null}
                    <div
                        className={`modal fade ${this.state.nameToggle ? "show" : null
                            }`}
                        style={{
                            display: this.state.nameToggle ? "block" : "",
                        }}
                        id="exampleModalLong"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5
                                        className="modal-title"
                                        id="exampleModalLongTitle"
                                    >
                                        Enter Project Name:
                                    </h5>
                                    <button type="button" class="close" onClick={()=>{this.setState({nameToggle:false})}}>
          <span aria-hidden="true">&times;</span>
        </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <input
                                                onChange={(event) => {
                                                    this.debouncedLog(
                                                        event.target.value
                                                    );
                                                }}
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                            />

                                            {this.state.isUsed ? (
                                                <div class="alert alert-danger alert-dismissible fade show">
                                                    The design name is already
                                                    used.
                                                </div>
                                            ) : null}
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button   type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    {this.state.projectName ? (
                                        <span
                                            style={{
                                                display: this.state.isUsed
                                                    ? "none"
                                                    : "block",
                                            }}
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                onClick={() => {
                                                    this.setState({
                                                        nameToggle: false,
                                                        toggle: false,
                                                        editNameToggle: false,
                                                    });
                                                    this.startNewProject(
                                                        this.state.projectName
                                                    );
                                                }}
                                                data-dismiss="modal"
                                            >
                                                Add
                                            </button>
                                        </span>
                                    ) : null}
                                    {this.state.prodId == null ? (
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            onClick={() => {
                                                this.setState({
                                                    editNameToggle: false,
                                                    nameToggle: false,
                                                    toggle: true,
                                                });
                                            }}
                                        >
                                            Back
                                        </button>
                                    ) : (
                                        <button
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            type="button"
                                            class="btn btn-secondary"
                                            onClick={() => {
                                                this.setState({
                                                    editNameToggle: false,
                                                    nameToggle: false,
                                                });
                                            }}
                                        >
                                            Back
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class={`modal fade ${this.state.toggle ? "show" : null
                            } `}
                        style={{ display: this.state.toggle ? "block" : "" }}
                        id="exampleModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5
                                        class="modal-title"
                                        id="exampleModalLabel"
                                    >
                                        My Projects
                                    </h5>

                                    <button onClick={()=>{
                    this.setState({toggle:false})}} id="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                                </div>
                                <div class="modal-body">
                                    <ul
                                        class="list-group"
                                        style={{
                                            height: "300px",
                                            overflow: "scroll",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        {this.state.designData?.length > 0 ? (
                                            this.state.designData?.map(
                                                (item) => {
                                                    return (
                                                        <button class="list-group-item">
                                                            <li
                                                                style={{
                                                                    listStyleType:
                                                                        "none",
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col
                                                                        onClick={() => {
                                                                            this.handleClick(
                                                                                item
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.designName
                                                                        }
                                                                    </Col>
                                                                    <Col>
                                                                        {" "}
                                                                        <span
                                                                            onClick={() => {
                                                                                this.handleProjectDel(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <i className="far fa-trash-alt text arrow" />
                                                                        </span>
                                                                        <span
                                                                            type="button"
                                                                            title="Edit Project Name"
                                                                            class="btn btn-primary mx-3"
                                                                            data-dismiss="modal"
                                                                            onClick={() => {
                                                                                this.setState(
                                                                                    {
                                                                                        editId: item.id,
                                                                                        editNameToggle: true,
                                                                                        toggle: false,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                border: "none",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                onClick={() => {
                                                                                    document
                                                                                        .getElementById(
                                                                                            "firstClose"
                                                                                        )
                                                                                        .click();
                                                                                }}
                                                                                style={{
                                                                                    marginBottom:
                                                                                        "6px",
                                                                                    height: "10px",
                                                                                    width: "12px",
                                                                                }}
                                                                                src={require("./images/pencil.png")}
                                                                                alt="Edit"
                                                                            />
                                                                        </span>
                                                                    </Col>{" "}
                                                                </Row>
                                                            </li>
                                                        </button>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <h2>You have no saved projects.</h2>
                                        )}
                                    </ul>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        id="firstClose"
                                        style={{ display: "none" }}
                                        type="button"
                                        class="btn btn-secondary"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            this.setState({ toggle: false });
                                        }}
                                    >
                                        Close
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        aria-label="Close"
                                        onClick={() => {
                                            this.setState({ nameToggle: true });
                                            document
                                                .getElementById("firstClose")
                                                .click();
                                        }}
                                    >
                                        Start New Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class={`modal fade ${this.state.editNameToggle ? "show" : null
                            } `}
                        style={{
                            display: this.state.editNameToggle ? "block" : "",
                        }}
                        id="exampleModalLong1"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle1"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5
                                        class="modal-title"
                                        id="exampleModalLongTitle1"
                                    >
                                        Enter Project Name to update:
                                    </h5>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div class="form-group">
                                            <input
                                                defaultValue=""
                                                onChange={(event) => {
                                                    this.debouncedLog(
                                                        event.target.value
                                                    );
                                                }}
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                            />

                                            {this.state.isUsed ? (
                                                <div class="alert alert-danger alert-dismissible fade show">
                                                    The design name is empty or
                                                    already used.
                                                </div>
                                            ) : null}
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    {this.state.prodId == null ? (
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            onClick={() => {
                                                this.setState({
                                                    editNameToggle: false,
                                                    nameToggle: false,
                                                    toggle: true,
                                                });
                                            }}
                                        >
                                            Back
                                        </button>
                                    ) : (
                                        <button
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            type="button"
                                            class="btn btn-secondary"
                                            onClick={() => {
                                                this.setState({
                                                    editNameToggle: false,
                                                    nameToggle: false,
                                                });
                                            }}
                                        >
                                            Back
                                        </button>
                                    )}
                                    <span
                                        style={{
                                            display: this.state.isUsed
                                                ? "none"
                                                : "block",
                                        }}
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={() => {
                                                this.setState({
                                                    editNameToggle: false,
                                                    toggle: false,
                                                    nameToggle: false,
                                                });

                                                if (
                                                    this.state.isUsed == false
                                                ) {
                                                    // console.log(this.state.projectName,this.state.editId)
                                                    this.editProjectName(
                                                        this.state.projectName
                                                    );
                                                }
                                            }}
                                            data-dismiss="modal"
                                        >
                                            Done
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <Container fluid>
                    <Row style={{ borderBottom: "1px solid grey" }}>
                        {/* <button
                            type="button"
                            class="badge badge-primary mx-1 my-1"
                            data-toggle="modal"
                            data-target="#exampleModal"
                        >
                            My Projects
                        </button> */}
                        {/* <div id="signInDiv">
          </div> */}
                        {/* <button className="mx-2 btn btn-primary" onClick={()=>{
            this.setState({userObject:{}})
            window.location.reload()}
            }>
            Sign Out
          </button> */}
                        {/* <button type="button"  class="badge badge-secondary my-1" onClick={()=>{localStorage.setItem("userId",null)}}>
                Logout
            </button> */}
                        {/* <span className="mx-1">
            <h1>
              Count:{this.props.cal}
            </h1>
          <button className="mx-1" onClick={()=>{this.props.increment()}}>Increment</button>
          <button className="mx-1"  onClick={()=>{this.props.decrement()}}>Decrement</button>
            </span> */}
                        <div style={{ height: "100px" }}>
                            {/* <div style={{ height: "4px" }}>
                                <span
                                    id="autoSaveMessage"
                                    style={{
                                        display: "none",
                                        fontSize: "12px",
                                        marginLeft: "auto",
                                    }}
                                >
                                    Autosaved...
                                </span>

                            </div> */}

                            <div className=" " style={{ width: "100%", height: "50px", position: "fixed",marginTop: "18px", marginLeft: "5px", top:"10px" }}>
                                <div style={{ height: "35px",width : "140px",position : "absolute",left : 0 }}>                                
                                    <img style={{ height: "inherit", position: "absolute", margin: "1%" }} src={CapLogo} alt="CAP" />
                                </div>
                                {$(window).width() > 568 ? (
                                    <Toolbar
                                        state={this.state}
                                        allCanvasList={this.state.allCanvasList}
                                        updateCanvas={this.updateCanvas}
                                        canvasObj={this.state.canvasObj}
                                        setMasking={this.setMasking}
                                        setImage={this.state.setImage}
                                        bullet={this.state.bullet}
                                        imageObject = {this.imageObject}
                                        imageObjects = {this.state.imageObjects}
                                        triggerState = {this.triggerState}
                                        imgGallery = {this.imgGallery}
                                        multipleDelete = {this.multipleDelete}
                                    />
                                ) : this.state.toolbarVisible ? (
                                    <Toolbar
                                        state={this.state}
                                        allCanvasList={this.state.allCanvasList}
                                        updateCanvas={this.updateCanvas}
                                        canvasObj={this.state.canvasObj}
                                        setMaskingMobile={this.setMaskingMobile}
                                        imageObject = {this.imageObject}
                                        imageObjects = {this.state.imageObjects}
                                        triggerState = {this.triggerState}
                                        imgGallery = {this.imgGallery}
                                        multipleDelete = {this.multipleDelete}
                                    />
                                ) : (
                                    <div className="mobilePanel">
                                        <a
                                            className=""
                                            style={{ marginLeft: "30%" }}
                                            href={LOGO_URL}
                                        >
                                            <img
                                                src={logo}
                                                alt=""
                                                width="300"
                                                height="60"
                                            />{" "}
                                        </a>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Row>
                    {isLoader ? 
                    <GrowExample/>
                    :
                    <Row className="main-container">
                        <div className="main-container-inner">
                            <div className="largedevice" style={{position: "absolute", zIndex: "10" }}>
                                <Tabs
                                    defaultTab="vertical-tab-five"
                                    vertical
                                    className="vertical-tabs "
                                >
                                    <TabList className='tab-list' style={{ fontSize: "15px", borderRight: "none", paddingTop: "19px", marginRight: "10px", width: "110px", alignItems: "center", position : "relative", backgroundColor : "#f0f0f0" }}>
                                        {/* <Tab
                                            tabFor="vertical-tab-six"
                                            className="lasttab"
                                            onClick={() =>
                                                this.toggleSidebar(true)
                                            }
                                        >
                                            <div className="edit-box">
                                                <i className="fas fa-palette fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    TEMPLATE
                                                </span>
                                            </div>
                                        </Tab> */}
                                        <Tab
                                            tabFor="vertical-tab-five"
                                            className="lasttab mx-2 my-2"
                                            onClick={() => {
                                                this.toggleSidebar(true);
                                                this.scrollToTop();
                                                this.setState({
                                                    isMasking: false,
                                                });
                                            }}
                                        >
                                            <div
                                                className="edit-box"
                                                id="ImageTab"
                                                style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}
                                            >
                                                <i className="fas fa-image fa-2x text-muted "></i>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Images
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-one"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                                this.toggleSidebar(true)
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-font fa-2x text-muted"></i>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Text
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-eight"
                                            className="lasttab"
                                            onClick={() =>
                                                this.toggleSidebar(true)
                                            }
                                        >
                                            <div className="edit-box"  style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-palette fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Color
                                                </span>
                                            </div>
                                        </Tab>

                                        {/* <Tab tabFor="vertical-tab-two" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-fill fa-2x text-muted"></i>
                                    <span className="sidenav">COLOR</span>
                                </div>
                            </Tab> */}
                                        <Tab
                                            tabFor="vertical-tab-three"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                                this.toggleSidebar(true)
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-images fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Clip Art
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-four"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>
                                                this.toggleSidebar(true)
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                <i className="fas fa-shapes fa-2x text-muted"></i>
                                                <span className="sidenav" style={{color : !collapse && '#697582'}}>
                                                    Shapes
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-two"
                                            className="lasttab mx-2 my-2"
                                            onClick={() =>{
                                                this.toggleSidebar(true);
                                                this.scrollToEnd();
                                            }
                                            }
                                        >
                                            <div className="edit-box" style={{border : !collapse && 'none', background : !collapse && '#e1e6ea'}}>
                                                {/* <i className="fas fa-font fa-2x text-muted"></i> */}
                                                <BsFillLayersFill className="layers-icon"/>
                                                <span className="sidenav"  style={{color : !collapse && '#697582'}}>
                                                    Layers
                                                </span>
                                            </div>
                                        </Tab>
                                        {/* {this.state.isMasking?this.toggleSidebar():null} */}
                                    </TabList>
                                    <div
                                        style={{ width: "100%" }}
                                        className="left-side-panel"
                                    >
                                        {collapse && (
                                            <LeftPanel
                                                key="leftpanel"
                                                allCanvasList={
                                                    this.state.allCanvasList
                                                }
                                                canvas={this.state.canvas}
                                                canvasObj={this.state.canvasObj}
                                                imggallery={imggallery}
                                                imgGallery={this.imgGallery}
                                                maskTabToggle={
                                                    this.maskTabToggle
                                                }
                                                currentBookmark={
                                                    this.state.currentBookmark
                                                }
                                                templateSize={{
                                                    width: this.state
                                                        .canvaswidth,
                                                    height: this.state
                                                        .canvasheight,
                                                }}
                                                templateKey={
                                                    this.state.templateKey
                                                }
                                                setTemplateKey={
                                                    this.setTemplateKey
                                                }
                                                setImages={this.setImages}
                                                toggleSidebar = {this.toggleSidebar}
                                                collapse = {this.state.collapse}
                                                imageObject = {this.imageObject}
                                                imageObjects = {this.state.imageObjects}
                                                uploadedImages={this.uploadedImages}
                                                selectedImages = {this.state.selectedImages}
                                                backgroundColor = {this.state.backgroundColor}
                                                triggerState = {this.triggerState}
                                                sortedArray = {this.state.sortedArray}
                                                draglayer = {this.draglayer}
                                                deleteLayer = {this.deleteLayer}
                                                isAdmin = {this.state.isAdmin}
                                                previewLoaderImage = {this.previewLoaderImage}
                                                previewLoader = {this.state.previewLoader}
                                            />
                                        )}
                                    </div>
                                    {/* <div
                                        className="btn-toggle"
                                        onClick={() => {
                                            this.toggleSidebar(
                                                !this.state.collapse
                                            );
                                            this.setState({ isMasking: false });
                                        }}
                                        style={{ opacity: collapse ? 1 : 0 }}
                                    >
                                        <i className="fas fa-chevron-left arrowimage"></i>
                                    </div> */}
                                </Tabs>
                            </div>

                            <LoadingOverlay
                            active={this.state.appLoader}
                            spinner
                            className="spinner-overlay"
                            text={this.state.loaderMessage}>
                            <div className="canvas-panel-canvas-area">

                                {/* Create Canvas after getting the size */}
                                {this.state.canvaswidth !== "" ? (
                                    <FabricCanvas
                                        ref={(ref) => (this.childRef = ref)}
                                        allCanvasList={this.state.allCanvasList}
                                        state={this.state}
                                        currentBookmark={this.currentBookmark}
                                        updateCanvas={this.updateCanvas}
                                        addCanvasInCanvasLIst={
                                            this.addCanvasInCanvasLIst
                                        }
                                        updateCanvases={this.updateCanvases}
                                        updateState={this.updateState}
                                        adminCanvasData = {this.state.adminCanvasData}
                                        saveCanvasZoom = {this.state.saveCanvasZoom}
                                        saveZoom = {this.saveZoom}
                                        triggerState = {this.triggerState}
                                        appLoader={this.appLoader}
                                    />
                                ) : 
                                null}
                            </div>
                            </LoadingOverlay>
                            {/*<Footer canvas={this.state.canvas}>
                        <ul className="navbar-nav ml-md-auto">
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Display Grid">Grid <input type="checkbox" id="gridswitch" />
                                    <label htmlFor="gridswitch" onClick={this.showhideGrid}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Snap to Grid">Snap <input type="checkbox" id="snapswitch" />
                                    <label htmlFor="snapswitch" onClick={this.setSnap}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Overlap">Overlap <input type="checkbox" id="overlapswitch" />
                                    <label htmlFor="overlapswitch" onClick={this.setOverlap}>Toggle</label>
                                </a>
                            </li>
                        </ul>
                    </Footer>*/}
                        </div>
                    </Row>
                    }
                    {$(window).width() < 1240 && (
                    <div className="mobilenav fixed-bottom ">
                        <Tabs>
                            <div
                                className=" fixed-bottom"
                                style={{ position: "relative", zIndex: "auto" }}
                            >
                                {collapse && (
                                    <LeftPanel
                                        key="leftpanel"
                                        allCanvasList={this.state.allCanvasList}
                                        canvas={this.state.canvas}
                                        canvasObj={this.state.canvasObj}
                                        imggallery={imggallery}
                                        imgGallery={this.imgGallery}
                                        currentBookmark={
                                            this.state.currentBookmark
                                        }
                                        templateSize={{
                                            width: this.state.canvaswidth,
                                            height: this.state.canvasheight,
                                        }}
                                        activeTab={this.state.activeTab}
                                        templateKey={this.state.templateKey}
                                        setTemplateKey={this.setTemplateKey}
                                        setActiveTab={this.setActiveTab}
                                        toggle={this.toggle}
                                        setImages={this.setImages}
                                        imageObject = {this.imageObject}
                                        imageObjects = {this.state.imageObjects}
                                        uploadedImages={this.uploadedImages}
                                        selectedImages = {this.state.selectedImages}
                                        backgroundColor = {this.state.backgroundColor}
                                        triggerState = {this.triggerState}
                                        sortedArray = {this.state.sortedArray}
                                        draglayer = {this.draglayer}
                                        deleteLayer={this.deleteLayer}
                                        isAdmin = {this.state.isAdmin}
                                        previewLoaderImage = {this.previewLoaderImage}
                                        previewLoader = {this.state.previewLoader}
                                    />
                                )}
                            </div>
                        </Tabs>
                        <nav
                            className="menu"
                            style={{
                                border: "none",
                                display: "flex",
                                overflowX: "scroll",
                                listStyle: "none",
                                height: "100px",
                            }}
                        >
                            {/** Images */}
                            <NavItem
                                className={classnames({
                                    selectedColor: this.state.activeTab === "3",
                                })}
                            >
                                <NavLink
                                    id="imageTabMobile"
                                    className={classnames({
                                        active: this.state.activeTab === "3",
                                    })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "3") {
                                            this.toggle("3");
                                        } else {
                                            this.toggle("");
                                        }

                                        this.setState({ isMasking: false });
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i
                                                className="fas fa-image fa-2x "
                                                style={{
                                                    color:
                                                        this.state.activeTab ===
                                                            "3"
                                                            ? "white"
                                                            : "grey",
                                                }}
                                            ></i>
                                        </div>
                                        <div className="side-nav">IMAGE</div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            {/** Text */}
                            <NavItem
                                className={classnames({
                                    selectedColor: this.state.activeTab === "1",
                                })}
                            >
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "1",
                                    })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "1") {
                                            this.toggle("1");
                                        } else {
                                            this.toggle("");
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i
                                                className="fas fa-font fa-2x px-1"
                                                style={{
                                                    color:
                                                        this.state.activeTab ===
                                                            "1"
                                                            ? "white"
                                                            : "grey",
                                                }}
                                            ></i>
                                        </div>
                                        <div>TEXT</div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            {/** Verse */}
                            {/* <NavItem
                                className={classnames({
                                    selectedColor: this.state.activeTab === "4",
                                })}
                            >
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "4",
                                    })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "4") {
                                            this.toggle("4");
                                        } else {
                                            this.toggle("");
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i
                                                className="fas fa-font fa-2x "
                                                style={{
                                                    color:
                                                        this.state.activeTab ===
                                                        "4"
                                                            ? "white"
                                                            : "grey",
                                                }}
                                            ></i>
                                        </div>
                                        <div>VERSE</div>
                                    </span>
                                </NavLink>
                            </NavItem> */}
                            {/** Print */}
                            {/* <NavItem
                                className={classnames({
                                    selectedColor: this.state.activeTab === "5",
                                })}
                            >
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "5",
                                    })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "5") {
                                            this.toggle("5");
                                        } else {
                                            this.toggle("");
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i
                                                className="fas fa-print fa-2x "
                                                style={{
                                                    color:
                                                        this.state.activeTab ===
                                                        "5"
                                                            ? "white"
                                                            : "grey",
                                                }}
                                            ></i>
                                        </div>
                                        <div className="">PRINT</div>
                                    </span>
                                </NavLink>
                            </NavItem> */}
                            {/** Mask Image */}
                            <NavItem
                                style={{ display: "none" }}
                                className={classnames({
                                    selectedColor: this.state.activeTab === "6",
                                })}
                            >
                                <NavLink
                                    id="tab6"
                                    className={classnames({
                                        active: this.state.activeTab === "6",
                                    })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "6") {
                                            this.toggle("6");
                                        } else {
                                            this.toggle("");
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i
                                                className="fas fa-print fa-2x "
                                                style={{
                                                    color:
                                                        this.state.activeTab ===
                                                            "5"
                                                            ? "white"
                                                            : "grey",
                                                }}
                                            ></i>
                                        </div>
                                        <div className="">Mask IMAGE</div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            {/* <Tab
                                                    ref={this.setMask}
                                                    id="mask_tab"
                                                    tabFor="vertical-tab-nine"
                                                    className="lasttab"
                                                    onClick={() => this.toggleSidebar(true)}
                                                    style={{display:"none"}}
                                                >
                                                    <div className="edit-box">
                                                        <i className="fas fa-circle fa-2x text-muted"></i>
                                                        <span className="sidenav">
                                                            Mask Image
                                                        </span>
                                                    </div>
                    </Tab> */}
                        </nav>
                    </div>
                    )}
                    {!this.state.isLoader && (
                    <span style={{ position: "fixed", bottom: 0, left: 0, width: "100%", zIndex: 999, backgroundColor: "rgb(255,255,255)", height: "60px", display: "flex", margin: "auto", textAlign: "center", boxShadow: " 0px 10px 30px rgb(50 50 50 / 75%)", justifyContent:"center" }}>
                        <button className="footer-btn my-2 btn btn-info" disabled={this.state.appLoader}>
                        <div className="footer-btn-icon">
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                            Back
                        </div>
                        </button>
                        <button className="footer-btn mx-2 my-2 btn btn-info" onClick={this.saveCanvas} disabled={this.state.appLoader}>
                        <div className="footer-btn-icon">
                            <i class="fa fa-floppy-o" aria-hidden="true"></i>
                            Save
                        </div>
                        </button>
                        <button className="continue-btn my-2 btn btn-warning" onClick={this.openModal} disabled={this.state.appLoader}>
                        <div className="footer-btn-continue-icon">
                            Continue
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                        </button>
                    </span>
                    )}
                </Container>
                {this.state.continueButton ? 
                <LoadingOverlay
                active={this.state.cartLoader}
                spinner
                className={this.state.cartLoader ? "spinner-overlay-cart spinner-overlay-cart-loader" : "spinner-overlay-cart"}
                text='Adding your Product to Cart...'>
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="xl"
                    id="productTypeModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                Preview Image
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="preview-image">
                        <div className="preview-image-inner">
                            <img src={previewImage}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.closeModal} disabled={this.state.cartLoader}>
                            Go Back and Edit Design
                        </button>
                        {!this.state.isAdmin && new URLSearchParams(window.location.search).get('pid') && 
                        <button className="btn btn-edit" onClick={this.addtocart} disabled={this.state.cartLoader}>
                            Add To Cart
                        </button>
                        }
                    </Modal.Footer>
                </Modal>
                </LoadingOverlay>
                :''}
                <Modal
                    show={this.state.cartWarning}
                    onHide={this.closeCartWarning}
                    size="default"
                    id="productTypeModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                Attention
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p>Please log in before adding to the cart.</p>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent : "center"}}>
                        <button className="btn btn-edit" onClick={this.cartLogin}>
                            Login
                        </button>
                        <button className="btn btn-edit" onClick={this.closeCartWarning}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
