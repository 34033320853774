import React, { Component } from "react";
import { fabric } from "fabric";
import { saveCanvasState, initCenteringGuidelines, initAligningGuidelines, selectObject, pixelSize} from "./Helpers";
import { addToHistory, addToHistory2, removeStates, removeStates2} from "../components/CanvasHistory";
import $ from "jquery";
import "./modal.css";
import config from "../../config";
import axios from "axios";
import UndoRedo from "./UndoRedo";
import { getCanvasBySide } from "./Helpers.js";
import { toast } from 'react-toastify';
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import Tooltip from "react-tooltip-lite";
import petmatsafearea from '../images/petmatsafearea.svg'
import dartsafearea from '../images/dartsafearea.svg'
import growthsafearea from '../images/growthsafearea.svg'
fabric.Canvas.prototype.originalWidth = undefined;
fabric.Canvas.prototype.originalHeight = undefined;
fabric.Canvas.prototype.cropArea = undefined;

class FabricCanvas extends Component {
    constructor(props) {
        super(props);
        this.mainCanvasContainer = React.createRef();
        this.state = {
            displaybgColorPicker: false,
            subtarget: null,
            frontback: "",
            canvasObj: [],
            canvas: null,
            canvas1: null,
            canvasActive: [],
            imgParam: [],
            imgdata: [],
            canvasOuterMargin: "",
            canvasWidth: 220,
            canvasHeight: 420,
            initCanvasWidth: 220,
            initCanvasHeight: 420,
            zoomLevel: 100,
            initZoomLevel: 1,
            widthInInch : '',
            heightInInch : '',
            showDimensions : false,
            getLoader : true,
            updateCanvasHeight : '',
            updateCanvasWidth : '',
            tempTop : '',
            disableClear : false
        };
        //this.map__SetZoom = this.map__SetZoom.bind(this);
    }

    updateState(e) {
        let stateoptions = {};
        if (e) {
            stateoptions = {
                fontBoldValue: e.target.fontWeight,
                fontItalicValue: e.target.fontStyle,
                fontUnderlineValue: e.target.underline,
                fontStrikeValue: e.target.linethrough,
            };
        }
        this.props.updateState(stateoptions);
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const container = this.mainCanvasContainer.current?.getBoundingClientRect();
        this.setState({
            widthInInch : this.props.state?.canvaswidth,
            heightInInch : this.props.state?.canvasheight
        })
        //Convert inches to pixel
        let canvasWidthInPixels = pixelSize(this.props.state?.canvaswidth);
        let canvasHeightInPixels = pixelSize(this.props.state?.canvasheight);
        const scale = Math.min(
            (1920 - 1920 * 0.4) / canvasWidthInPixels,
            (1080 - 1080 * 0.4) / canvasHeightInPixels
          );
        canvasWidthInPixels *= scale;
        canvasHeightInPixels *= scale;
        fabric.Canvas.originalHeight = canvasHeightInPixels;
        fabric.Canvas.originalWidth = canvasWidthInPixels;
        this.setState({
            canvasWidth: canvasWidthInPixels,
            canvasHeight: canvasHeightInPixels,
            initCanvasWidth: canvasWidthInPixels,
            initCanvasHeight: canvasHeightInPixels,
        });
        if(Object.keys(this.props.adminCanvasData).length > 0)
        {
            this.props.appLoader(true,'Loading your Product')
            let item = this.props.adminCanvasData
            if(Object.keys(item).length > 0)
            {
                this.setState({
                    showDimensions : true,
                })
                this.canvas = new fabric.Canvas("front", {
                    preserveObjectStacking: true,
                    width: item && item.width ? item.width : canvasWidthInPixels,
                    height: item && item.height ? item.height : canvasHeightInPixels,
                    backgroundColor : item && item.background ? item.background : '#fff',
                    // aspectRatio:ratio,
                });           
                this.setbgImage(this.canvas)
                if(this.props.state?.canvastype === 'photodart')
                {
                    // Apply border-radius to the canvas container
                    var canvasContainers = document.getElementsByClassName("canvas-container");
                    if (canvasContainers.length > 0) {
                    var canvasContainer = canvasContainers[0];
                    canvasContainer.style.borderRadius = "50%"; // Adjust the value as per your preference
                    }

                    // Apply border-radius to the canvas container before pseudo-element
                    var style = document.createElement('style');
                    style.innerHTML = '.canvas-container:before { border-radius: 50%; }'; // Adjust the value as per your preference
                    document.head.appendChild(style);
                }
                $("#abs1 > .canvas-title").css({ width: canvasWidthInPixels + "px" });
                $("#abs2 > .canvas-title").css({ width: canvasWidthInPixels + "px" });
                this.setState({
                    canvas: this.canvas,
                });
                this.canvas1 = new fabric.Canvas("back", {
                    preserveObjectStacking: true,
                    width: canvasWidthInPixels,
                    height: canvasHeightInPixels,
                    // aspectRatio:ratio,
                });
                this.setState({
                    canvas1: this.canvas1,
                });
                // this.state.canvasObj.push(this.canvas)
                // this.state.canvasObj.push(this.canvas1)
                this.props.addCanvasInCanvasLIst({
                    side: "front",
                    canvas: this.canvas,
                });
                this.props.addCanvasInCanvasLIst({
                    side: "back",
                    canvas: this.canvas1,
                });
                //for canvas history save - undo / redo
                this.canvas.state = [];
                this.canvas.index = 0;
                this.canvas.stateaction = true;
                this.canvas1.state = [];
                this.canvas1.index = 0;
                this.canvas1.stateaction = true;
                initCenteringGuidelines(this.canvas);
                initAligningGuidelines(this.canvas);
                initCenteringGuidelines(this.canvas1);
                initAligningGuidelines(this.canvas1);
                this.initCanvasEvents();
                this.setState({
                    displaybgColorPicker: false,
                });
                this.props.updateCanvas(this.canvas);
                this.props.updateCanvases("front");
                let canvas=this.canvas
                var ml = "-" + $("#temp").width() / 2;
                this.setState({
                    canvasOuterMargin: ml,
                });
                // let scaleFactorX = canvasWidthInPixels/item.json.width
                // let scaleFactorY = canvasHeightInPixels/item.json.height
                // this.canvas.getObjects().forEach(obj => {
                //     // Update object properties based on the new scale
                //     obj.set({
                //         left: obj.left * scaleFactorX,
                //         top: obj.top * scaleFactorY,
                //         scaleX: obj.scaleX * scaleFactorX,
                //         scaleY: obj.scaleY * scaleFactorY,
                //         // Adjust other properties as needed
                //     });
                //     // Handle text objects
                //     if (obj.type === 'textbox') {
                //         // Set font size directly to 16
                //         obj.set({ fontSize: obj.fontSize });

                //         // Adjust width and height to match new scale
                //         obj.set({
                //             scaleX : 1,
                //             scaleY : 1,
                //         });
                //     }
                //     // Apply changes to the object
                //     obj.setCoords();
                // });
                //1024
                if((container.width <= 1024 && container.height >= 400) && (container.width <= 1024 && container.height <= 600))
                {
                    this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180)
                }
                if((container.width <= 1024 && container.height >= 601) && (container.width <= 1024 && container.height <= 768))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180)
                }
                //1280
                if(((container.width >= 1025 && container.height >= 450) && (container.width <= 1280 && container.height <= 560)) || ((container.width === 1280 && container.height >= 450) && (container.width === 1280 && container.height <= 560)))
                {
                    this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
                }
                if(((container.width >= 1025 && container.height >= 561) && (container.width <= 1280 && container.height <= 700)) || (container.width === 1280 && container.height >= 561) && (container.width === 1280 && container.height <= 700))
                {
                    this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
                }
                if(((container.width >= 1025 && container.height >= 701) && (container.width <= 1280 && container.height <= 860)) || (container.width === 1280 && container.height >= 701) && (container.width === 1280 && container.height <= 860))
                {
                    this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if(((container.width >= 1025 && container.height >= 861) && (container.width <= 1280 && container.height <= 924)) || (container.width === 1280 && container.height >= 861) && (container.width === 1280 && container.height <= 924))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if((container.width >= 1025 && container.height > 924) && (container.width <= 1280 && container.height > 924))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                //1366
                if(((container.width >= 1281 && container.height >= 450) && (container.width <= 1366 && container.height <= 560)) || (container.width === 1366 && container.height >= 450) && (container.width === 1366 && container.height <= 560))
                {
                    this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
                }
                if(((container.width >= 1281 && container.height >= 561) && (container.width <= 1366 && container.height <= 768)) || (container.width === 1366 && container.height >= 561) && (container.width === 1366 && container.height <= 768))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if((container.width >= 1281 && container.height > 561) && (container.width <= 1366 && container.height > 561))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                //1440
                if(((container.width >= 1367 && container.height >= 450) && (container.width <= 1440 && container.height <= 560)) || (container.width === 1440 && container.height >= 450) && (container.width === 1440 && container.height <= 560))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if(((container.width >= 1367 && container.height >= 561) && (container.width <= 1440 && container.height <= 700)) || (container.width === 1440 && container.height >= 561) && (container.width === 1440 && container.height <= 700))
                {
                    this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if(((container.width >= 1367 && container.height >= 701) && (container.width <= 1440 && container.height <= 900)) || (container.width === 1440 && container.height >= 900) && (container.width === 1440 && container.height <= 900))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                if((container.width >= 1367 && container.height > 900) && (container.width <= 1440 && container.height > 900))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
                }
                //1600
                if(((container.width >= 1441 && container.height >= 600) && (container.width <= 1660 && container.height <= 700)) || (container.width === 800 && container.height >= 600) && (container.width === 800 && container.height <= 700))
                {
                    this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if(((container.width >= 1441 && container.height >= 701) && (container.width <= 1660 && container.height <= 800)) || (container.width === 800 && container.height >= 701) && (container.width === 800 && container.height <= 800))
                {
                    this.getResolutionZoom(75, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if(((container.width >= 1441 && container.height >= 801) && (container.width <= 1660 && container.height <= 900)) || (container.width === 801 && container.height >= 801) && (container.width === 801 && container.height <= 900))
                {
                    this.getResolutionZoom(85, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if(((container.width >= 1441 && container.height >= 901) && (container.width <= 1660 && container.height <= 1200)) || (container.width === 1600 && container.height >= 901) && (container.width === 1600 && container.height <= 1200))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if((container.width >= 1441 && container.height > 1200) && (container.width <= 1660 && container.height > 1200))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                //1680
                if(((container.width >= 1601 && container.height >= 800) && (container.width <= 1680 && container.height <= 900)) || (container.width === 1680 && container.height >= 800) && (container.width === 1680 && container.height <= 900))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if(((container.width >= 1601 && container.height >= 901) && (container.width <= 1680 && container.height <= 1050)) || (container.width === 1680 && container.height >= 901) && (container.width === 1680 && container.height <= 1050))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if((container.width >= 1601 && container.height > 1050) && (container.width <= 1680 && container.height > 1050))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                //1920
                if(((container.width >= 1681 && container.height >= 800) && (container.width <= 1920 && container.height <= 950)) || (container.width === 1920 && container.height >= 800) && (container.width === 1920 && container.height <= 950))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if(((container.width >= 1681 && container.height >= 951) && (container.width <= 1920 && container.height <= 1080)) || (container.width === 1920 && container.height >= 951) && (container.width === 1920 && container.height <= 1080))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                if((container.width >= 1681 && container.height > 1080) && (container.width <= 1920 && container.height > 1080))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
                }
                //2048
                if(((container.width >= 1921 &&  container.height <= 1536) && (container.width <= 2048 &&  container.height <= 1536)) || (container.width === 2048 && container.height <= 1536))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '46%', 170)
                }
                if((container.width >= 1921 &&  container.height > 1536) && (container.width < 2048 &&  container.height > 1536))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '46%', 170)
                }
                //2560
                if(((container.width >= 2049 && container.height <= 1440) && (container.width <= 2560 && container.height <= 1440)) || (container.width === 2560 && container.height <= 1440))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
                }
                if((container.width >= 2049 && container.height > 1440) && (container.width <= 2560 && container.height > 1440))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
                }
                //high
                if((container.width > 2560 && container.height > 1560))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
                }
                await this.addObjectsToCanvas(item.objects,this.canvas)
                this.props.appLoader(false,'Loading your Product')
                setTimeout(() => {
                    if (canvas) {
                        let frontCanvas = getCanvasBySide(
                            this.props.allCanvasList,
                            "front"
                        );
                
                        let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                        removeStates();
                        removeStates2();
                        addToHistory(frontCanvas);
                        addToHistory2(backCanvas);
                    }
                }, 100);
            }
        }else{
            this.canvas = new fabric.Canvas("front", {
                preserveObjectStacking: true,
                width: canvasWidthInPixels,
                height: canvasHeightInPixels,
                backgroundColor : '#fff',
                // aspectRatio:ratio,
            });
            this.setbgImage(this.canvas)
            if(this.props.state?.canvastype === 'photodart')
            {
                // Apply border-radius to the canvas container
                var canvasContainers = document.getElementsByClassName("canvas-container");
                if (canvasContainers.length > 0) {
                var canvasContainer = canvasContainers[0];
                canvasContainer.style.borderRadius = "50%"; // Adjust the value as per your preference
                }

                // Apply border-radius to the canvas container before pseudo-element
                var style = document.createElement('style');
                style.innerHTML = '.canvas-container:before { border-radius: 50%; }'; // Adjust the value as per your preference
                document.head.appendChild(style);
            }
            this.setState({
                showDimensions : true,
                getLoader : false
            })
            $("#abs1 > .canvas-title").css({ width: canvasWidthInPixels + "px" });
            $("#abs2 > .canvas-title").css({ width: canvasWidthInPixels + "px" });
            this.setState({
                canvas: this.canvas,
            });
            this.canvas1 = new fabric.Canvas("back", {
                preserveObjectStacking: true,
                width: canvasWidthInPixels,
                height: canvasHeightInPixels,
                // aspectRatio:ratio,
            });
            this.setState({
                canvas1: this.canvas1,
            });
            // this.state.canvasObj.push(this.canvas)
            // this.state.canvasObj.push(this.canvas1)
            this.props.addCanvasInCanvasLIst({
                side: "front",
                canvas: this.canvas,
            });
            this.props.addCanvasInCanvasLIst({
                side: "back",
                canvas: this.canvas1,
            });
            //for canvas history save - undo / redo
            this.canvas.state = [];
            this.canvas.index = 0;
            this.canvas.stateaction = true;
            this.canvas1.state = [];
            this.canvas1.index = 0;
            this.canvas1.stateaction = true;
            initCenteringGuidelines(this.canvas);
            initAligningGuidelines(this.canvas);
            initCenteringGuidelines(this.canvas1);
            initAligningGuidelines(this.canvas1);
            this.initCanvasEvents();
            this.setState({
                displaybgColorPicker: false,
            });
            this.props.updateCanvas(this.canvas);
            this.props.updateCanvases("front");
            let canvas=this.canvas
            var ml = "-" + $("#temp").width() / 2;
            this.setState({
                canvasOuterMargin: ml,
            });
            canvas.requestRenderAll()
            //1024
            if((container.width <= 1024 && container.height >= 400) && (container.width <= 1024 && container.height <= 600))
            {
                this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180)
            }
            if((container.width <= 1024 && container.height >= 601) && (container.width <= 1024 && container.height <= 768))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180)
            }
            //1280
            if(((container.width >= 1025 && container.height >= 450) && (container.width <= 1280 && container.height <= 560)) || ((container.width === 1280 && container.height >= 450) && (container.width === 1280 && container.height <= 560)))
            {
                this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
            }
            if(((container.width >= 1025 && container.height >= 561) && (container.width <= 1280 && container.height <= 700)) || (container.width === 1280 && container.height >= 561) && (container.width === 1280 && container.height <= 700))
            {
                this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
            }
            if(((container.width >= 1025 && container.height >= 701) && (container.width <= 1280 && container.height <= 860)) || (container.width === 1280 && container.height >= 701) && (container.width === 1280 && container.height <= 860))
            {
                this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if(((container.width >= 1025 && container.height >= 861) && (container.width <= 1280 && container.height <= 924)) || (container.width === 1280 && container.height >= 861) && (container.width === 1280 && container.height <= 924))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if((container.width >= 1025 && container.height > 924) && (container.width <= 1280 && container.height > 924))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            //1366
            if(((container.width >= 1281 && container.height >= 450) && (container.width <= 1366 && container.height <= 560)) || (container.width === 1366 && container.height >= 450) && (container.width === 1366 && container.height <= 560))
            {
                this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 170)
            }
            if(((container.width >= 1281 && container.height >= 561) && (container.width <= 1366 && container.height <= 768)) || (container.width === 1366 && container.height >= 561) && (container.width === 1366 && container.height <= 768))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if((container.width >= 1281 && container.height > 561) && (container.width <= 1366 && container.height > 561))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            //1440
            if(((container.width >= 1367 && container.height >= 450) && (container.width <= 1440 && container.height <= 560)) || (container.width === 1440 && container.height >= 450) && (container.width === 1440 && container.height <= 560))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if(((container.width >= 1367 && container.height >= 561) && (container.width <= 1440 && container.height <= 700)) || (container.width === 1440 && container.height >= 561) && (container.width === 1440 && container.height <= 700))
            {
                this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if(((container.width >= 1367 && container.height >= 701) && (container.width <= 1440 && container.height <= 900)) || (container.width === 1440 && container.height >= 900) && (container.width === 1440 && container.height <= 900))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            if((container.width >= 1367 && container.height > 900) && (container.width <= 1440 && container.height > 900))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170)
            }
            //1600
            if(((container.width >= 1441 && container.height >= 600) && (container.width <= 1660 && container.height <= 700)) || (container.width === 800 && container.height >= 600) && (container.width === 800 && container.height <= 700))
            {
                this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if(((container.width >= 1441 && container.height >= 701) && (container.width <= 1660 && container.height <= 800)) || (container.width === 800 && container.height >= 701) && (container.width === 800 && container.height <= 800))
            {
                this.getResolutionZoom(75, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if(((container.width >= 1441 && container.height >= 801) && (container.width <= 1660 && container.height <= 900)) || (container.width === 801 && container.height >= 801) && (container.width === 801 && container.height <= 900))
            {
                this.getResolutionZoom(85, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if(((container.width >= 1441 && container.height >= 901) && (container.width <= 1660 && container.height <= 1200)) || (container.width === 1600 && container.height >= 901) && (container.width === 1600 && container.height <= 1200))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if((container.width >= 1441 && container.height > 1200) && (container.width <= 1660 && container.height > 1200))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            //1680
            if(((container.width >= 1601 && container.height >= 800) && (container.width <= 1680 && container.height <= 900)) || (container.width === 1680 && container.height >= 800) && (container.width === 1680 && container.height <= 900))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if(((container.width >= 1601 && container.height >= 901) && (container.width <= 1680 && container.height <= 1050)) || (container.width === 1680 && container.height >= 901) && (container.width === 1680 && container.height <= 1050))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if((container.width >= 1601 && container.height > 1050) && (container.width <= 1680 && container.height > 1050))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            //1920
            if(((container.width >= 1681 && container.height >= 800) && (container.width <= 1920 && container.height <= 950)) || (container.width === 1920 && container.height >= 800) && (container.width === 1920 && container.height <= 950))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if(((container.width >= 1681 && container.height >= 951) && (container.width <= 1920 && container.height <= 1080)) || (container.width === 1920 && container.height >= 951) && (container.width === 1920 && container.height <= 1080))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            if((container.width >= 1681 && container.height > 1080) && (container.width <= 1920 && container.height > 1080))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '45%', 180)
            }
            //2048
            if(((container.width >= 1921 &&  container.height <= 1536) && (container.width <= 2048 &&  container.height <= 1536)) || (container.width === 2048 && container.height <= 1536))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '46%', 170)
            }
            if((container.width >= 1921 &&  container.height > 1536) && (container.width < 2048 &&  container.height > 1536))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '46%', 170)
            }
            //2560
            if(((container.width >= 2049 && container.height <= 1440) && (container.width <= 2560 && container.height <= 1440)) || (container.width === 2560 && container.height <= 1440))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
            }
            if((container.width >= 2049 && container.height > 1440) && (container.width <= 2560 && container.height > 1440))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
            }
            //high
            if((container.width > 2560 && container.height > 1560))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '45%', 170)
            }
            setTimeout(() => {
                if (canvas) {
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                    removeStates();
                    removeStates2();
                    addToHistory(frontCanvas);
                    addToHistory2(backCanvas);
                }
            }, 100);
        }
    }
    getResolutionZoom = (zoomRange, canvasWidthInPixels, canvasHeightInPixels, tempTop, tempHeight) => {
        let zoomLevel = parseInt(zoomRange, 10);
        const zoomValue = zoomLevel / 100;
        this.setState({
            zoomLevel : zoomLevel,
            initCanvasHeight : canvasHeightInPixels,
            initCanvasWidth : canvasWidthInPixels,
            tempTop : tempTop,
            tempHeight : tempHeight
        })
        const newZoomLevel = this.state.initZoomLevel * zoomValue;
        const newCanvasHeight = parseInt(canvasHeightInPixels * zoomValue);
        const newCanvasWidth = parseInt(canvasWidthInPixels * zoomValue);
        this.setState({
            updateCanvasHeight : newCanvasHeight,
            updateCanvasWidth : newCanvasWidth
        })
        this.canvas.setHeight(newCanvasHeight);
        this.canvas.setWidth(newCanvasWidth);
        this.canvas.setZoom(newZoomLevel);
        this.props.saveZoom(newCanvasHeight,newCanvasWidth,newZoomLevel)
    }
    async addObjectsToCanvas(array, canvas) {
        for (const obj of array) {
          obj.cornerColor = '#1babeb';
          obj.cornerStrokeColor = '#1babeb';
          obj.borderColor = '#1babeb';
          obj.cornerSize = 5;
          obj.padding = 5;
          obj.cornerStyle = 'circle';
          obj.transparentCorners = false;
          obj.radius = 70;
          switch (obj.type) {
            case 'image':
                await this.addImageToCanvas(canvas, obj);
                break;
            case 'textbox':
                await this.addTextboxToCanvas(canvas, obj);
                break;
            case 'circle':
                await this.addCircleToCanvas(canvas, obj);
                break;
            case 'rect':
                await this.addRectToCanvas(canvas, obj);
                break;
            case 'polygon':
                await this.addPolygonToCanvas(canvas, obj);
                break;
            default:
              break;
          }
        }
      }

      setbgImage = (canvas) => {
            let svg;
            if(this.props.state?.canvastype === 'photodart'){
                svg = dartsafearea
            }else if(this.props.state?.canvastype === 'growthchart')
            {
                svg = growthsafearea
            }else{
                svg = petmatsafearea
            }
            var image = new Image();
            image.src = svg;
            image.crossOrigin = "anonymous";
            image.crossDomain = true;
            image.onload = () => {
                var img = new fabric.Image(image);
                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                img.setControlsVisibility(HideControls);
                img.set({
                    top : fabric.Canvas.originalHeight / 2,
                    type : 'image',
                    originX: "center",
                    originY: "center",
                    left: fabric.Canvas.originalWidth / 2,
                    src : svg,
                });
                var scaleX = fabric.Canvas.originalWidth / img.width;
                var scaleY = fabric.Canvas.originalHeight / img.height;
                var scale = Math.min(scaleX, scaleY);
                img.set({ scaleX: scale, scaleY: scale });
                canvas.setOverlayImage(img, canvas.renderAll.bind(canvas));
                img.setCoords();
                canvas.renderAll();
            };
      }

      addImageToCanvas(canvas, obj) {
        return new Promise((resolve) => {
        var HideControls = {
            'mb': false,
            'mt': false,
          };
          const imgElement = new Image();
          imgElement.crossOrigin = "anonymous"; // Set crossOrigin here
      
          imgElement.onload = function () {
            if (obj.src.toLowerCase().endsWith('.svg')) {
                // Handle SVG separately
                fabric.loadSVGFromURL(obj.src, function (objects, options) {
                    const group = new fabric.Group(objects, options);
                    group.set(obj);
                    group.setControlsVisibility(HideControls);
                    canvas.add(group);
                    resolve();
                });
            } else {
                // For other formats (e.g., PNG)
                fabric.Image.fromURL(obj.src, function (img) {
                    img.setElement(imgElement);
                    img.set(obj);
                    img.setControlsVisibility(HideControls);
                    canvas.add(img);
                    resolve();
                });
            }
          };
          imgElement.src = obj.src; // Set the image source URL
        });
      }
    
      addTextboxToCanvas(canvas, obj) {
        return new Promise((resolve) => {
        var HideControls = {
            'mb': false,
            'mt': false,
            };
          var textObj = new fabric.Textbox(obj.text, obj);
          textObj.setControlsVisibility(HideControls);
          canvas.add(textObj);
          resolve();
        });
      }
    
      addCircleToCanvas(canvas, obj) {
        return new Promise((resolve) => {
        var HideControls = {
        'mb': false,
        'mt': false,
        };
        var circleObj = new fabric.Circle(obj);
        circleObj.setControlsVisibility(HideControls);
        canvas.add(circleObj);
        resolve();
        });
      }
    
      addRectToCanvas(canvas, obj) {
        return new Promise((resolve) => {
        var HideControls = {
        'mb': false,
        'mt': false,
        };
        var rectObj = new fabric.Rect(obj);
        rectObj.setControlsVisibility(HideControls);
        canvas.add(rectObj);
        resolve();
        });
      }
    
      addPolygonToCanvas(canvas, obj) {
        return new Promise((resolve) => {
        var HideControls = {
        'mb': false,
        'mt': false,
        };
        var polyObj = new fabric.Polygon(obj.points);
        polyObj.set(obj);
        polyObj.setControlsVisibility(HideControls);
        canvas.add(polyObj);
        resolve();
        });
      }
    regularPolygonPoints(sideCount, radius) {
        var sweep = (Math.PI * 2) / sideCount;
        var cx = radius;
        var cy = radius;
        var points = [];
        for (var i = 0; i < sideCount; i++) {
            var x = cx + radius * Math.cos(i * sweep);
            var y = cy + radius * Math.sin(i * sweep);
            points.push({ x: x, y: y });
        }
        return points;
    }
    canvasSettings = (id) => {
        this.canvas.renderAll();

        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        //var lthis = this;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/userSettings?id=${id}`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response) => {
                var settings = JSON.parse(response.data.data[0].data);
                fabric.Object.prototype.set({
                    transparentCorners: false,
                    cornerColor: "#1babeb",
                    cornerStrokeColor: "#1babeb",
                    borderColor: "#1babeb",
                    cornerSize: 10,
                    padding: 5,
                    cornerStyle: "circle",
                });
                this.canvas.loadFromJSON(
                    settings,
                    this.canvas.renderAll.bind(this.canvas),
                    function (o, object) {
                        fabric.log(o, object);
                    }
                );
                saveCanvasState(this.canvas);
                //this.canvas.setActiveObject(text);
                selectObject(this.canvas);
                this.canvas.renderAll();
            })
            .catch((err) => {});
    };
    plus() {
        //$("#abs1").css({"zoom": parseInt($("#my-range").val())+10 +'%'});
        //$("#abs2").css({"zoom": parseInt($("#my-range").val())+10 +'%'});
    }
    minus() {
        //$("#abs1").setZoom( +this.value );
        //$("#abs2").setZoom( +this.value );
        // $("#abs1").css({"zoom": parseInt($("#my-range").val())-10 +'%'});
        // $("#abs2").css({"zoom": parseInt($("#my-range").val())-10 +'%'});
    }
    // map__SetZoom() {
    //     const zoomLevel = $("#my-range").val();

    //     const zoomValue = zoomLevel / 100;
    //     this.setState({
    //         zoomLevel,
    //     });
    //     const currentZoomLevel = this.state.canvas.getZoom();
    //     const initCanvasHeight = parseInt(this.state.initCanvasHeight);
    //     const initCanvasWidth = parseInt(this.state.initCanvasWidth);
    //     const oldCanvasHeight = this.state.canvas.getHeight();
    //     const oldCanvasWidth = this.state.canvas.getWidth();
    //     //    console.log(oldCanvasHeight,"oldCanvas",oldCanvasWidth)

    //     let newZoomLevel, newCanvasHeight, newCanvasWidth;
    //     newZoomLevel = this.state.initZoomLevel * zoomValue;
    //     // console.log("newZoom",newZoomLevel,zoomValue)
    //     newCanvasHeight = parseInt(initCanvasHeight * zoomValue);
    //     newCanvasWidth = parseInt(initCanvasWidth * zoomValue);
    //     //front Canvas caculation
    //     // let frontScaleXFactor = (newCanvasWidth+5) / this.state.canvas.backgroundImage.width
    //     // let frontScaleYFactor = (newCanvasHeight+5) / this.state.canvas.backgroundImage.height
    //     // this.state.canvas.backgroundImage.scaleX = frontScaleXFactor
    //     // this.state.canvas.backgroundImage.scaleY = frontScaleYFactor
    //     this.state.canvas.setHeight(newCanvasHeight);
    //     this.state.canvas.setWidth(newCanvasWidth);
    //     this.state.canvas.setZoom(newZoomLevel);

    //     // console.log(zoomValue,"zoomValue",zoomLevel,"zoomlevel")

    //     //Back Canvas Caculation
    //     // let backScaleXFactor = (newCanvasWidth+5) / this.state.canvas1.backgroundImage.width
    //     // let backScaleYFactor = (newCanvasHeight+5) / this.state.canvas1.backgroundImage.height
    //     // this.state.canvas1.backgroundImage.scaleX = backScaleXFactor
    //     // this.state.canvas1.backgroundImage.scaleY = backScaleYFactor
    //     this.state.canvas1.setHeight(newCanvasHeight);
    //     this.state.canvas1.setWidth(newCanvasWidth);
    //     this.state.canvas1.setZoom(newZoomLevel);

    //     this.state.canvas.requestRenderAll();

    //     $("#abs1 > .canvas-title").css({ width: newCanvasWidth + "px" });
    //     $("#abs2 > .canvas-title").css({ width: newCanvasWidth + "px" });
    // }
    handleZoomIn = () => {
        const { canvas, canvas1, zoomLevel, initZoomLevel } = this.state;
        const newZoomLevel = zoomLevel + 5;
    
        if (newZoomLevel <= 200) {
          const zoomValue = newZoomLevel / 100;
          const newCanvasHeight = parseInt(this.state.initCanvasHeight * zoomValue);
          const newCanvasWidth = parseInt(this.state.initCanvasWidth * zoomValue);
        this.setState({
            updateCanvasHeight : newCanvasHeight,
            updateCanvasWidth : newCanvasWidth
        })
          canvas.setHeight(newCanvasHeight);
          canvas.setWidth(newCanvasWidth);
          canvas.setZoom(initZoomLevel * zoomValue);
    
          canvas1.setHeight(newCanvasHeight);
          canvas1.setWidth(newCanvasWidth);
          canvas1.setZoom(initZoomLevel * zoomValue);
    
          this.setState({ zoomLevel: newZoomLevel });
          if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
        }
      };
    
      handleZoomOut = () => {
        const { canvas, canvas1, zoomLevel, initZoomLevel } = this.state;
        const newZoomLevel = zoomLevel - 5;
    
        if (newZoomLevel >= 10) {
          const zoomValue = newZoomLevel / 100;
          const newCanvasHeight = parseInt(this.state.initCanvasHeight * zoomValue);
          const newCanvasWidth = parseInt(this.state.initCanvasWidth * zoomValue);
          this.setState({
            updateCanvasHeight : newCanvasHeight,
            updateCanvasWidth : newCanvasWidth
        })
          canvas.setHeight(newCanvasHeight);
          canvas.setWidth(newCanvasWidth);
          canvas.setZoom(initZoomLevel * zoomValue);
    
          canvas1.setHeight(newCanvasHeight);
          canvas1.setWidth(newCanvasWidth);
          canvas1.setZoom(initZoomLevel * zoomValue);
    
          this.setState({ zoomLevel: newZoomLevel });
        }
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
      };
    initCanvasEvents() {
        const { updateCanvases } = this.props;
        //var i = 1, j = 1;
        var lthis = this;
        $(".canvas-area").click(function () {
            lthis.canvas.discardActiveObject();
            lthis.canvas.renderAll();
        });
        $(".canvas-container").click(function (e) {
            e.stopPropagation();
        });
        // document
        //     .querySelector("#my-range")
        //     .addEventListener("input", this.map__SetZoom);
        function frontClick(e) {
            $("#abs1").css({ opacity: 1 });
            $("#abs2").css({ opacity: 0.2 });
            const activeobjs = [];
            activeobjs.push("front");
            updateCanvases("front");

            // if (lthis.state.subtarget) {
            //     selectObject(lthis.canvas, lthis.state.subtarget);
            //     lthis.setState({
            //         subtarget: null
            //     })
            // }
        }
        function backClick(e) {
            $("#abs2").css({ "background-color": "#fff" });
            $("#abs2").css({ opacity: 1 });
            $("#abs1").css({ opacity: 0.2 });
            const activeobjs = [];
            activeobjs.push("back");

            updateCanvases("back");

            // if (lthis.state.subtarget) {
            //     selectObject(lthis.canvas1, lthis.state.subtarget);
            //     lthis.setState({
            //         subtarget: null
            //     })
            // }
        }
        fabric.util.addListener(this.canvas.upperCanvasEl, "click", frontClick);
        fabric.util.addListener(
            this.canvas.upperCanvasEl,
            "touchstart",
            frontClick
        );

        fabric.util.addListener(this.canvas1.upperCanvasEl, "click", backClick);
        fabric.util.addListener(
            this.canvas1.upperCanvasEl,
            "touchstart",
            backClick
        );
        //     this.canvas.on("text:editing:entered", function(e){

        //         var activeObject = lthis.canvas.getActiveObject();

        //     // canvas.on('text:editing:entered', (textObject) => {
        //         // alert('am edit')
        //     // var activeObject = textObject.target
        //      let txt = activeObject.text

        //     activeObject.text="\u2022"+""

        //         lthis.canvas.add(activeObject);
        // lthis.canvas.setActiveObject(activeObject);
        // selectObject(lthis.canvas);
        // lthis.canvas.renderAll();
        //     });

        // this.canvas.on({
        //     'mouse:down': (e) => {
        //         if (e.subTargets && e.subTargets[0]) {
        //             lthis.setState({
        //                 subtarget: e.subTargets[0]
        //             })
        //         }
        //     },

        //     'object:moving': (e) => {
        //         //Optimization: No need to updatestate on moving
        //         //lthis.updateState(e);
        //         if (this.props.state.isOverlap) {
        //             lthis.avoidOverlap(e);
        //         }
        //         if (this.props.state.isSnap) {
        //             e.target.set({
        //                 left: Math.round(e.target.left / this.props.state.gridsize) * this.props.state.gridsize,
        //                 top: Math.round(e.target.top / this.props.state.gridsize) * this.props.state.gridsize
        //             });
        //         }
        //     },
        //     'object:added': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState(e);
        //         saveCanvasState(lthis.canvas);

        //     },

        //     'object:modified': (e) => {

        //         localStorage.setItem('objId', e.target.objId);
        //         if (e.subTargets) {
        //             selectObject(lthis.canvas, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas);

        //     },
        //     'object:selected': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState(e);
        //     },
        //     'object:scaling': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         //localStorage.setItem('content', a);
        //         lthis.updateState(e);

        //     },
        //     'selection:created': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState();
        //         if (e.subTargets) {
        //             selectObject(lthis.canvas, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas);
        //     },
        //     'selection:updated': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState();
        //         selectObject(lthis.canvas);
        //         //let object = lthis.canvas.getActiveObject();
        //     },
        //     'selection:cleared': (e) => {

        //         if (e.subTargets) {
        //             selectObject(lthis.canvas, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas);

        //     },
        //     'selection:added': (e) => { //alert('selection added')
        //     },
        // });
        //  this.canvas1.on({
        //     'mouse:down': (e) => {
        //         if (e.subTargets && e.subTargets[0]) {
        //             lthis.setState({
        //                 subtarget: e.subTargets[0]
        //             })
        //         }
        //     },

        //     'object:moving': (e) => {

        //         lthis.updateState(e);
        //         if (this.props.state.isOverlap) {
        //             lthis.avoidOverlap(e);
        //         }
        //         if (this.props.state.isSnap) {
        //             e.target.set({
        //                 left: Math.round(e.target.left / this.props.state.gridsize) * this.props.state.gridsize,
        //                 top: Math.round(e.target.top / this.props.state.gridsize) * this.props.state.gridsize
        //             });
        //         }
        //     },
        //     'object:added': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState(e);
        //         saveCanvasState(lthis.canvas1);

        //     },

        //     'object:modified': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         if (e.subTargets) {
        //             selectObject(lthis.canvas1, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas1);

        //     },
        //     'object:selected': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState(e);
        //     },
        //     'object:scaling': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState(e);

        //     },
        //     'selection:created': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState();
        //         if (e.subTargets) {
        //             selectObject(lthis.canvas, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas);

        //     },
        //     'selection:updated': (e) => {
        //         localStorage.setItem('objId', e.target.objId);
        //         lthis.updateState();
        //         selectObject(lthis.canvas);
        //         //let object = lthis.canvas.getActiveObject();
        //     },
        //     'selection:cleared': (e) => {

        //         if (e.subTargets) {
        //             selectObject(lthis.canvas, e.subTargets[0]);
        //         } else
        //             selectObject(lthis.canvas);

        //     },
        //     'selection:added': (e) => { //alert('selection added')
        //     },
        // });
    }

    avoidOverlap = (e) => {
        var snap = 20;
        var lthis = this;
        // Sets corner position coordinates based on current angle, width and height
        e.target.setCoords();
        // Don't allow objects off the canvas
        if (e.target.get("left") < snap) {
            e.target.set({
                left: 0,
            });
        }
        if (e.target.get("top") < snap) {
            e.target.set({
                top: 0,
            });
        }
        if (
            e.target.get("width") + e.target.get("left") >
            this.canvasWidth - snap
        ) {
            e.target.set({
                left: this.canvasWidth - e.target.get("width"),
            });
        }
        if (
            e.target.get("height") + e.target.get("top") >
            this.canvasHeight - snap
        ) {
            e.target.set({
                top: this.canvasHeight - e.target.get("height"),
            });
        }

        this.canvas.forEachObject(function (obj) {
            if (obj === e.target) return;
            // If objects intersect
            if (
                e.target.isContainedWithinObject(obj) ||
                e.target.intersectsWithObject(obj) ||
                obj.isContainedWithinObject(e.target)
            ) {
                var distX =
                    (obj.get("left") + obj.get("width")) / 2 -
                    (e.target.get("left") + e.target.get("width")) / 2;
                var distY =
                    (obj.get("top") + obj.get("height")) / 2 -
                    (e.target.get("top") + e.target.get("height")) / 2;
                // Set new position
                lthis.findNewPos(distX, distY, e.target, obj);
            }
            // Snap objects to each other horizontally
            // If bottom points are on same Y axis
            if (
                Math.abs(
                    e.target.get("top") +
                        e.target.get("height") -
                        (obj.get("top") + obj.get("height"))
                ) < snap
            ) {
                // Snap target BL to object BR
                if (
                    Math.abs(
                        e.target.get("left") -
                            (obj.get("left") + obj.get("width"))
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left") + obj.get("width"),
                    });
                    e.target.set({
                        top: obj.get("left") + obj.get("width"),
                    });
                }
                // Snap target BR to object BL
                if (
                    Math.abs(
                        e.target.get("left") +
                            e.target.get("width") -
                            obj.get("left")
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left") - e.target.get("width"),
                    });
                    e.target.set({
                        top:
                            obj.get("top") +
                            obj.get("height") -
                            e.target.get("height"),
                    });
                }
            }
            // If top points are on same Y axis
            if (Math.abs(e.target.get("top") - obj.get("top")) < snap) {
                // Snap target TL to object TR
                if (
                    Math.abs(
                        e.target.get("left") -
                            (obj.get("left") + obj.get("width"))
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left") + obj.get("width"),
                    });
                    e.target.set({
                        top: obj.get("top"),
                    });
                }
                // Snap target TR to object TL
                if (
                    Math.abs(
                        e.target.get("left") +
                            e.target.get("width") -
                            obj.get("left")
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left") + obj.get("width"),
                    });
                    e.target.set({
                        top: obj.get("top"),
                    });
                }
            }
            // Snap objects to each other vertically
            // If right points are on same X axis
            if (
                Math.abs(
                    e.target.get("left") +
                        e.target.get("width") -
                        (obj.get("left") + obj.get("width"))
                ) < snap
            ) {
                // Snap target TR to object BR
                if (
                    Math.abs(
                        e.target.get("top") -
                            (obj.get("top") + obj.get("height"))
                    ) < snap
                ) {
                    e.target.set({
                        left:
                            obj.get("left") +
                            obj.get("width") -
                            e.target.get("width"),
                    });
                    e.target.set({
                        top: obj.get("top") + obj.get("height"),
                    });
                }
                // Snap target BR to object TR
                if (
                    Math.abs(
                        e.target.get("top") +
                            e.target.get("height") -
                            obj.get("top")
                    ) < snap
                ) {
                    e.target.set({
                        left:
                            obj.get("left") +
                            obj.get("width") -
                            e.target.get("width"),
                    });
                    e.target.set({
                        top: obj.get("top") - e.target.get("height"),
                    });
                }
            }
            // If left points are on same X axis
            if (Math.abs(e.target.get("left") - obj.get("left")) < snap) {
                // Snap target TL to object BL
                if (
                    Math.abs(
                        e.target.get("top") -
                            (obj.get("top") + obj.get("height"))
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left"),
                    });
                    e.target.set({
                        top: obj.get("top") + obj.get("height"),
                    });
                }
                // Snap target BL to object TL
                if (
                    Math.abs(
                        e.target.get("top") +
                            e.target.get("height") -
                            obj.get("top")
                    ) < snap
                ) {
                    e.target.set({
                        left: obj.get("left"),
                    });
                    e.target.set({
                        top: obj.get("top") + obj.get("height"),
                    });
                }
            }
        });
    };

    findNewPos = (distX, distY, target, obj) => {
        // See whether to focus on X or Y axis
        if (Math.abs(distX) > Math.abs(distY)) {
            if (distX > 0) {
                target.set({
                    left: obj.get("left") - target.get("width"),
                });
            } else {
                target.set({
                    left: obj.get("left") + obj.get("width"),
                });
            }
        } else {
            if (distY > 0) {
                target.set({
                    top: obj.get("top") - target.get("height"),
                });
            } else {
                target.set({
                    top: obj.get("top") + obj.get("height"),
                });
            }
        }
    };

    deleteCanvasBg = () => {
        this.canvas.backgroundColor = "";
        this.canvas.renderAll();
        var objects = this.canvas.getObjects().filter(function (o) {
            return o.bg === true;
        });
        for (var i = 0; i < objects.length; i++) {
            this.canvas.remove(objects[i]);
        }
        this.canvas.bgsrc = "";
        this.canvas.bgcolor = "";
    };

    setcanvasBG = (result) => {
        var bgsrc = result;
        if (result && result.url) bgsrc = result.url;
        if (bgsrc) {
            this.deleteCanvasBg();
            fabric.Image.fromURL(bgsrc, (bg) => {
                var canvasAspect = this.canvas.width / this.canvas.height;
                var imgAspect = bg.width / bg.height;
                var scaleFactor;
                if (canvasAspect >= imgAspect) {
                    scaleFactor = (this.canvas.width / bg.width) * 1;
                } else {
                    scaleFactor = (this.canvas.height / bg.height) * 1;
                }
                bg.set({
                    originX: "center",
                    originY: "center",
                    opacity: 1,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    hasCorners: false,
                    left: this.canvas.width / 2,
                    top: this.canvas.height / 2,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    strokeWidth: 0,
                });
                this.canvas.add(bg);
                this.canvas.sendToBack(bg);
                bg.bg = true;
                this.canvas.bgsrc = bgsrc;
            });
        }
    };

    bgpickerOpen = () => {
        this.setState({
            displaybgColorPicker: !this.state.displaybgColorPicker,
        });
    };

    bgpickerClose = () => {
        this.setState({
            displaybgColorPicker: false,
        });
    };
    getZoom = (zoomLevel, width, height) => {
        this.setState({
            zoomLevel : zoomLevel * 100,
            updateCanvasWidth : width,
            updateCanvasHeight : height
        })
    }
    handleMainAreaClick = (e) => {
        const clickedElement = e.target;
        //console.log('clickedElement.classList',clickedElement.classList[0])
        // Check if the clicked element is not one of the excluded child elements
        if (
            clickedElement === this.mainCanvasContainer.current || clickedElement.classList[0] === 'bleed-line' || clickedElement.classList[0] === 'safety-line'
        ) {
            // Trigger your click event here
            if (this.props.state.canvas) {
                this.props.state.canvas.discardActiveObject();
            }
        }
    }
    clearButton = () => {
        this.setState({
            disableClear : true
        })
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        this.props.state.canvas.clear()
        this.props.appLoader(true,'Clearing your Product')
        if(pid)
        {
            let canvas = JSON.parse(localStorage.getItem('canvas'))
            if(canvas)
            {
                localStorage.removeItem('canvas')
                this.getadmindata()
            }else{
                this.getadmindata()
            }
        }
        
    }
    getadmindata = async() => {
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        let apiDynamicUrlAddress1 = config.default.api1.host;
        let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin`;
        try {
            const response = await axios.get(apiUrl1, {
                mode: "cors",
                headers: headers,
            });
            if(response.data.code === 200)
            {
                let canvasdata = response.data.data[0].json
                await this.addObjectsToCanvas(canvasdata.objects,this.props.state.canvas)
                    this.setbgImage(this.props.state.canvas)
                    this.props.appLoader(false,'Clearing your Product')
                    this.setState({
                        disableClear : false
                    })
                    setTimeout(() => {
                        if (this.props.state.canvas) {
                            let frontCanvas = getCanvasBySide(
                                this.props.allCanvasList,
                                "front"
                            );
                            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                            removeStates();
                            removeStates2();
                            addToHistory(frontCanvas);
                            addToHistory2(backCanvas);
                        }
                    }, 100);
            }
            else{
                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
        }
    }
    render() {
        const displayzoomLevel = parseInt(this.state.zoomLevel);
        return (
            <div>
                <div
                    id="main-area"
                    ref={this.mainCanvasContainer}
                    className="main-area"
                    style={{
                        position: "absolute",
                        width: "100%",
                        background : "#f0f0f0"
                    }}
                    onClick={this.handleMainAreaClick}
                >
                    <div
                        id="temp"
                        style={{
                            display : "grid",
                            position: "absolute",
                            top: this.state.tempTop,
                            marginLeft: $(window).width() <=1024 ? "25%" : "10%",
                            transform : "translateY(-50%)",
                            height : this.state?.updateCanvasHeight >= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight ? this.mainCanvasContainer.current?.getBoundingClientRect().height - 180 : "auto",
                            width : this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - 140 ? this.mainCanvasContainer.current?.getBoundingClientRect().width - 140 : "auto",
                            overflowY : this.state?.updateCanvasHeight >= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight ?  "scroll" : 'hidden',
                            overflowX : this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - 140 ?  "scroll" : 'hidden'
                        }}
                    >
                        {this.state.showDimensions && displayzoomLevel >= 45 && (this.state?.updateCanvasHeight <= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight) && (
                            <div className="safety-bleed-outer">
                                <p className="canvas-dimension">{this.state.widthInInch} x {this.state.heightInInch}(in)</p>
                                 <Tooltip content="Anything outside this line will not be printed." direction="up">
                                    <button className="bleed-line">Trim Line</button>
                                </Tooltip>
                                <Tooltip content="Anything outside this line may be trimmed off in the printing process." direction="up">
                                    <button className="safety-line">Safe Area</button>
                                </Tooltip>
                            </div>
                        )}
                        <div id="abs1" className="canvasfrontback " style={{height : this.state?.updateCanvasHeight}}>
                        <canvas id="front"></canvas>
                        </div>
                        <div
                            id="canvasElement"
                            style={{ display: "none" }}
                            className="canvasfrontback"
                        >
                            <canvas id="canvas-element"></canvas>
                        </div>
                    </div>
                    <div className="bottom-outer">
                        <span
                        className="largedevice undoredo"
                        >
                        {$(window).width() >= 900 ? (
                            <UndoRedo
                                allCanvasList={this.props.allCanvasList}
                                canvas={this.canvas}
                                getZoom = {this.getZoom}
                                triggerState = {this.props.triggerState}
                                setbgImage = {this.setbgImage}
                            />
                        ) : null}
                        </span>
                        <span
                            className=""
                            style={{ position: "fixed", zIndex: 5, marginTop: "20%" }}
                        >
                            {$(window).width() > 568 && $(window).width() < 900 ? (
                                <UndoRedo
                                    allCanvasList={this.props.allCanvasList}
                                    canvas={this.canvas}
                                    getZoom = {this.getZoom}
                                    triggerState = {this.props.triggerState}
                                    setbgImage = {this.setbgImage}
                                />
                            ) : null}
                        </span>
                        {$(window).width() < 568 ? (
                            <span
                                className="my-1"
                                style={{ position: "absolute", zIndex: 5 }}
                            >
                                <UndoRedo
                                    allCanvasList={this.props.allCanvasList}
                                    canvas={this.canvas}
                                    getZoom = {this.getZoom}
                                    triggerState = {this.props.triggerState}
                                    setbgImage = {this.setbgImage}
                                />
                            </span>
                        ) : null}
                        {$(window).width() >= 900 ? (
                            <div
                                className="largedevice zoom-range"
                            >
                                <AiOutlineZoomIn style={{marginRight : "20px"}} onClick={this.handleZoomIn}/>
                                    <span className="zoom-value" style={{marginRight : "20px"}}>{displayzoomLevel}%</span>
                                <AiOutlineZoomOut onClick={this.handleZoomOut}/>
                            </div>
                        ) : $(window).width() > 568 && $(window).width() < 900  ? (
                            <div
                                className="tabletzoom"
                                style={{
                                    position: "fixed",
                                    marginTop: "43%",
                                    marginLeft: "37%",
                                }}
                            >
                                <input
                                    id="my-range"
                                    type="range"
                                    className="range"
                                    min="25"
                                    max="400"
                                    step="1"
                                    defaultValue="100"
                                    onInput={this.handleRangeChange}
                                ></input>
                                <span className="zoom-value">{displayzoomLevel}%</span>
                            </div>
                        ) : (
                            <div
                                className="mobilenav"
                                style={{
                                    position: "fixed",
                                    marginBottom:
                                        $(window).height() > 600 ? "43%" : "55%",
                                    marginLeft: "30%",
                                }}
                            >
                                <input
                                    id="my-range"
                                    type="range"
                                    className="range"
                                    min="25"
                                    max="400"
                                    step="1"
                                    defaultValue="100"
                                    onInput={this.handleRangeChange}
                                ></input>
                                <span className="zoom-value">{displayzoomLevel}%</span>
                            </div>
                        )}
                        <div className="clr-btn-outer">
                            <button className="clr-btn" onClick={this.clearButton} disabled={this.state.disableClear || this.props?.state?.canvas?.getObjects().length === 0}>Clear</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FabricCanvas;