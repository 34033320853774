import React, { Component } from 'react';
import { fabric } from 'fabric';
import { SketchPicker } from 'react-color';
import FontPicker from 'font-picker-react';
import Popup from 'reactjs-popup'
import { getOffset, saveCanvasState, selectObject, removeNumbering,getCanvasBySide } from './Helpers'
import { addToHistory,addToHistory2,removeStates,removeStates2 } from "../components/CanvasHistory"

import { DEFAULT_FONT_PROPS } from "../constants";
import { Container, Row } from "reactstrap";
import $ from 'jquery';
//import { text } from '@fortawesome/fontawesome-svg-core';
import config from '../../config';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiRotateCw, FiCrop } from 'react-icons/fi';
import { AiFillLock, AiFillUnlock, AiOutlineBold, AiOutlineItalic, AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight,
    AiOutlineUnderline, AiOutlineStrikethrough, AiOutlineOrderedList } from "react-icons/ai";
import { RiSendBackward, RiBringForward } from "react-icons/ri";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import Select from 'react-select';
// var FontFaceObserver = require('fontfaceobserver');
var WebFont = require('webfontloader');

const optionStyles = (styles, { data,isDisabled }) => {
    return {
      ...styles,
      fontFamily: data.value, // Set the color based on the 'color' property of the option
      cursor: isDisabled ? 'not-allowed' : 'pointer'
    };
  };
class Toolbar extends Component {
    state = {
        textColor: '',
        glowColor: '',
        strokeColor: '',
        defaultFontSize: {value:"6", label : "6"},
        opacityval: '1',
        strokeval: '1',
        blurval: '1',
        offsetX: '1',
        offsetY: '1',
        isCropping:false,
        isNewCrop:false,
        cropObject:null,
        currentImage:null,
        activeFontFamily: {value:"Montserrat", label : "Montserrat"},
        selectedCanvasObject:null,
        canvasObj:null,
        cropRect:null,
        cropLightRect:null,
        savestateaction: true,
        displayColorPicker: false,
        displaystrokeColorPicker: false,
        displayglowColorPicker: false,
        collapse: false,
        // activeObjectWidth:200,
        activeObjectHeight: 200,
        bulletText:true,
        numberText:true,
        glowcollapse: false,
        open: false,
        backgroundcol: '',
        glowbackgroundcol: '',
        outlinechecked: false,
        glowchecked: false,
        txtlock: false,
        styles: {
            position: 'absolute',
            display: 'none',
        },
        fontFamilyNames:
            [
            {value : "ABeeZee", label : "ABeeZee"},
            {value : "Abel", label : "Abel"},
            {value : "Abril Fatface", label : "Abril Fatface"},
            {value : "Acme", label : "Acme"},
            {value : "Aerolite", label : "Aerolite"},
            {value : "Alegreya", label : "Alegreya"},
            {value : "Alegreya Sans", label : "Alegreya Sans"},
            {value : "Alfa Slab One", label : "Alfa Slab One"},
            {value : "Alex Brush", label : "Alex Brush"},
            {value : "Amatic SC", label : "Amatic SC"},
            {value : "Amiri", label : "Amiri"},
            {value : "Anton", label : "Anton"},
            {value : "Archivo", label : "Archivo"},
            {value : "Archivo Black", label : "Archivo Black"},
            {value : "Archivo Narrow", label : "Archivo Narrow"},
            {value : "Arimo", label : "Arimo"},
            {value : "Arvo", label : "Arvo"},
            {value : "Asap Condensed", label : "Asap Condensed"},
            {value : "Assistant", label : "Assistant"},
            {value : "Barlow", label : "Barlow"},
            {value : "Barlow Condensed", label : "Barlow Condensed"},
            {value : "Barlow Semi Condensed", label : "Barlow Semi Condensed"},
            {value : "Bebas Neue", label : "Bebas Neue"},
            {value : "Bitter", label : "Bitter"},
            {value : "Bree Serif", label : "Bree Serif"},
            {value : "Cabin", label : "Cabin"},
            {value : "Cairo", label : "Cairo"},
            {value : "Catamaran", label : "Catamaran"},
            {value : "Caveat", label : "Caveat"},
            {value : "Chakra Petch", label : "Chakra Petch"},
            {value : "Changa", label : "Changa"},
            {value : "Chancery Bold", label : "Chancery Bold"},
            {value : "Chancery Regular", label : "Chancery Regular"},
            {value : "Cinzel", label : "Cinzel"},
            {value : "Comfortaa", label : "Comfortaa"},
            {value : "Cormorant Garamond", label : "Cormorant Garamond"},
            {value : "Courgette", label : "Courgette"},
            {value : "Covered By Your Grace", label : "Covered By Your Grace"},
            {value : "Crimson Text", label : "Crimson Text"},
            {value : "Dancing Script", label : "Dancing Script"},
            {value : "Didact Gothic", label : "Didact Gothic"},
            {value : "DM Sans", label : "DM Sans"},
            {value : "DM Serif Display", label : "DM Serif Display"},
            {value : "Domine", label : "Domine"},
            {value : "Dosis", label : "Dosis"},
            {value : "EB Garamond", label : "EB Garamond"},
            {value : "El Messiri", label : "El Messiri"},
            {value : "Exo", label : "Exo"},
            {value : "Exo 2", label : "Exo 2"},
            {value : "Fira Sans", label : "Fira Sans"},
            {value : "Fira Sans Condensed", label : "Fira Sans Condensed"},
            {value : "Fjalla One", label : "Fjalla One"},
            {value : "Frank Ruhl Libre", label : "Frank Ruhl Libre"},
            {value : "Fredoka One", label : "Fredoka One"},
            {value : "Gothic A1", label : "Gothic A1"},
            {value : "Heebo", label : "Heebo"},
            {value : "Hind", label : "Hind"},
            {value : "Hind Madurai", label : "Hind Madurai"},
            {value : "Hind Siliguri", label : "Hind Siliguri"},
            {value : "IBM Plex Mono", label : "IBM Plex Mono"},
            {value : "IBM Plex Sans", label : "IBM Plex Sans"},
            {value : "IBM Plex Serif", label : "IBM Plex Serif"},
            {value : "Inconsolata", label : "Inconsolata"},
            {value : "Indie Flower", label : "Indie Flower"},
            {value : "Inter", label : "Inter"},
            {value : "Italianno", label : "Italianno"},
            {value : "Josefin Sans", label : "Josefin Sans"},
            {value : "Jost", label : "Jost"},
            {value : "Journal", label : "Journal"},
            {value : "Kalam", label : "Kalam"},
            {value : "Kanit", label : "Kanit"},
            {value : "Karla", label : "Karla"},
            {value : "Lato", label : "Lato"},
            {value : "Libre Baskerville", label : "Libre Baskerville"},
            {value : "Libre Franklin", label : "Libre Franklin"},
            {value : "Lilita One", label : "Lilita One"},
            {value : "Lora", label : "Lora"},
            {value : "M PLUS 1p", label : "M PLUS 1p"},
            {value : "M PLUS Rounded 1c", label : "M PLUS Rounded 1c"},
            {value : "Manrope", label : "Manrope"},
            {value : "Martel", label : "Martel"},
            {value : "Maven Pro", label : "Maven Pro"},
            {value : "Merriweather", label : "Merriweather"},
            {value : "Merriweather Sans", label : "Merriweather Sans"},
            {value : "Montserrat", label : "Montserrat"},
            {value : "Mukta", label : "Mukta"},
            {value : "Mulish", label : "Mulish"},
            {value : "Nanum Gothic", label : "Nanum Gothic"},
            {value : "Nanum Myeongjo", label : "Nanum Myeongjo"},
            {value : "Noto Sans", label : "Noto Sans"},
            {value : "Noto Sans HK", label : "Noto Sans HK"},
            {value : "Noto Sans JP", label : "Noto Sans JP"},
            {value : "Noto Sans KR", label : "Noto Sans KR"},
            {value : "Noto Sans SC", label : "Noto Sans SC"},
            {value : "Noto Sans TC", label : "Noto Sans TC"},
            {value : "Noto Serif", label : "Noto Serif"},
            {value : "Noto Serif JP", label : "Noto Serif JP"},
            {value : "Nunito,Nunito Sans", label : "Nunito,Nunito Sans"},
            {value : "Open Sans", label : "Open Sans"},
            {value : "Oswald", label : "Oswald"},
            {value : "Oxygen", label : "Oxygen"},
            {value : "Pacifico", label : "Pacifico"},
            {value : "Play", label : "Play"},
            {value : "Playfair Display", label : "Playfair Display"},
            {value : "Poppins", label : "Poppins"},
            {value : "Prompt", label : "Prompt"},
            {value : "PT Sans", label : "PT Sans"},
            {value : "PT Sans Caption", label : "PT Sans Caption"},
            {value : "PT Sans Narrow", label : "PT Sans Narrow"},
            {value : "PT Serif", label : "PT Serif"},
            {value : "Public Sans", label : "Public Sans"},
            {value : "Questrial", label : "Questrial"},
            {value : "Quicksand", label : "Quicksand"},
            {value : "Rajdhani", label : "Rajdhani"},
            {value : "Raleway", label : "Raleway"},
            {value : "Red Hat Display", label : "Red Hat Display"},
            {value : "Roboto", label : "Roboto"},
            {value : "Roboto Condensed", label : "Roboto Condensed"},
            {value : "Roboto Mono", label : "Roboto Mono"},
            {value : "Roboto Slab", label : "Roboto Slab"},
            {value : "Rubik", label : "Rubik"},
            {value : "Saira Condensed", label : "Saira Condensed"},
            {value : "Sarabun", label : "Sarabun"},
            {value : "Satisfy", label : "Satisfy"},
            {value : "Secular One", label : "Secular One"},
            {value : "Shadows Into Light", label : "Shadows Into Light"},
            {value : "Signika", label : "Signika"},
            {value : "Signika Negative", label : "Signika Negative"},
            {value : "Slabo 27px", label : "Slabo 27px"},
            {value : "Source Code Pro", label : "Source Code Pro"},
            {value : "Source Sans Pro", label : "Source Sans Pro"},
            {value : "Source Serif Pro", label : "Source Serif Pro"},
            {value : "Space Grotesk", label : "Space Grotesk"},
            {value : "Spectral", label : "Spectral"},
            {value : "Tajawal", label : "Tajawal"},
            {value : "Tangerine", label : "Tangerine"},
            {value : "Tinos", label : "Tinos"},
            {value : "Titillium Web", label : "Titillium Web"},
            {value : "Ubuntu", label : "Ubuntu"},
            {value : "Ubuntu Condensed", label : "Ubuntu Condensed"},
            {value : "Varela Round", label : "Varela Round"},
            {value : "Vollkorn", label : "Vollkorn"},
            {value : "Work Sans", label : "Work Sans"},
            {value : "Yanone Kaffeesatz", label : "Yanone Kaffeesatz"},
            {value : "Yantramanav", label : "Yantramanav"},
            {value : "Zilla Slab", label : "Zilla Slab"}
        ],
        fontSize : [
            {value : "3", label : "3"},
            {value : "4", label : "4"},
            {value : "6", label : "6"},
            {value : "8", label : "8"},
            {value : "10", label : "10"},
            {value : "12", label : "12"},
            {value : "14", label : "14"},
            {value : "16", label : "16"},
            {value : "18", label : "18"},
            {value : "21", label : "21"},
            {value : "24", label : "24"},
            {value : "28", label : "28"},
            {value : "32", label : "32"},
            {value : "36", label : "36"},
            {value : "42", label : "42"},
            {value : "48", label : "48"},
            {value : "56", label : "56"},
            {value : "64", label : "64"},
            {value : "72", label : "72"},
            {value : "80", label : "80"},
            {value : "88", label : "88"},
            {value : "96", label : "96"},
            {value : "104", label : "104"},
            {value : "120", label : "120"},
            {value : "144", label : "144"},
        ]
    };

    constructor(props) {
        super(props);
        this.textcolorRef = React.createRef();
        this.pickerRef = React.createRef();
        this.textstrokecolRef = React.createRef();
        this.textglowcolRef = React.createRef();
    }

  
    componentDidMount() {
        WebFont.load({
            google: {
              families:["ABeeZee","Abel","Abril Fatface","Acme","Alegreya","Alegreya Sans","Alfa Slab One","Alex Brush","Amatic SC","Amiri","Anton","Archivo","Archivo Black","Archivo Narrow","Arimo","Arvo","Asap Condensed","Assistant","Barlow","Barlow Condensed","Barlow Semi Condensed","Bebas Neue","Bitter","Bree Serif","Cabin","Cairo","Catamaran","Caveat","Chakra Petch","Changa","Cinzel","Comfortaa","Cormorant Garamond","Courgette","Covered By Your Grace","Crimson Text","Dancing Script","Didact Gothic","DM Sans","DM Serif Display","Domine","Dosis","EB Garamond","El Messiri","Exo","Exo 2","Fira Sans","Fira Sans Condensed","Fjalla One","Frank Ruhl Libre","Fredoka One","Gothic A1","Heebo","Hind","Hind Madurai","Hind Siliguri","IBM Plex Mono","IBM Plex Sans","IBM Plex Serif","Inconsolata","Indie Flower","Inter","Italianno","Josefin Sans","Jost","Kalam","Kanit","Karla","Lato","Libre Baskerville","Libre Franklin","Lilita One","Lora","M PLUS 1p","M PLUS Rounded 1c","Manrope","Martel","Maven Pro","Merriweather","Merriweather Sans","Montserrat","Mukta","Mulish","Nanum Gothic","Nanum Myeongjo","Noto Sans","Noto Sans HK","Noto Sans JP","Noto Sans KR","Noto Sans SC","Noto Sans TC","Noto Serif","Noto Serif JP","Nunito,Nunito Sans","Open Sans","Oswald","Overpass","Oxygen","Pacifico","Play","Playfair Display","Poppins","Prompt","PT Sans","PT Sans Caption","PT Sans Narrow","PT Serif","Public Sans","Questrial","Quicksand","Rajdhani","Raleway","Red Hat Display","Roboto","Roboto Condensed","Roboto Mono","Roboto Slab","Rubik","Saira Condensed","Sarabun","Satisfy","Secular One","Shadows Into Light","Signika","Signika Negative","Slabo 27px","Source Code Pro","Source Sans Pro","Source Serif Pro","Space Grotesk","Spectral","Tajawal","Tangerine","Tinos","Titillium Web","Ubuntu","Ubuntu Condensed","Varela Round","Vollkorn","Work Sans","Yanone Kaffeesatz","Yantramanav","Zilla Slab"]
            }
          });

        this.setState({
            activeObjectWidth: 200,
            activeObjectHeight: 200,
            canvasObj:this.props.state.canvas?.getActiveObject()
        })

        $(".strokeeff").hide();
        $(".iconbar").hide();
        document.addEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown=(event)=>{
        let DELETE_KEY=46;
        switch( event.keyCode ) {
            case DELETE_KEY:
                this.deleteItem();
                break;
            default: 
                break;
        }
    }

    componentWillReceiveProps = (newprops) => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();
            if (activeObject) {
                var left = getOffset('front').left;
                var top = getOffset('front').top;
                this.setState({
                    styles: {
                        top: activeObject.top + top - 50,
                        left: activeObject.left + left + activeObject.width * activeObject.scaleX / 2 + 10,
                        position: 'fixed',
                        display: 'block',
                        zIndex: 1000
                    }
                })
            } else {
                this.setState({
                    styles: {
                        display: 'none',
                    }
                })
            }
        }
        this.selobject();
    }

    selobject = () => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();
            // if(activeObject?.styles && activeObject?.hoverCursor === null)
            // {
            //     let outerobj = Object.values(activeObject?.styles)[0]
            //     if(outerobj !== undefined)
            //     {
            //         let objVal = Object.values(outerobj)
            //         objVal && objVal.map((item) => {
            //             Object.keys(item) && Object.keys(item).map((val) => {
            //                 if(val === "fontSize")
            //                 {
            //                     this.setState({
            //                         defaultFontSize: {
            //                             value : '',
            //                             label : ''
            //                         },
            //                     })
            //                 }
            //                 if(val === "fontFamily")
            //                 {
            //                     this.setState({
            //                         activeFontFamily: '',
            //                     })
            //                 }
            //             })
            //         })
            //     }
            // }
            if (!activeObject) return false;
            if (activeObject.type === 'text') {

                this.setState({
                    defaultFontSize: {
                        value : activeObject.fontSize,
                        label : activeObject.fontSize
                    },
                    activeFontFamily : {
                        value : activeObject.fontFamily,
                        label : activeObject.fontFamily
                    },
                    opacityval: Math.round(activeObject.opacity * 100)
                });


                if (activeObject.shadow) {
                    this.setState({
                        offsetX: activeObject.shadow.offsetX,
                        blurval: activeObject.shadow.blur,
                        offsetY: activeObject.shadow.offsetY,
                        glowColor: activeObject.shadow.color,
                        glowbackgroundcol: activeObject.shadow.color,
                        glowcollapse: true,
                        glowchecked: true
                    });

                } else {

                    this.setState({
                        offsetX: 1,
                        blurval: 1,
                        offsetY: 1,
                        glowColor: '',
                        glowcollapse: false,
                        glowbackgroundcol: '',
                        glowchecked: false
                    });
                }

                if (activeObject.strokeWidth && activeObject.stroke) {
                    this.setState({
                        strokeColor: activeObject.stroke,
                        backgroundcol: activeObject.stroke,
                        strokeval: activeObject.strokeWidth,
                        collapse: true,
                        outlinechecked: true
                    });
                } else {

                    this.setState({
                        strokeColor: '',
                        backgroundcol: '',
                        strokeval: 1,
                        collapse: false,
                        outlinechecked: false
                    });

                }
                //It was changing color of picker arrow
                //$(".textcolpick").css("background", activeObject.fill);

                if (activeObject.fontWeight === 'bold') {
                    $(".tbold").css("opacity", "1");
                } else {
                    $(".tbold").css("opacity", "0.5");
                }
                if (activeObject.fontStyle === 'italic') {
                    $(".titalic").css("opacity", "1");
                } else {
                    $(".titalic").css("opacity", "0.5");
                }
                if (activeObject.underline === 'underline') {
                    $(".tunder").css("opacity", "1");
                } else {
                    $(".tunder").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'left') {
                    $(".tleft").css("opacity", "1");
                } else {
                    $(".tleft").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'center') {
                    $(".tcenter").css("opacity", "1");
                } else {
                    $(".tcenter").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'right') {
                    $(".tright").css("opacity", "1");
                } else {
                    $(".tright").css("opacity", "0.5");
                }
            }

            if (activeObject.type === 'path') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
            if (activeObject.type === 'image') {
                this.setState({
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }

            if (activeObject.type === 'group') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
        }
    }

    setStyle = (styleName, value, o) => {
        if (o.setSelectionStyles && o.isEditing) {
            var style = {};
            style[styleName] = value;
            o.setSelectionStyles(style);
        } else {
            o.set(styleName, value);
        }
        o.setCoords();
    }

    setActiveStyle(styleName, value, object) {
        var canvas = this.props.state.canvas;
        object = object || canvas.getActiveObject();
        object.set(styleName, value);
        // if (!object) return;
        // if (object.setSelectionStyles && object.isEditing) {
        //     var style = {};
        //     style[styleName] = value;
        //     object.setSelectionStyles(style);
        //     object.setCoords();
        // } else {
        //     object.set(styleName, value);
        // }
        object.setCoords();
        canvas.renderAll();
    }

    setTextFont = (event) => {
        var self = this;
             
    
        self.setActiveStyle('fontFamily',`${event.value}`);

        this.setState({
            activeFontFamily: event
        })
        this.props.triggerState()

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );

            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)

            },(500))

            // removeStates()
            // removeStates2()
            }
      
    }

    setTextBold = () => {
        var obj=this.props.state.canvas.getActiveObject()
        if(!obj.hasOwnProperty('fontWeight') || (obj.fontWeight && obj.fontWeight==='normal')){
            this.setActiveStyle('fontWeight', 'bold');
        }else{
            this.setActiveStyle('fontWeight', 'normal');
        }
        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }

    setTextItalic = () => {
        var obj=this.props.state.canvas.getActiveObject()
        if(!obj.hasOwnProperty('fontStyle') || (obj.fontStyle && obj.fontStyle==='normal')){
            this.setActiveStyle('fontStyle', 'italic');
        }else{
            this.setActiveStyle('fontStyle', 'normal');
        }
        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }

    setTextUnderline = () => {
        var obj=this.props.state.canvas.getActiveObject()
        if(!obj.hasOwnProperty('underline') || (typeof obj.underline === 'boolean')){
            this.setActiveStyle('underline', 'underline');
        }else{
            this.setActiveStyle('underline', false);
        }
        this.props.state.canvas.renderAll()
        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }

    setTextStrike = () => {
        var obj=this.props.state.canvas.getActiveObject()
        if(!obj.hasOwnProperty('linethrough') || (typeof obj.linethrough === 'boolean')){
            this.setActiveStyle('linethrough', 'linethrough');
        }else{
            this.setActiveStyle('linethrough', false);
        }
        this.props.state.canvas.renderAll()
        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }

    setBulletText=()=>{
        var canvas = this.props.state.canvas;
        var obj=canvas.getActiveObject()
        if(!obj.hasOwnProperty('listType') || typeof obj.listType === 'object' || obj.listType && obj.listType === 'number'){
            this.setActiveStyle('listType', 'bullet');
        }else{
            this.setActiveStyle('listType', null);
        }
        canvas.renderAll()
        this.setState({canvasObj:obj})
        if(obj.listType && obj.listType==='bullet'){
        const fontWeight = obj?.fontWeight ? obj?.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj?.fontStyle : '';
        const underline = obj?.underline ? obj?.underline : '';
        obj.set( {
        name: "list",
        fontStyle:fontStyle ,
        fontSize: obj.fontSize,
        fontWeight:fontWeight,
        objectCaching: false,
        isWrapping: false,
        listType: "bullet",
        listBullet: "●",
        listCounter: 0,
        listNull:""
    })
        const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
            const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
                bullet = this.listType === "bullet"
                        ? [this.listBullet]
                        :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
                bulletLeft = left - style0.fontSize - 2;
            if (line.length) {
                if (!this.isWrapping) {
                    // render the bullet
                    this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                    this.isWrapping = !this.isEndOfWrapping(lineIndex);
                    if (!this.isWrapping) this.listCounter++;
                } else if (this.isEndOfWrapping(lineIndex)) {
                    this.isWrapping = false;
                    this.listCounter++;
                }
            }
            if (lineIndex === this.textLines.length - 1) {
                this.isWrapping = false;
                this.listCounter = 0;
            }
            // render the text line
            this._renderChars(method, ctx, line, left, top, lineIndex);
        };
        obj._renderTextLine = renderTextLine;
        canvas.renderAll()
        if(canvas){
        let frontCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "front"
        );

        let backCanvas=getCanvasBySide(
            this.props.allCanvasList,
            "back"
        );
        addToHistory(frontCanvas)
        addToHistory2(backCanvas)
        }
        }
        else if(typeof obj.listType === 'object'){
            obj.set( {
                name: "list",
                objectCaching: false,
                isWrapping: false,
                listType:null,
                listBullet:null,
                listCounter: 0,
            })
            canvas.renderAll()
            if(canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
            }
        }
    }

    setNumberText=()=>{
        var canvas = this.props.state.canvas;
        var obj=canvas.getActiveObject()
        if(!obj.hasOwnProperty('listType') || typeof obj.listType === 'object' || obj.listType && obj.listType === 'bullet'){
            this.setActiveStyle('listType', 'number');
        }else{
            this.setActiveStyle('listType', null);
        }
        canvas.renderAll()
        this.setState({canvasObj:obj})
        if(obj.listType && obj.listType==='number'){
        const fontWeight = obj?.fontWeight ? obj.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj.fontStyle : '';
        const underline = obj?.underline ? obj.underline : '';
        obj.set( {
        name: "list",
        fontStyle:fontStyle ,
        fontSize: obj.fontSize,
        fontWeight:fontWeight,
        objectCaching: false,
        isWrapping: false,
        listType: "number",
        listBullet: "●",
        listCounter: 0,
        listNull:""
    })
    const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
        const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
            bullet = this.listType === "bullet"
                    ? [this.listBullet]
                    :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
            bulletLeft = left - style0.fontSize - 2;
        if (line.length) {
            if (!this.isWrapping) {
                // render the bullet
                this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                this.isWrapping = !this.isEndOfWrapping(lineIndex);
                if (!this.isWrapping) this.listCounter++;
            } else if (this.isEndOfWrapping(lineIndex)) {
                this.isWrapping = false;
                this.listCounter++;
            }
        }
        if (lineIndex === this.textLines.length - 1) {
            this.isWrapping = false;
            this.listCounter = 0;
        }
        // render the text line
        this._renderChars(method, ctx, line, left, top, lineIndex);
    };
    obj._renderTextLine = renderTextLine;
    canvas.renderAll()
    if(canvas){
    let frontCanvas = getCanvasBySide(
        this.props.allCanvasList,
        "front"
    );
    let backCanvas=getCanvasBySide(
        this.props.allCanvasList,
        "back"
    );
    addToHistory(frontCanvas)
    addToHistory2(backCanvas)
    }
    }
    else if(typeof obj.listType === 'object'){
        obj.set( {
            name: "list",
            objectCaching: false,
            isWrapping: false,
            listType:null,
            listBullet:null,
            listCounter: 0,
        })
        canvas.renderAll()
        if(canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
    }
    }

    setActiveProp = (name, value) => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                object.set(name, value).setCoords();
            });
        } else if (activeObject) {
            activeObject.set(name, value).setCoords();
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }

    alignObjectLeft = (value) => {
        this.setActiveProp('textAlign', 'left');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    alignObjectCenter = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        // activeObject.set({
        //     left: (canvas.width / 2) - ((activeObject.width * activeObject.scaleX) / 2)
        // });
        // activeObject.setCoords();
        this.setActiveProp('textAlign', 'center');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    alignObjectRight = () => {
        this.setActiveProp('textAlign', 'right');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    clearCanvas = () => {

        var canvas = this.props.state.canvas;
        canvas.clear();
    }

    // deleteItem = () => {
    //     var canvas = this.props.state.canvas;
    //      let apiDynamicUrlAddress = config.default.api.host;
    //     var activeObject = canvas.getActiveObject();
    //     var id=activeObject.objId
    //     if (activeObject.type === 'activeSelection') {
    //         activeObject.forEachObject(function (object) {
    //             canvas.remove(object);
    //         });
    //     } else {
    //         canvas.remove(activeObject);

    //     let apiUrl = `${apiDynamicUrlAddress}/deleteItem?id=${id}`
    //     axios.get(apiUrl, {
    //         crossDomain: true,
    //         enablePreflight: false
    //     }).then((response) => {
    //         window.location.reload();

    //     })
    //         .catch((err) => {

    //         });
    //     }
    // }
    deleteItem = () => {
        var canvas = this.props.state.canvas;
        var apiDynamicUrlAddress = config.default.api.host;
        var activeObject = canvas.getActiveObject();
        //this.props.imageObject(activeObject,'delete')
        var id = activeObject.id
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                canvas.remove(object);
                canvas.discardActiveObject();
            });
        } else {
            canvas.remove(activeObject);
        }
        this.props.multipleDelete()
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    setColor = (color) => {
        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
        var canvas = this.props.state.canvas;
        var activeObject=canvas.getActiveObject();
        if (activeObject.type !== 'image' && activeObject.type !== 'activeSelection') {
            activeObject.set({ 'fill': rgbaColor })
            canvas.renderAll();
        }
        activeObject.setCoords()
        this.changeObjectColor(rgbaColor);
        this.setState({ textColor: rgbaColor });
        if(this.textcolorRef.current)
        this.textcolorRef.current.style.background = rgbaColor;
        this.props.triggerState()
        // this.selobject();
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }

    };

    pickerOpen = () => {
        this.setState({
            displayColorPicker: !this.state.displayColorPicker
        })
    };

    rotate = () => {
        var canvas = this.props.state.canvas;

        if (canvas._activeObject) {
            var curAngle = canvas._activeObject.angle;
            canvas._activeObject.rotate(curAngle + 90);
            canvas.renderAll();

            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }
    };

    componentDidUpdate(){
        var canvas=this.props.state.canvas;
        if(canvas)
        {
            canvas.requestRenderAll()
            let activeobject = canvas._activeObject
            let fill = canvas._activeObject?.fill
            let fontSize = canvas._activeObject?.fontSize
            let fontFamily = canvas._activeObject?.fontFamily
            if (activeobject !== this.state.canvasObj) {
                this.setState({
                    canvasObj : activeobject,
                    textColor : fill,
                    defaultFontSize: {
                        value : fontSize,
                        label : fontSize
                    },
                    activeFontFamily: {
                        label : fontFamily,
                        value : fontFamily
                    }
                })
            }
            canvas.off('object:created',this.objectCreated)
            canvas.on('object:created',this.objectCreated)
            canvas.off('object:modified',this.objectModified)
            canvas.on('object:modified',this.objectModified)
            canvas.off('selection:created',this.selectionCreated)
            canvas.on('selection:created',this.selectionCreated)
            canvas.off('selection:updated',this.selectionUpdated)
            canvas.on('selection:updated', this.selectionUpdated)
            canvas.off('selection:clear',this.selectionCleared)
            canvas.on('selection:clear',this.selectionCleared )
            canvas.off('mouse:up',this.funcMouseUp)
            canvas.on('mouse:up',this.funcMouseUp)
            canvas.off('after:render',this.funcAfterRender)
            canvas.on('after:render',this.funcAfterRender)
        }
    }

    eventObjectModified(object) {
        var canvas=this.props.state.canvas;

        var currentObject=this.state.currentImage;
        var selectedCanvas=this.props.state.canvas;
        var selectedCanvasObject=canvas.getObjects().find((item)=>{return item.name==="selectionRect"});

        if (selectedCanvasObject) {
            /* Check the crop object do not cross the image */
            if (selectedCanvasObject.left < currentObject.left - ((currentObject.getScaledWidth() - selectedCanvasObject.getScaledWidth())/2)) {
                selectedCanvasObject.left = currentObject.left - ((currentObject.getScaledWidth() - selectedCanvasObject.getScaledWidth())/2)
                selectedCanvasObject.setCoords()
                if (
                    selectedCanvasObject.left + selectedCanvasObject.getScaledWidth() >
                    currentObject.left + currentObject.getScaledWidth()
                ) {
                    selectedCanvasObject.width = currentObject.getScaledWidth()
                    selectedCanvasObject.scaleX = 1
                    selectedCanvasObject.setCoords()
                }
            }
            if (selectedCanvasObject.top < currentObject.top - ((currentObject.getScaledHeight() - selectedCanvasObject.getScaledHeight())/2)) {
                selectedCanvasObject.top = currentObject.top - ((currentObject.getScaledHeight() - selectedCanvasObject.getScaledHeight())/2)
                selectedCanvasObject.setCoords()
                if (
                    selectedCanvasObject.top + selectedCanvasObject.getScaledHeight() >
                    currentObject.top + currentObject.getScaledHeight()
                ) {
                    selectedCanvasObject.height = currentObject.getScaledHeight()
                    selectedCanvasObject.scaleY = 1
                    selectedCanvasObject.setCoords()
                }
            }   
            if (
                selectedCanvasObject.left >
                currentObject.left +
                    ((currentObject.getScaledWidth() - selectedCanvasObject.getScaledWidth())/2)
            ) {
                if (selectedCanvasObject.getScaledWidth() > currentObject.getScaledWidth()) {
                    selectedCanvasObject.left = currentObject.left
                    selectedCanvasObject.width = currentObject.getScaledWidth()
                    selectedCanvasObject.scaleX = 1
                } else {
                    selectedCanvasObject.left =
                        currentObject.left +
                        ((currentObject.getScaledWidth() -
                        selectedCanvasObject.getScaledWidth())/2)
                }
                selectedCanvasObject.setCoords()
            }
            if (
                selectedCanvasObject.top >
                currentObject.top +
                    ((currentObject.getScaledHeight() - selectedCanvasObject.getScaledHeight())/2)
            ) {
                if (
                    selectedCanvasObject.getScaledHeight() > currentObject.getScaledHeight()
                ) {
                    selectedCanvasObject.top = currentObject.top
                    selectedCanvasObject.height = currentObject.getScaledHeight()
                    selectedCanvasObject.scaleY = 1
                } else {
                    selectedCanvasObject.top =
                        currentObject.top +
                        ((currentObject.getScaledHeight() -
                        selectedCanvasObject.getScaledHeight())/2)
                }
                selectedCanvasObject.setCoords()
            }
        }
    }

    objectModified=(event)=>{
        this.eventObjectModified(event)
        let canvas=this.props.state.canvas
        var target = event.target;

        // Check if the target is the common box (active selection)
        if (target && target.type === 'activeSelection') {
            // Get the clicked point coordinates
            var pointer = canvas.getPointer(event.e);

            // Find the innermost object at the clicked point
            var clickedObject = canvas.findTarget(pointer, function(obj) {
            return obj.type === 'textbox';
            });

            if (clickedObject) {
            // Remove the common controls
            canvas.discardActiveObject();

            // Activate the clicked individual box
            canvas.setActiveObject(clickedObject);
            canvas.requestRenderAll();
            }
        }
        if (target && target.type === 'textbox') {
        let activeobj = this.props.state.canvas.getActiveObject()
        let obj = activeobj.fontSize * activeobj.scaleX
        let formattedValue = obj.toFixed(2);
        if (formattedValue.endsWith(".00")) {
            formattedValue = obj.toFixed(0);
        }
        if((obj.toString() ||formattedValue) !== "NaN" )
        {
            if (
                !this.state.fontSize.some((item) => item.value === obj.toString()) &&
                !this.state.fontSize.some((item) => item.value === formattedValue)
            ) {
                this.state.fontSize.push({value : formattedValue, label: formattedValue})
                setTimeout(() => {
                    this.setState({
                        defaultFontSize: {
                            value : formattedValue,
                            label : formattedValue
                        },
                    })
                }, 100);
            }
            else{
                this.setState({
                    defaultFontSize: {
                        value : formattedValue,
                        label : formattedValue
                    },
                })
            }
            this.setActiveStyle('fontSize', formattedValue);
            activeobj.set({
                scaleX : 1,
                scaleY : 1,
            })
        }
        canvas.renderAll()
        }
        this.setState({canvasObj:this.props.state.canvas.getActiveObject()})
        if(canvas._activeObject.name != 'selectionRect')
        {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
        }

    objectCreated=(e)=>{
        let canvas=this.props.state.canvas
        this.eventObjectModified(e)
        this.setState({canvasObj:this.props.state.canvas.getActiveObject()})
        if(canvas._activeObject.name != 'selectionRect')
        {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            removeStates()
            removeStates2()
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
        }
        
}
    selectionCreated=()=>{
        let canvas=this.props.state.canvas
        let fill=canvas?.getActiveObject().fill;
        this.setState({
            textColor : fill
        })
        this.setState({canvasObj:canvas.getActiveObject()})
        this.setState({
            defaultFontSize: {value : canvas.getActiveObject().fontSize, label : canvas.getActiveObject().fontSize}})

            if (canvas.getActiveObject().get('type') == 'textbox')
            {
                this.setState({
                    defaultFontSize: {
                        value : canvas?.getActiveObject().fontSize,
                        label : canvas?.getActiveObject().fontSize
                    },
                    activeFontFamily: {
                        label : canvas?.getActiveObject().fontFamily,
                        value : canvas?.getActiveObject().fontFamily
                    }
                    },
                )
            }
    //     // let object1=canvas.getActiveObject();
    //     // let array=object1?.styles.flat()
    //     // let finalArray=array-object1.textLines;
    //     //  this.setState({activeFontFamily:finalArray[object1.selectionEnd.fontFamily]})
    //     // this.setState({canvasObj:canvas.getActiveObject()})
    }
    selectionUpdated=()=>{
        let canvas=this.props.state.canvas
                
        let fill=canvas?.getActiveObject().fill;
        this.setState({
            textColor : fill
        })
       var object=canvas.getActiveObject();
        if (canvas?.getActiveObject().get('type') == 'textbox')
        {



        this.setState({ textColor: canvas?.getActiveObject().fill });
        this.setState({
            defaultFontSize: {
            value : canvas?.getActiveObject().fontSize,
            label : canvas?.getActiveObject().fontSize
        },
        activeFontFamily : {
            value : canvas?.getActiveObject().fontFamily,
            label : canvas?.getActiveObject().fontFamily
        }
        },
    )
    }
        if(this.state.currentImage!==object && object.name!=="selectionRect")
        {
        this.setState({isCropping:false})
        let obj=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
        canvas.remove(obj)  
        }
    }
    selectionCleared=()=>{
        this.setState({canvasObj:this.props.state.canvas.getActiveObject()})
    }
    funcMouseUp=(event)=>{
        let canvas=this.props.state.canvas
        var target = event.target;
        const activeObjects = canvas._activeObject && canvas._activeObject._objects;
        const active = canvas.getActiveObject()
        let text = 'Type text here'
        if(active && active.text === 'Type text here' && active && active.type === 'textbox')
        {
            active && active.set({ text: '' });
            active && active.enterEditing();
            active && active.selectAll();
            canvas.renderAll();
        }
        // Handle text editing event
        active && active.on('editing:entered', function(options) {
            if (active && active.text.toLowerCase() === text && text.toLowerCase()) {
                active && active.set({ text: '' })
                active && active.selectAll(); // Select all text when entering editing mode and default text is present
                canvas.renderAll();
            }
        });
        // Handle selection cleared event
        canvas && canvas.on('selection:cleared', function() {
            const lowerCaseText = active?.text?.toLowerCase();
            if (!lowerCaseText?.trim()) {
                active && active.set({ text: text }); // Reset text to default if it's empty or contains only whitespace
                canvas.renderAll();
            }
        });
        // Handle text editing exit event
        active && active.on('editing:exited', function(options) {
            const editedText = active?.text.trim();
            if (!editedText) {
                active && active.set({ text: text }); // Reset text to default if edited text is empty
            }
            canvas.renderAll();
        });
        if(!active || !target)
        {
            if(active)
            {
                canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
                active._objects = active._objects && active._objects.filter(element => element.name !== 'selectionRect');
            }else{
                canvas._objects = canvas._objects.filter(element => element.name !== 'selectionRect');
            }
        }
        if (activeObjects && activeObjects.length > 0) {
            activeObjects.forEach((object) => {
                if(object.lockMovementX && object.lockMovementY && object.lockScalingX && object.lockScalingY && object.isLocked && object.lockRotation)
                {
                    this.setState({
                        lock : true
                    })
                    if (canvas._activeObject) {
                        canvas._activeObject.editable = false;
                        canvas._activeObject.isLocked = true
                        canvas._activeObject.lockMovementX = true;
                        canvas._activeObject.lockMovementY = true;
                        canvas._activeObject.lockScalingX = true;
                        canvas._activeObject.lockScalingY = true;
                        canvas._activeObject.lockRotation = true;
                    }
                }
            });
        }
        // Check if the target is the common box (active selection)
        if (target && target.type === 'activeSelection') {
            // Get the clicked point coordinates
            var pointer = canvas.getPointer(event.e);

            // Find the innermost object at the clicked point
            var clickedObject = canvas.findTarget(pointer, function(obj) {
            return obj.type === 'textbox';
            });

            if (clickedObject) {
            // Remove the common controls
            canvas.discardActiveObject();

            // Activate the clicked individual box
            canvas.setActiveObject(clickedObject);
            let activeobj = this.props.state.canvas.getActiveObject()
            let obj = activeobj.fontSize * activeobj.scaleX
            let formattedValue = obj.toFixed(2);
            if (formattedValue.endsWith(".00")) {
                formattedValue = obj.toFixed(0);
            }
            if (
                !this.state.fontSize.some((item) => item.value === obj.toString()) &&
                !this.state.fontSize.some((item) => item.value === formattedValue)
            ) {
                this.state.fontSize.push({value : formattedValue, label : formattedValue})
                this.setState({
                    defaultFontSize: {
                        value : formattedValue,
                        label : formattedValue
                    },
                })
            }
            canvas.requestRenderAll();
            }
        }
        this.setState({
            canvasObj : this.props.state.canvas.getActiveObject()
        })
         let object=canvas.getActiveObject();
         if(object)
         {
            this.setState({ textColor: object.fill });
            if(object?.isLocked)
            {
                
                object.set({
                    lockMovementX : true,
                    lockMovementY : true
                })
            }
        //  else{
        //         object.set({
        //             lockMovementX : false,
        //             lockMovementY : false
        //         })
        //     }
        }
        if(object?.type==="textbox"){
         let array=[]
      
     
        let index=object?.selectionEnd-1
        const str = object?.text; 
        
        const result = str.split(/\r?\n/);

          for(let i=0;i<parseInt(result.length);i++){
              
                  array.push(parseInt(result[i].length))
          }

          let subArray
          let total=0;
         
          for(let i=0;i<parseInt(array.length);i++){
             
             total=total+parseInt(array[i])
              if(parseInt(index)<=total)
              {
              subArray=i;
              break;
              }
          }
      

         let final=0;
         
          for(let i=0;i<subArray;i++)
          {
              final=final+array[i]
          }
         let position=index-final-subArray
        if(object.styles[subArray]){
              let obj=object.styles
        if(obj[subArray][position])
        {
            Object.keys(obj[subArray][position]).map((val)=> {
                if(val === 'fontSize')
                {
                    this.setState({
                        defaultFontSize: {
                            value : obj[subArray][position]?.fontSize,
                            label : obj[subArray][position]?.fontSize
                        },
                    });
                }
                if(val === 'fontFamily')
                {
                    this.setState({activeFontFamily:{
                        value : obj[subArray][position]?.fontFamily,
                        label : obj[subArray][position]?.fontFamily,
                    }
                    })
                }
                if(val === 'fontStyle')
                {
                    this.setState({fontStyle: obj[subArray][position]?.fontStyle})
                }
                if(val === 'fontWeight')
                {
                    this.setState({fontWeight: obj[subArray][position]?.fontWeight})
                }
            })
            this.setState({textColor:obj[subArray][position]?.fill})
            if(this.textcolorRef.current)
            this.textcolorRef.current.style.background = obj[subArray][position]?.fill;
            canvas.renderAll()
        }
        else{
            this.setState({
                defaultFontSize: {
                    value : object.fontSize,
                    label : object.fontSize
                },
                activeFontFamily:{
                    value : object.fontFamily,
                    label : object.fontFamily
                }
            })
          this.setState({textColor:object.fill})
          this.textcolorRef.current.style.background = object.fill;

          canvas.renderAll()
        }
      }
      else{
        let activeobj = this.props.state.canvas.getActiveObject()
        let obj = activeobj.fontSize * activeobj.scaleX
        let formattedValue = obj.toFixed(2);
        if (formattedValue.endsWith(".00")) {
            formattedValue = obj.toFixed(0);
        }
        if((obj.toString() ||formattedValue) !== "NaN" )
        {
            if (
                !this.state.fontSize.some((item) => item.value === obj.toString()) &&
                !this.state.fontSize.some((item) => item.value === formattedValue)
            ) {
                this.state.fontSize.push({value : formattedValue, label: formattedValue})
                setTimeout(() => {
                    this.setState({
                        defaultFontSize: {
                            value : formattedValue,
                            label : formattedValue
                        },
                    })
                }, 100);
            }
            else{
                this.setState({
                    defaultFontSize: {
                        value : formattedValue,
                        label : formattedValue
                    },
                })
            }
            this.setActiveStyle('fontSize', formattedValue);
            object.set({
                scaleX : 1,
                scaleY : 1,
            })
        }
        this.setState({
            activeFontFamily:{
                value : object.fontFamily,
                label : object.fontFamily
            }
        })
          this.setState({textColor:object.fill})
          canvas.renderAll()
        }

     
      
         subArray=null;
         final=0;
         array=[]
        }
  }
    funcAfterRender=()=>{
        this.props.state.canvas.getActiveObjects().forEach(function(obj){
            obj.setCoords();
        })
    }




    lock = () => {
        this.setState({
            lock: true,
        })
        var canvas = this.props.state.canvas;
        
        if (canvas._activeObject) {
            canvas._activeObject.editable = false;
            canvas._activeObject.isLocked = true
            canvas._activeObject.lockMovementX = true;
            canvas._activeObject.lockMovementY = true;
            canvas._activeObject.lockScalingX = true;
            canvas._activeObject.lockScalingY = true;
            canvas._activeObject.lockRotation = true;

        const activeObjects = canvas._activeObject._objects;
        if (activeObjects && activeObjects.length > 0) {
            activeObjects.forEach((object) => {
                object.editable = false;
                object.isLocked = true;
                object.lockMovementX = true;
                object.lockMovementY = true;
                object.lockScalingX = true;
                object.lockScalingY = true;
                object.lockRotation = true;
            });
        }
            canvas.renderAll();
            this.props.triggerState()
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
       
           
        }
    };
    unlock = () => {
        this.setState({
            lock: false
        })
        var canvas = this.props.state.canvas;
        if (canvas._activeObject) {
            canvas._activeObject.editable = true;
            canvas._activeObject.isLocked = false
            canvas._activeObject.lockMovementX = false;
            canvas._activeObject.lockMovementY = false;
            canvas._activeObject.lockScalingX = false;
            canvas._activeObject.lockScalingY = false;
            canvas._activeObject.lockRotation = false;
            const activeObjects = canvas._activeObject._objects;
        if (activeObjects && activeObjects.length > 0) {
            activeObjects.forEach((object) => {
                object.editable = true;
                object.isLocked = false;
                object.lockMovementX = false;
                object.lockMovementY = false;
                object.lockScalingX = false;
                object.lockScalingY = false;
                object.lockRotation = false;
            });
        }
            canvas.renderAll();
            this.props.triggerState()
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
          
        }
    };
    pickerClose = () => {
        this.setState({
            displayColorPicker: false
        })
    };

    strokepickerOpen = () => {
        this.setState({
            displaystrokeColorPicker: !this.state.displaystrokeColorPicker
        })
    };

    strokepickerClose = () => {
        this.setState({
            displaystrokeColorPicker: false
        })
    };

    glowpickerOpen = () => {
        this.setState({
            displayglowColorPicker: !this.state.displayglowColorPicker
        })
    };

    glowpickerClose = () => {
        this.setState({
            displayglowColorPicker: false
        })
    };

    setStroke = (color) => {
        this.changeObjectproperty('stroke', color.hex);
        this.setState({
            strokeColor: color.hex
        });
        this.textstrokecolRef.current.style.background = color.hex;
    };

    changeObjectColor = (hex) => {
        // this.changeObjectproperty('fill', hex);
    }

    changeObjectproperty(style, hex) {
        let lthis = this;
        var canvas = this.props.state.canvas;
        let obj = canvas.selectedObject;

        if (!obj)
            obj = canvas.getActiveObject();

        if (obj) {
            if (obj.paths) {
                for (let i = 0; i < obj.paths.length; i++) {
                    this.setActiveStyle(style, hex, obj.paths[i]);
                }
            } else if (obj.type === "group") {
                let objects = obj.getObjects();
                for (let i = 0; i < objects.length; i++) {
                    this.setActiveStyle(style, hex, objects[i]);
                }
            }
            else this.setActiveStyle(style, hex, obj);
        } else {
            let grpobjs = canvas.getActiveObjects();
            if (grpobjs) {
                grpobjs.forEach(function (object) {
                    if (object.paths) {
                        for (let i = 0; i < object.paths.length; i++) {
                            lthis.setActiveStyle(style, hex, obj.paths[i]);
                        }
                    }
                    else lthis.setActiveStyle(style, hex, obj);
                });
            }
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }

    fontSize = (event) => {
        this.setState({
            defaultFontSize: event,
        });
        let canvas=this.props.state.canvas
        var activeObject = canvas.getActiveObject();
        activeObject.set('scaleX', 1);
        activeObject.set('scaleY', 1);
        this.setActiveStyle('fontSize', event.value);
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    clone = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return false;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject((object) => {
                this.cloneSelObject(object);
            });
        }
        else {
            this.cloneSelObject(activeObject);
        }
   

    }

    cloneSelObject = (actobj) => {
        var canvas = this.props.state.canvas;
        canvas.discardActiveObject();
        // if (fabric.util.getKlass(actobj.type).async) {
        //     var clone = fabric.util.object.clone(actobj);
        //     clone.set({
        //         id:Math.floor(Math.random() * 1000),
        //         top: actobj.top + 30
        //     });
        //     canvas.add(clone);
        //     saveCanvasState(canvas);
       
        // } else {
        //     var clones = fabric.util.object.clone(actobj);
        //     canvas.add(clones.set({
        //         id:Math.floor(Math.random() * 1000),
        //         top: actobj.top + 30
        //     }));
        //     saveCanvasState(canvas);
         
        // }

        actobj.clone(function (o) {
            var vobj = o;
            if (vobj) {
                vobj.set({
            
                    top:actobj.top + 30
                });
                canvas.add(vobj);
                canvas.renderAll();
                canvas.calcOffset();
            } 
        });
        
        canvas.requestRenderAll();
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );

            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
            },(300))
          
            // removeStates()
            // removeStates2()
            }
      
    }

    setOpacity = (event) => {
        this.setState({
            opacityval: event.target.value
        });
        this.setActiveStyle('opacity', event.target.value / 100);
    }

    setStrokeval = (event) => {
        this.setState({
            strokeval: event.target.value
        });
        this.changeObjectproperty('strokeWidth', event.target.value * 1);
    }

    outlinetoggle = () => {
        this.setState({
            collapse: !this.state.collapse
        })
        if (this.state.collapse === true) {
            this.changeObjectproperty('strokeWidth', null);
            this.changeObjectproperty('stroke', null);
        } else {
            this.changeObjectproperty('strokeWidth', this.state.strokeval);
            this.changeObjectproperty('stroke', this.state.strokeColor);
        }
    }

    setGlow = (color) => {
        this.textglowcolRef.current.style.background = color.hex;
        this.setState({
            glowColor: color.hex
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                color: color.hex,
                blur: 1,
                offsetX: 1,
                offsetY: 1
            });
        }
        canvas.renderAll();
    }
    shapeUpload = (event) => {
        var canvas = this.props.state.canvas;
        var reader = new FileReader();
        reader.onload = function (event) {
            var image = new Image();
            image.src = event.target.result;
            image.onload = function () {
                var img = new fabric.Image(image);
                img.set({
                    id:Math.floor(Math.random() * 1000),
                    left: 100,
                    top: 100
                });
                var activeObject = canvas.getActiveObject();
                activeObject.set('fill', new fabric.Pattern({
                    source: image,
                    repeat: 'repeat'
                }));
                canvas.renderAll();
            }
        }
        reader.readAsDataURL(event.target.files[0]);

    }
    setglowblur = (event) => {
        this.setState({
            blurval: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: event.target.value,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetX = (event) => {
        this.setState({
            offsetX: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: event.target.value,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetY = (event) => {
        this.setState({
            offsetY: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: event.target.value
            });
        }
        canvas.renderAll();
    }

    glowtoggle = () => {
        this.setState({
            glowcollapse: !this.state.glowcollapse
        })
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (this.state.glowcollapse === true) {
            if (activeObject) {
                activeObject.setShadow({
                    color: '',
                    blur: 0,
                    offsetX: 0,
                    offsetY: 0
                });
            }
            canvas.renderAll();
        } else {
            if (activeObject) {
                activeObject.setShadow({
                    color: this.state.glowColor,
                    blur: this.state.blurval,
                    offsetX: this.state.offsetX,
                    offsetY: this.state.offsetY
                });
            }
            canvas.renderAll();
        }
    }

    bringForward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        this.props.triggerState()
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.bringForward(object);
                }
                canvas.renderAll();
                saveCanvasState(canvas);
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
            });
        } else {

            if(activeObject?.isLocked !== true){
                canvas.bringForward(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);
            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }

        }
    }

    sendBackward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        this.props.triggerState()
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.sendBackwards(object);
                }

                canvas.renderAll();
                saveCanvasState(canvas);
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
            });
        } else {
            if(activeObject?.isLocked !== false){
                canvas.sendBackwards(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);
            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }
    }
    setWidth = () => {
        var canvas = this.props.state.canvas;
        $(".width").click(function () {
            //var widdth = $(this).val()



            //var activeObject = canvas.getActiveObject();

            var grpobjs = canvas.getActiveObjects();

            if (grpobjs) {
                grpobjs.forEach((object) => {

                    if (object.width) {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                        // this.setState({
                        //     activeObjectHeight:object.width
                        // })
                    }
                    else {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                    }
                    canvas.renderAll();
                    saveCanvasState(canvas);
                });
            }
            // else {
            //     if (activeObject.width) activeObject.width = object.width+10;
            //     else activeObject.width = object.width+10;
            //     canvas.renderAll();
            //     saveCanvasState(canvas);

            // }
        });
    }
    setHeight = () => {
        // $(".width").click(function () {
        // var canvas = this.props.state.canvas;
        // var activeObject = canvas.getActiveObject();

        // var grpobjs = canvas.getActiveObjects();

        // if (grpobjs) {
        //     grpobjs.forEach((object) => {

        //         if (object.height)
        //         {object.height = object.height+5;
        //             object.radius = object.radius+5;
        //             this.setState({
        //                 activeObjectHeight:object.height
        //             })
        //         }
        //         else {
        //             object.height = object.height+5;
        //             object.radius = object.radius+5;
        //         }
        //         canvas.renderAll();
        //         saveCanvasState(canvas);
        //     });
        // }
        // // else {
        // //     if (activeObject.width) activeObject.width = object.width+10;
        // //     else activeObject.width = object.width+10;
        // //     canvas.renderAll();
        // //     saveCanvasState(canvas);

        // // }
        // });
    }
    horizontalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipX) object.flipX = false;
                else object.flipX = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipX) activeObject.flipX = false;
            else activeObject.flipX = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }
    verticalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipY) object.flipY = false;
                else object.flipY = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipY) activeObject.flipY = false;
            else activeObject.flipY = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }

    groupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        canvas.getActiveObject().toGroup();
        selectObject(canvas);
        canvas.renderAll();
    }

    unGroupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'group') {
            return;
        }
        canvas.getActiveObject().toActiveSelection();
        selectObject(canvas);
        canvas.renderAll();
    }

    popupClose = () => {
        this.setState({
            glowcollapse: false,
            open: false,
            collapse: false,
            outlinechecked: false,
            glowchecked: false
        })
    }

    popupOpen = () => {

        this.setState({
            open: true
        });
    }

    outlineCheckBox = (e) => {
        this.setState({
            outlinechecked: e.target.checked
        })
    }

    glowCheckBox = (e) => {
        this.setState({
            glowchecked: e.target.checked
        })
    }


   cropCancel=()=>{
    var canvas=this.props.state.canvas;
    var currentImage=this.state.currentImage;
    var selectionRect=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
    if(selectionRect)
    {
    canvas.remove(selectionRect)
    }
    if(currentImage.clipPath)
    {
        currentImage.set({clipath:null})
    }
    this.setState({isCropping:false})

   }

    cropDone=()=>{
    
        var canvas=this.props.state.canvas;
        let zoomLevel = $("#my-range").val()
        zoomLevel=zoomLevel/100;
        var currentImage=this.state.currentImage;
        var tempCanvas1 = new fabric.Canvas('canvas-ele', {
            width: fabric.Canvas.originalWidth,
            height:fabric.Canvas.originalHeight
        });
        tempCanvas1.set({height:fabric.Canvas.originalHeight*4,width:fabric.Canvas.originalWidth*4})
        tempCanvas1.setZoom(4)
        
                     
            tempCanvas1.add(currentImage);
            tempCanvas1.renderAll()
        var selectionRect=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
        selectionRect.set({fill:null,opacity:0})
        tempCanvas1.add(selectionRect);


          var value=selectionRect.getBoundingRect();

          
          currentImage.setCoords()

        currentImage.selectable = true;

        var cropped = new Image();

        cropped.src = tempCanvas1.toDataURL({
            left: value.left,
            top: value.top,
            width: value.width,
            height: value.height,
            quality:1.0
        });

        cropped.onload = function() {
           var image = new fabric.Image(cropped);
          
        //    image.height=value.height;
        //    image.width=value.width;

        image.set({
            top:30,
            left:10,
            height:value.height,
            width:value.width,
            id: Math.floor(Math.random() * 1000),
            opacity: 1,
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 10,
            padding: 5,
            cornerStyle: "circle",
            src: cropped.src,
            imgName : currentImage.imgName
        })
        var scale;
        if(selectionRect.getScaledWidth()<image.getScaledWidth()){
        scale=(selectionRect.getScaledWidth())/image.getScaledWidth()
       image.set({top:selectionRect.top,left:selectionRect.left,scaleX:scale,scaleY:scale})

        }
        else if(selectionRect.getScaledHeight()<image.getScaledHeight())
        {
         scale=(selectionRect.getScaledHeight())/image.getScaledHeight()
        image.set({top:value.top,left:value.left,scaleY:scale,scaleX:scale})
        }
        image.set({
            originX: "center",
            originY: "center",
        })

        
      
        tempCanvas1.set({height:fabric.Canvas.originalHeight*zoomLevel,width:fabric.Canvas.originalWidth*zoomLevel})
        tempCanvas1.setZoom(zoomLevel)
      
setTimeout(()=>{
    canvas.add(image);
    image.setCoords();

    canvas.renderAll();


},(100))

          
        };
     


       canvas.remove(currentImage)
        canvas.remove(selectionRect);
        tempCanvas1.remove(selectionRect);

        tempCanvas1.remove(currentImage)

        tempCanvas1.renderAll()


          canvas.renderAll();
          if(canvas){
            let frontCanvas = getCanvasBySide(
                this.props?.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props?.allCanvasList,
                "back"
            );
            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
            },(300))
            }
    this.setState({isCropping:false})


    }

    // cropDone=()=>{
    //     let canvas=this.props.state.canvas;
    //     let currentImage=this.state.currentImage;
    //    let secondaryImage=this.props.setImage;
    //    console.log("SetImage",secondaryImage)
    
    //     let scalex=(secondaryImage.width/secondaryImage.getScaledWidth());
    //     let scaley=(secondaryImage.height/secondaryImage.getScaledHeight())
    

    //     let tempCanvas1 = new fabric.Canvas('canvas-ele', {
    //         height:secondaryImage.height,
    //         width:secondaryImage.width})

    //         console.log("Scales",currentImage.width,secondaryImage.width,currentImage.getScaledWidth(),secondaryImage.getScaledWidth(),currentImage.height,secondaryImage.height,currentImage.getScaledHeight(),secondaryImage.getScaledHeight(),currentImage.scaleY,secondaryImage.scaleY,currentImage.scaleX,secondaryImage.scaleX,currentImage)
    
       
    //      currentImage.set({left:0,top:0,scaleX:secondaryImage.scaleX*scalex,scaleY:secondaryImage.scaleY*scaley})       
    //       tempCanvas1.add(currentImage);
    
    //       let obj=tempCanvas1.toDataURL();
    //       console.log("obj",obj)
    //       tempCanvas1.renderAll();
    
    // }




    editCrop=()=>{

        var canvas=this.props.state.canvas;
        // canvas.preserveObjectStacking = true;

        var activeObject=canvas.getActiveObject();
         if(activeObject.clipPath){
             activeObject.clipPath=null
         }
        if(activeObject.type==="image"){
        this.addSelectionRect(activeObject,canvas);
        }
    }

    addSelectionRect=(activeObject,canvas)=>{
     this.setState({currentImage:activeObject})
       var selectionRect = new fabric.Rect({
           top:activeObject.top,
           left:activeObject.left,
            fill: 'rgba(0,0,0,0.3)',
            originX: 'center',
            originY: 'center',
            stroke: 'black',
            opacity: 1,
            height:activeObject.getScaledHeight()/2,
            width:activeObject.getScaledWidth()/2,
            hasRotatingPoint: false,
            transparentCorners: false,
            cornerColor: 'white',
            cornerStrokeColor: 'black',
            borderColor: 'black',
            cornerSize: 12,
            padding: 0,
            cornerStyle: 'circle',
            borderDashArray: [5, 5],
            borderScaleFactor: 1.3,
            name:"selectionRect"
          });
          selectionRect.visible = true;
          canvas.add(selectionRect);
          canvas.setActiveObject(selectionRect);
    }
    


    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
            top: '46px',
            left: '0',
        }
        const popover2 = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',

        }


        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        const strokepopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const strokecover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        /*const glowpopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const glowcover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }*/

        const { canvas } = this.props.state;
        let isObjectLocked = false
        let selectionrect = true;
        //const { activeObjectWidth, activeObjectHeight } = this.state;
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            canvas._objects.forEach((item)=>{
                if(item.name === 'selectionRect')
                {
                    selectionrect = false
                }
            })
            if (!activeObject) {
                return null;
            } else {
                isObjectLocked = activeObject?.isLocked ? activeObject.isLocked: false
            }
        } else {
            return null;
        }




        return (
            <div>
            {($(window).width()>568)?
                <Container className="toolbar-container" style={{marginLeft:"auto",width:"fit-content", height: "65px"}}>

                <span className="leftbar">
                        <div className="colrsec" title="Rotate">
                            <FiRotateCw style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}} onClick={isObjectLocked? null : this.rotate}/>
                            <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Rotate</p>
                        </div>
                        {!isObjectLocked ? <div className="colrsec" title="Lock">
                            <AiFillLock onClick={this.lock}/>
                            <p>Lock</p>
                        </div>: ''}
                        {isObjectLocked ? <div className="colrsec" title="Unlock">
                            <AiFillUnlock onClick={this.unlock}/>
                            <p>Unlock</p>
                        </div>: ''}
                     { canvas.getActiveObject().type==="textbox" &&
                     
                    <div title="Font Family" className="colrsec" style={{cursor : isObjectLocked ? 'no-drop' : ''}}>
                        {/* <FontPicker
                            ref={this.pickerRef}
                            apiKey="AIzaSyCOyeDUsAnL-jnWudXBKNNma9cXmXsT4tM"
                            activeFontFamily={this.state.activeFontFamily}
                            families={fontFamilyNames}
                            limit="150"
                            onChange={nextFont => {this.setTextFont(nextFont.family)
                            }}
                        /> */}
                        <Select
                            className='font-family-dropdown'
                            value={this.state.activeFontFamily}
                            onChange={this.setTextFont}
                            options={this.state.fontFamilyNames}
                            styles={{
                                option: optionStyles, // Apply the custom styles to the options
                            }}
                            isDisabled={isObjectLocked}
                        />
                        {/* <select value={this.state.activeFontFamily} onChange={this.setTextFont} className='font-family-select' disabled={isObjectLocked} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                            {this.state.fontFamilyNames.map((item)=>{
                            return <option style={{fontFamily:item}}>{item}</option>
                            })}
                        </select> */}
                        <p style={{cursor : isObjectLocked ? 'auto' : 'pointer', color : isObjectLocked ? "#ccc" : ''}}>Font Type</p>
                    </div>}
 { canvas.getActiveObject().type==="textbox" &&
                    <div style={{display : "flex"}}>
                    <div className="select-container  colrsec" title="Font Size">
                        {/* <select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC',cursor: isObjectLocked ? "no-drop" : 'pointer', marginBottom:"3px" }} disabled={isObjectLocked}>
                        {
                            this.state.fontSize.map((item)=>{
                                return (
                                    <option>
                                        {item}
                                    </option>
                                )
                            })
                        }
                        </select> */}
                        <Select
                            className='font-size-dropdown'
                            value={this.state.defaultFontSize}
                            onChange={this.fontSize}
                            options={this.state.fontSize}
                            styles={{
                                option: optionStyles, // Apply the custom styles to the options
                            }}
                            isDisabled={isObjectLocked}
                        />
                        <p style={{cursor : isObjectLocked ? 'auto' : 'pointer', color : isObjectLocked ? "#ccc" : ''}}>Font Size</p>
                    </div>
                    <div style={{margin : "0 20px 0 -18px"}}>
                        (px)
                    </div>
                    </div>
                    
    }
    { (canvas.getActiveObject().type !=="image" && canvas.getActiveObject().type !=="activeSelection" && this.state.isCropping===false) &&
                   <span style ={{position : "relative"}}>
                    <div className="font-color-container colorpic" onClick={isObjectLocked? null : this.pickerOpen} title="Font/Fill Color 1" style={{cursor: isObjectLocked ? "no-drop" : '', background : isObjectLocked ? "#f2f2f2" : ''}}>
                        <div onClick={isObjectLocked? null : this.pickerOpen}>
                            <div ref={this.textcolorRef} style={{background : this.state.textColor}} className="primcol textcolpick" />
                            &nbsp;&nbsp;<img className="arrowimg  textcolpick" src={require('../images/down-arrow.png')} alt="" onClick={isObjectLocked? null : this.pickerOpen} />
                        </div>
                    </div>
                    <p style={{color : isObjectLocked ? "#ccc" : ''}}>{canvas.getActiveObject().type==="textbox" ? 'Font Color' : 'Fill Color'}</p>
                    {this.state.displayColorPicker
                        ? <div style={popover} >
                            <div style={cover} onClick={this.pickerClose} />
                            <SketchPicker color={this.state.textColor} onChange={this.setColor} />
                        </div>
                        : null
                    }
                    </span>
                }              
                { canvas.getActiveObject().type==="textbox" && $(window).width() > 1280 &&
                <>
                    <div title="Bold" onClick={isObjectLocked? null : this.setTextBold} className={`  ${
                    this.state.canvasObj?.fontWeight === 'bold' ? 'activetext bold colrsec' : 'bold colrsec'
                }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        <AiOutlineBold style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Bold</p>
                    </div>
                    <div title="Italic" onClick={isObjectLocked? null : this.setTextItalic} className={`  ${
                    this.state.canvasObj?.fontStyle === 'italic' ? 'activetext italic colrsec' : 'italic colrsec'
                }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        <AiOutlineItalic style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Italic</p>
                    </div>
                    <div title="Left" onClick={isObjectLocked? null : this.alignObjectLeft}  className={` ${
                    this.state?.canvasObj?.textAlign === 'left' ? 'activetext more-options' : 'more-options'
                    }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        <AiOutlineAlignLeft style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Left</p>
                    </div>
                    <div title="Center" onClick={isObjectLocked? null : this.alignObjectCenter} className={`  ${this.state?.canvasObj?.textAlign === 'center' ? 'activetext more-options' : 'more-options'}`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        <AiOutlineAlignCenter style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Center</p>
                    </div>
                    <div title="Right" onClick={isObjectLocked? null : this.alignObjectRight} className={` ${
                    this.state?.canvasObj?.textAlign === 'right' ? 'activetext more-options' : 'more-options'
                    }`} src={require('../images/align-right-50.png')} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        <AiOutlineAlignRight style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Right</p>
                    </div>
                </>
                }
                    {/* <div className="overlap-container">
                        <div title="Group" onClick={this.groupItems} className="sendforward group">
                            <i className="arrow fa fa-object-group" aria-hidden="true"></i>
                        </div>
                        <div title="Ungroup" onClick={this.unGroupItems} className="sendforward ungroup">
                            <i className="arrow fa fa-object-ungroup" aria-hidden="true"></i>
                        </div>
                    </div> */}

                    {/* <div className="overlap-container">
                        <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                            <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                        </div>
                        <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                            <i className="fas fa-ruler-vertical"></i>
                        </div>
                    </div> */}

                    {/* <div title="Effects" className="txteff">
                        <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Outline</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                        <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.statecollapse} className="strokesection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displaystrokeColorPicker
                                            ? <div style={strokepopover}>
                                                <div style={strokecover} onClick={this.strokepickerClose} />
                                                <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="5"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}</div>
                                        </div>
                                    </div>
                                </Collapse>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Glow</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                        <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.glowpickerOpen}>
                                                <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displayglowColorPicker
                                            ? <div style={glowpopover}>
                                                <div style={glowcover} onClick={this.glowpickerClose} />
                                                <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetX</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetX}
                                                value={this.state.offsetX}
                                            />
                                            <div>{this.state.offsetX}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetY</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetY}
                                                value={this.state.offsetY}
                                            />
                                            <div>{this.state.offsetY}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Blur</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowblur}
                                                value={this.state.blurval}
                                            />
                                            <div>{this.state.blurval}%</div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </Popup>
                    </div> */}

                    {/* <div title="Effects" className="elementeff">
                        <Popup className="elepopupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                            </div>
                        </Popup>
                    </div> */}

                    <div title="Effects" className="strokeeff">
                        <Popup className="popupcontent"
                            trigger={<div className="toolbar-label">Stroke</div>}
                            position="bottom center"
                            closeOnDocumentClick>
                            <span>
                                <div className="effects">
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                            {
                                                this.state.displaystrokeColorPicker
                                                    ? <div style={strokepopover} className="strokecolpic">
                                                        <div style={strokecover} onClick={this.strokepickerClose} />
                                                        <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider strokeslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}%</div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </Popup>
                    </div>
                </span>
                <span className="rightbar">
                    {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                    <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                    {/* <div title="Clone the Item" className="colrsec btn-duplicate" onClick={this.clone}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/clone.png')} alt="" /></div> */}
                    <div className='crop-outer px-1'>
                    {canvas.getActiveObject().type==="image" && selectionrect ? <div title="Crop the Item" className="toolbar-label btn-duplicate colrsec" style={{cursor: isObjectLocked ? "no-drop" : ''}} onClick={()=>{     
                        if(!isObjectLocked)
                        {
                            this.editCrop();
                            this.setState({isCropping:true})}
                        }  
                        }>
                        <FiCrop style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : '', margin:"2px"}}/>
                        <p className='crop-text' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Crop</p></div>:null}
                    {this.state.isCropping && !selectionrect?
                        <div className='crop-done' onClick={()=>{
                            if(!isObjectLocked)
                            {
                                this.cropDone()
                            }
                            }}>
                            <i class="fa fa-check" aria-hidden="true" style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}></i>
                            <span className='colrsec btn-duplicate' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Done</span>
                        </div>
                        :null}
                    {this.state.isCropping && !selectionrect?
                        <div className='crop-cancel' onClick={()=>{
                            if(!isObjectLocked)
                            {
                                this.cropCancel()
                            }
                            }}>
                            <i class="fa fa-ban" aria-hidden="true" style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}></i>
                            <span className='colrsec btn-duplicate' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Cancel</span>
                        </div>
                        :null}
                    </div>
                    {/* <div className='px-1'>
                    {canvas.getActiveObject().type==="image" ?
                    <div title="Mask the Image" className="colrsec btn-duplicate" onClick={()=>{this.props.setMasking();this.setState({selectedCanvasObject:this.props.state.canvas.getActiveObject()})}}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/circle-in-square.png')} alt="" /></div>
                    :null}
                    </div> */}
                    {(canvas.getActiveObject().type !=="textbox" && canvas.getActiveObject().type !=="activeSelection") && (
                    <>
                    <div className='send-outer'>
                    <div title="Send Back" onClick={isObjectLocked? null : this.sendBackward} className="send-back colrsec">
                        <RiSendBackward style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p className='tool-text' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Send Back</p>
                    </div>
                    <div title="Bring Forward" onClick={isObjectLocked? null : this.bringForward} className="send-forward colrsec ">
                        <RiBringForward style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p className='tool-text' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Bring Forward</p>
                    </div>
                    </div>
                    </>
                    )}
                    <span title="Delete" id="delete" className={`btn-delete`} onClick={isObjectLocked? null : this.deleteItem}>
                        <i className="far fa-trash-alt text arrow"  style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                        <p className='delete-btn-p' style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Delete</p>
                    </span>
                    {canvas.getActiveObject().type ==="textbox" && (
                    <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={isObjectLocked} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                        More
                    </Dropdown.Toggle>
                    {($(window).width()<=1280) ? 
                        <Dropdown.Menu>
                        <Dropdown.Item>
                        <div title="Bold" onClick={isObjectLocked? null : this.setTextBold} className={`  ${
                            this.state.canvasObj?.fontWeight === 'bold' ? 'activetext bold more-options colrsec' : 'bold more-options colrsec'
                            }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                                <AiOutlineBold style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                                <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Bold</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Italic" onClick={isObjectLocked? null : this.setTextItalic} className={`  ${
                            this.state.canvasObj?.fontStyle === 'italic' ? 'activetext italic more-options colrsec' : 'italic more-options colrsec'
                            }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                            <AiOutlineItalic style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                            <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Italic</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Left" onClick={isObjectLocked? null : this.alignObjectLeft}  className={` ${
                            this.state?.canvasObj?.textAlign === 'left' ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                                <AiOutlineAlignLeft style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                                <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Left</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Center" onClick={isObjectLocked? null : this.alignObjectCenter} className={`  ${this.state?.canvasObj?.textAlign === 'center' ? 'activetext more-options colrsec' : 'more-options colrsec'}`} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                            <AiOutlineAlignCenter style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                            <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Center</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Right" onClick={isObjectLocked? null : this.alignObjectRight} className={` ${
                            this.state?.canvasObj?.textAlign === 'right' ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`} src={require('../images/align-right-50.png')} style={{cursor: isObjectLocked ? "no-drop" : ''}}>
                                <AiOutlineAlignRight style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}/>
                                <p style={{cursor: isObjectLocked ? "no-drop" : '', color : isObjectLocked ? "#ccc" : ''}}>Align Right</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Underline" onClick={this.setTextUnderline}  className={`  ${
                        this.state.canvasObj?.underline === "underline" ? 'activetext more-options colrsec' : 'more-options colrsec'
                        }`}>
                            <AiOutlineUnderline/>
                            <p>Underline</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Strike out" onClick={this.setTextStrike}  className={`  ${
                        this.state.canvasObj?.linethrough === "linethrough" ? 'activetext more-options colrsec' : 'more-options colrsec'
                        }`}>
                            <AiOutlineStrikethrough/>
                            <p>Strike</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Ordered List" onClick={this.setNumberText}  className={`${
                        this.state.canvasObj?.listType === "number" ? 'activetext more-options colrsec' : 'more-options colrsec'
                        }`}>
                            <AiOutlineOrderedList/>
                            <p>Ordered List</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Bulleted List" onClick={this.setBulletText}  className={`${
                        this.state.canvasObj?.listType === "bullet" ? 'activetext more-options colrsec' : 'more-options colrsec'
                        }`}>
                            <MdOutlineFormatListBulleted/>
                            <p>Bulleted List</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Send Back" onClick={this.sendBackward} className="more-options colrsec">
                            <RiSendBackward/>
                            <p>Send Back</p>
                        </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                        <div title="Bring Forward" onClick={this.bringForward} className="more-options colrsec ">
                            <RiBringForward/>
                            <p>Bring Forward</p>
                        </div>
                        </Dropdown.Item>
                        </Dropdown.Menu>
                        :
                        <Dropdown.Menu>
                            <Dropdown.Item>
                            <div title="Underline" onClick={this.setTextUnderline}  className={`  ${
                            this.state.canvasObj?.underline === "underline" ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`}>
                                <AiOutlineUnderline/>
                                <p>Underline</p>
                            </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                            <div title="Strike out" onClick={this.setTextStrike}  className={`  ${
                            this.state.canvasObj?.linethrough === "linethrough" ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`}>
                                <AiOutlineStrikethrough/>
                                <p>Strike</p>
                            </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                            <div title="Ordered List" onClick={this.setNumberText}  className={`${
                            this.state.canvasObj?.listType === "number" ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`}>
                                <AiOutlineOrderedList/>
                                <p>Ordered List</p>
                            </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                            <div title="Bulleted List" onClick={this.setBulletText}  className={`${
                            this.state.canvasObj?.listType === "bullet" ? 'activetext more-options colrsec' : 'more-options colrsec'
                            }`}>
                                <MdOutlineFormatListBulleted/>
                                <p>Bulleted List</p>
                            </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                            <div title="Send Back" onClick={this.sendBackward} className="more-options colrsec">
                                <RiSendBackward/>
                                <p>Send Back</p>
                            </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                            <div title="Bring Forward" onClick={this.bringForward} className="more-options colrsec ">
                                <RiBringForward/>
                                <p>Bring Forward</p>
                            </div>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    }
                    </Dropdown>)}
                </span>
            </Container>:
            <Container className="toolbar-container" style={{minHeight:"77px",position:"inherit"}}>

                   <div className="row ">

                    <div className='col-12' style={{textAlign:"left"}}>
                       <span title="Font Family" className="" >
                       <select value={this.state.activeFontFamily} onChange={this.setTextFont} >
                            {this.state.fontFamilyNames.map((item)=>{
                            return <option style={{fontFamily:item}}>{item}</option>
                            })}
                        </select>
                       </span>

                       <span className="" title="Font Size">
                           &nbsp;&nbsp;<select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC', marginBottom:"3px" }}>
                               <option>6</option>
                               <option>8</option>
                               <option>10</option>
                               <option>12</option>
                               <option>14</option>
                               <option>16</option>
                               <option>18</option>
                               <option>21</option>
                               <option>24</option>
                               <option>28</option>
                               <option>32</option>
                               <option>36</option>
                               <option>42</option>
                               <option>48</option>
                               <option>56</option>
                               <option>64</option>
                               <option>72</option>
                               <option>80</option>
                               <option>88</option>
                               <option>96</option>
                               <option>104</option>
                               <option>120</option>
                               <option>144</option>
                           </select>

                       </span>
                       </div>

                       <div className='col-12 py-1' style={{textAlign:"left"}}>

                        <span className="px-1">
                        { canvas.getActiveObject().type==="textbox" &&
                            <>
                            <span title="Left" onClick={this.alignObjectLeft}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "left" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-left-50.png')} alt="" />
                            </span>
                            <span title="Center" onClick={this.alignObjectCenter}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "center" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-center-50.png')} alt="" />
                            </span>
                            <span title="Right" onClick={this.alignObjectRight}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "right" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-right-50.png')} alt="" />
                            </span>
                            </>
                        }
                        </span>

                       <span className="px-1">
                           <span title="Send Back" onClick={this.sendBackward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/send-backward.svg')} alt="" />
                           </span>
                           <span title="Bring Forward" onClick={this.bringForward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/bring-forward.svg')} alt="" />
                           </span>
                       </span>

                       {/* <span className="px-1">
                           <span title="Group" onClick={this.groupItems} className="px-1">
                               <i className="arrow fa fa-object-group" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                           <span title="Ungroup" onClick={this.unGroupItems} className="px-1">
                               <i className="arrow fa fa-object-ungroup" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                       </span> */}
                       <span className="px-2">
                       {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                       <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                       {/* <span title="Clone the Item" className="mr-1" onClick={this.clone}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/clone.png')} alt="" /></span> */}
                       <span title="Mask the Item" className="colrsec btn-duplicate" onClick={()=>this.props.setMaskingMobile()}> <img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/circle-in-square.png')} alt="" />
                    </span>

                   </span>
                       </div>

                       <div className='col-12 pt-2' style={{textAlign:"left"}}>
                       <span className="px-1">


                       {/* <span className="font-color-container colorpic px-1" onClick={this.pickerOpen} title="Font/Fill Color">
                           <span className="colrsec" onClick={this.pickerOpen}>
                               <div ref={this.textcolorRef} className="primcol textcolpick" />
                               &nbsp;&nbsp;<img className="arrowimg  textcolpick" style={{width:"20px",height:"20px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                           </span>
                           {this.state.displayColorPicker
                           ? <span style={popover} >
                               <div style={cover} onClick={this.pickerClose} />
                               <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                           </span>
                           : null
                       }
                       </span> */}




                           <span className="colrsec px-1" title="Rotate">
                               <img id="icon" src={require('../images/rotate.png')} height="20px" width="20px" onClick={this.rotate} style={{ cursor: "pointer" }} alt="" />
                           </span>
                           {!isObjectLocked ? <span className="colrsec px-1" title="Lock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/lock.jpg'} height="20px" width="20px" onClick={this.lock} style={{ cursor: "pointer" }} alt="" />
                           </span> : ''}
                           {isObjectLocked ? <span className="colrsec px-1" title="Unlock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/unlock.png'} height="20px" width="20px" onClick={this.unlock} style={{ cursor: "pointer" }} alt="" />
                           </span> : ''}
                           { canvas.getActiveObject().type==="textbox" &&
                           <>
                            <span className="px-1" onClick={this.pickerOpen} style={{width:"20px",height:"20px"}} title="Font/Fill Color">
                            <span className="" onClick={this.pickerOpen}>
                                {/* <span ref={this.textcolorRef} className="" style={{border:"0.5px solid black"}}/> */}
                                <img className="  " style={{width:"10px",height:"10px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                                {
                                this.state.displayColorPicker
                                    ?
                                    <span style={popover2} >
                                        <span style={cover} onClick={this.pickerClose} />
                                        <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                                    </span>
                                    : null
                                }
                            </span></span>
                           </>
                           }

                       </span>

                       <span className="px-1">
                           { canvas.getActiveObject().type==="textbox" &&
                           <>
                           <span title="Bold" onClick={this.setTextBold} className={`  ${
                        this.state.canvasObj?.fontWeight === 'bold' ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tbold" style={{height:"20px",width:"20px"}} src={require('../images/bold.png')} alt="" />
                           </span>
                           <span title="Italic" onClick={this.setTextItalic} className={`px-1  ${
                        this.state.canvasObj?.fontStyle === "italic" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon titalic" style={{height:"20px",width:"20px"}} src={require('../images/italic.png')} alt="" />
                           </span>
                           <span title="Underline" onClick={this.setTextUnderline} className={`px-1  ${
                        this.state.canvasObj?.underline === "underline" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tunder" style={{height:"20px",width:"20px"}} src={require('../images/underline.png')} alt="" />
                           </span>
                           <span title="Strike out" onClick={this.setTextStrike}  className={`px-1 ${
                        this.state.canvasObj?.linethrough === "linethrough" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tstrike" style={{height:"20px",width:"20px"}} src={require('../images/strikethrough.png')} alt="" />
                           </span>
                           <span title="Bulleted List" onClick={this.setBulletText}   className={`px-1 ${
                        this.state.canvasObj?.listType === "bullet" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tbullet" style={{height:"20px",width:"20px"}} src={require('../images/list-bullet.png')} alt="" />
                           </span>
                           <span title="Ordered List" onClick={this.setNumberText}  className={`px-1 ${
                        this.state.canvasObj?.listType === "number" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/orderlist.jpg')} alt="" />
                           </span></>
                            }

                           <span className='px-2'>

                            {canvas.getActiveObject().type==="image" && this.state.isCropping===false? <span title="Crop the Item" className="px-1" onClick={()=>{
                            this.editCrop();
                                this.setState({isCropping:true})}
                            }

                        ><img className="colrsec tNumber" style={{height:"20px",width:"20px"}} src={require('../images/crop.png')} alt="" /></span>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate px-1 text-menu' onClick={()=>this.cropDone()}>Done</span>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate px-4 text-menu' onClick={()=>this.cropCancel()}>Cancel</span>:null}
                    </span>
                    <span title="Delete" className="btn-delete" onClick={isObjectLocked? null : this.deleteItem}>
                            <>
                                <i className="far fa-trash-alt arrow" style={{color:isObjectLocked ? "grey" : "red"}}></i>
                            </>
                    </span>


                       </span>
                       </div>



                       {/* <div className="overlap-container">
                           <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                               <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                           </div>
                           <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                               <i className="fas fa-ruler-vertical"></i>
                           </div>
                       </div> */}

                       {/* <div title="Effects" className="txteff">
                           <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>

                                   <div className="effsection separator">
                                       <div className="toolbar-label">Outline</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                           <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.statecollapse} className="strokesection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displaystrokeColorPicker
                                               ? <div style={strokepopover}>
                                                   <div style={strokecover} onClick={this.strokepickerClose} />
                                                   <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="5"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}</div>
                                           </div>
                                       </div>
                                   </Collapse>

                                   <div className="effsection separator">
                                       <div className="toolbar-label">Glow</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                           <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.glowpickerOpen}>
                                                   <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displayglowColorPicker
                                               ? <div style={glowpopover}>
                                                   <div style={glowcover} onClick={this.glowpickerClose} />
                                                   <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetX</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetX}
                                                   value={this.state.offsetX}
                                               />
                                               <div>{this.state.offsetX}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetY</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetY}
                                                   value={this.state.offsetY}
                                               />
                                               <div>{this.state.offsetY}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Blur</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowblur}
                                                   value={this.state.blurval}
                                               />
                                               <div>{this.state.blurval}%</div>
                                           </div>
                                       </div>
                                   </Collapse>
                               </div>
                           </Popup>
                       </div> */}

                       {/* <div title="Effects" className="elementeff">
                           <Popup className="elepopupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>

                               </div>
                           </Popup>
                       </div> */}

                       <span title="Effects" className="strokeeff">
                           <Popup className="popupcontent"
                               trigger={<div className="toolbar-label">Stroke</div>}
                               position="bottom center"
                               closeOnDocumentClick>
                               <span>
                                   <div className="effects">
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                               {
                                                   this.state.displaystrokeColorPicker
                                                       ? <div style={strokepopover} className="strokecolpic">
                                                           <div style={strokecover} onClick={this.strokepickerClose} />
                                                           <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                       </div>
                                                       : null
                                               }
                                           </div>
                                       </div>
                                       <hr />
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider strokeslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}%</div>
                                           </div>
                                       </div>
                                   </div>
                               </span>
                           </Popup>
                       </span>
                   </div>

          </Container>
            }
    </div>
        );
    }
}

export default Toolbar;
