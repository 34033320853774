import React, { useCallback, useEffect } from 'react'
import $ from "jquery";
import { CANVAS_STATES, CANVAS_STATES2, addStateIndex, removeStateIndex, removeStateIndex2, addStateIndex2 } from "./CanvasHistory.js";
import { getCanvasBySide } from './Helpers.js';
import { BiUndo, BiRedo } from "react-icons/bi";

const UndoRedo = (props) => {
  const handleUndo = useCallback(() => {
    let state = addStateIndex()
    let state2 = addStateIndex2()
    let json = CANVAS_STATES[state];
    let json2 = CANVAS_STATES2[state2];
    let zoomLevel = CANVAS_STATES[state].zoom;
    let frontCanvas = getCanvasBySide(
      props?.allCanvasList,
      "front"
    );
    let backCanvas = getCanvasBySide(
      props?.allCanvasList,
      "back"
    );
    // console.log('state', state)
    // console.log('json', json)
    // console.log('CANVAS_STATES', CANVAS_STATES)
    // console.log('zoomLevel', zoomLevel)
    // console.log('frontCanvas', frontCanvas)
    if (json === undefined) {
      const imageObjects = frontCanvas._objects.filter((obj) => obj.type === ('image'));
      const textObjects = frontCanvas._objects.filter((obj) => obj.type === ('textbox'));
      const circleObjects = frontCanvas._objects.filter((obj) => obj.type === ('circle'));
      const rectangleObjects = frontCanvas._objects.filter((obj) => obj.type === ('rect'));
      const polyObjects = frontCanvas._objects.filter((obj) => obj.type === ('polygon'));
      if (imageObjects.length > 0) {
        imageObjects && imageObjects.map((obj) => {
          if (obj.isDefault === false) {
            frontCanvas.remove(obj)
          }
        })
      }
      if (textObjects.length > 0) {
        textObjects && textObjects.map((obj) => {
          if (obj.isDefault === false) {
            frontCanvas.remove(obj)
          }
        })
      }
      if (circleObjects.length > 0) {
        circleObjects && circleObjects.map((obj) => {
          if (obj.isDefault === false) {
            frontCanvas.remove(obj)
          }
        })
      }
      if (rectangleObjects.length > 0) {
        rectangleObjects && rectangleObjects.map((obj) => {
          if (obj.isDefault === false) {
            frontCanvas.remove(obj)
          }
        })
      }
      if (polyObjects.length > 0) {
        polyObjects && polyObjects.map((obj) => {
          if (obj.isDefault === false) {
            frontCanvas.remove(obj)
          }
        })
      }
      frontCanvas.discardActiveObject()
    }
    frontCanvas.loadFromJSON(json, () => {
      if (frontCanvas._objects !== null && frontCanvas._objects !== undefined && frontCanvas?._objects.length > 0) {
        const imageObjects = frontCanvas._objects.filter((obj) => obj.type === ('image'));
        const textObjects = frontCanvas._objects.filter((obj) => obj.type === ('textbox'));
        const circleObjects = frontCanvas._objects.filter((obj) => obj.type === ('circle'));
        const rectangleObjects = frontCanvas._objects.filter((obj) => obj.type === ('rect'));
        const polyObjects = frontCanvas._objects.filter((obj) => obj.type === ('polygon'));
        if (imageObjects.length > 0) {
          imageObjects && imageObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (textObjects.length > 0) {
          textObjects && textObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (circleObjects.length > 0) {
          circleObjects && circleObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (rectangleObjects.length > 0) {
          rectangleObjects && rectangleObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (polyObjects.length > 0) {
          polyObjects && polyObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
      }
      frontCanvas.setWidth(json.width);   // Update width
      frontCanvas.setHeight(json.height); // Update height
      frontCanvas.setZoom(zoomLevel);
      props.getZoom(zoomLevel,json.width,json.height)
      props.setbgImage(frontCanvas)
      props.triggerState()
      frontCanvas.renderAll();           // Re-render the canvas
    });
    backCanvas.loadFromJSON(json2, () => {
      if (backCanvas._objects !== null && backCanvas._objects !== undefined && backCanvas?._objects.length > 0) {
        frontCanvas.discardActiveObject()
        backCanvas.setActiveObject(backCanvas?._objects[backCanvas?._objects.length - 1])
      }
    });
    frontCanvas.requestRenderAll();
    backCanvas.requestRenderAll();
  }, [props.canvas]);

  const handleRedo = useCallback(() => {
    let state1 = removeStateIndex();
    let state2 = removeStateIndex2();
    let json = CANVAS_STATES[state1];
    let json2 = CANVAS_STATES2[state2];
    let zoomLevel = CANVAS_STATES[state1].zoom;
    let frontCanvas = getCanvasBySide(
      props?.allCanvasList,
      "front"
    );
    let backCanvas = getCanvasBySide(
      props?.allCanvasList,
      "back"
    );
    // console.log('state---', state1)
    // console.log('json---', json)
    // console.log('CANVAS_STATES---', CANVAS_STATES)
    // console.log('zoomLevel---', zoomLevel)
    // console.log('frontCanvas---', frontCanvas)
    frontCanvas.loadFromJSON(json, () => {
      if (frontCanvas._objects !== null && frontCanvas._objects !== undefined && frontCanvas?._objects.length > 0) {
        const imageObjects = frontCanvas._objects.filter((obj) => obj.type === ('image'));
        const textObjects = frontCanvas._objects.filter((obj) => obj.type === ('textbox'));
        const circleObjects = frontCanvas._objects.filter((obj) => obj.type === ('circle'));
        const rectangleObjects = frontCanvas._objects.filter((obj) => obj.type === ('rect'));
        const polyObjects = frontCanvas._objects.filter((obj) => obj.type === ('polygon'));
        if (imageObjects.length > 0) {
          imageObjects && imageObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (textObjects.length > 0) {
          textObjects && textObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (circleObjects.length > 0) {
          circleObjects && circleObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (rectangleObjects.length > 0) {
          rectangleObjects && rectangleObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
        if (polyObjects.length > 0) {
          polyObjects && polyObjects.map((obj) => {
            if (obj.isDefault != false) {
              frontCanvas.discardActiveObject()
            } else {
              //frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length - 1])
              const activeObjectInfo = json.activeObject;
              if (activeObjectInfo) {
                const objectId = activeObjectInfo.id;
                const activeObject = frontCanvas.getObjects().find(obj => obj.id === objectId);
                if (activeObject) {
                  frontCanvas.setActiveObject(activeObject);
                }
              }
            }
          })
        }
      }
      frontCanvas.setWidth(json.width);   // Update width
      frontCanvas.setHeight(json.height); // Update height
      frontCanvas.setZoom(zoomLevel);
      props.getZoom(zoomLevel,json.width,json.height)
      props.setbgImage(frontCanvas)
      props.triggerState()
      frontCanvas.renderAll();           // Re-render the canvas
    });
    backCanvas.loadFromJSON(json2, () => {
      if (backCanvas._objects !== null && backCanvas._objects !== undefined && backCanvas?._objects.length > 0) {
        frontCanvas.discardActiveObject()
        backCanvas.setActiveObject(backCanvas?._objects[backCanvas?._objects.length - 1])
      }
    });
    frontCanvas.requestRenderAll();
    backCanvas.requestRenderAll()
    props.canvas.requestRenderAll()
  }, [props.canvas])
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault(); // Prevent the default behavior (e.g., browser undo)
        handleUndo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
        event.preventDefault(); // Prevent the default behavior (e.g., browser redo)
        handleRedo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);
  return (
    <>
      {$(window).width() >= 900 ? <span><button id="undo" className="btn btn-outline-secondary btn-undo" onClick={handleUndo} title="Undo">
        <BiUndo />
      </button>
        <button id="redo" style={{marginRight: "5px"}} className="btn btn-outline-secondary btn-redo" onClick={handleRedo} title="Redo">
          <BiRedo />
        </button>
      </span> : <span>
        <button style={{
          height: "25px", width: "25px", padding: "inherit"
        }} id="undo" className="btn btn-outline-secondary btn-undo" onClick={handleUndo} title="Undo">
          <BiUndo />
        </button>
        <button style={{ height: "25px", width: "25px", padding: "inherit"}} id="redo" className="btn btn-outline-secondary btn-redo " onClick={handleRedo} title="Redo">
          <BiRedo />
        </button></span>}
    </>
  )
}
export default UndoRedo