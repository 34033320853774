import React, { Component } from "react";
import { fabric } from "fabric";
import { SketchPicker } from "react-color";
import { Row, Col, Container, Form, Input } from "reactstrap";
import {
    Nav,
    NavItem,
    NavLink,
    Card,
    Button as Bbutton,
    CardTitle,
    CardText,
} from "reactstrap";
import {
    addToHistory,
    addToHistory2,
    removeStates,
    removeStates2,
} from "../components/CanvasHistory";
import { getCanvasBySide } from "./Helpers.js";

import MASK_IMAGES from "./mask_images";

import { TabPanel } from "react-web-tabs";
import { client } from "filestack-react";
import { TabContent, TabPane } from "reactstrap";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
//import Popup from 'reactjs-popup';
import {
    DEFAULT_FONT_PROPS,
    templates,
    DPI_THRESHOLD,
    IMAGE_MAX_WIDTH,
    productTypes,
    VERSES,
    HOST_SITE,
    SAMPLE1_FONT_PROPS1,
    SAMPLE1_FONT_PROPS2,
    SAMPLE2_FONT_PROPS1,
    SAMPLE2_FONT_PROPS2,
    SAMPLE2_FONT_PROPS3,
    SAMPLE3_FONT_PROPS1,
    SAMPLE3_FONT_PROPS2,
    SAMPLE3_FONT_PROPS3,
    SAMPLE4_FONT_PROPS1,
    SAMPLE4_FONT_PROPS2,
    SAMPLE5_FONT_PROPS1,
    SAMPLE5_FONT_PROPS2,
    SAMPLE6_FONT_PROPS1,
    SAMPLE6_FONT_PROPS2,
    SAMPLE6_FONT_PROPS3
} from "../constants";
import { getDpi } from "./Helpers";

import {
    unique,
    saveCanvasState,
    selectObject,
    //getCanvasBySide,
    deleteCanvasBg,
    setcanvasBG,
} from "./Helpers";
import trashimg from "../images/trash.png";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Textlayer from "./Textlayer";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import diamond from "../images/elements/diamond.svg";
//import mg from '../images/animals_mascots/angels/BAS_Angels_  A w wings & halo black R1-1.gif'
//import classnames from 'classnames';
import "./modal.css";
import "./paginate.css";
//import ReactPaginate from 'react-paginate';
import config from "../../config";
import axios from "axios";
import $ from "jquery";
import jsPDF from "jspdf";
//import html2canvas from "html2canvas";
import ListGroup from "reactstrap/lib/ListGroup";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { LuImageOff } from "react-icons/lu";

const INIT_SOLID_COLORS = [
    "#d0021b",
    "#f5a623",
    "#f8e71c",
    "#8b572a",
    "#b8e986",
    "#417505",
    "#4a90e2",
    "#50e3ca",
    "#000000",
    "#fff",
];
// const INIT_PATTERN_IMAGES = [
//     require('../images/img/pattern1.jpg'),
//     require('../images/img/pattern2.jpg'),
//     require('../images/img/pattern3.jpg'),
//     require('../images/img/pattern4.jpg'),
//     require('../images/img/pattern5.jpg'),
//     require('../images/img/pattern6.jpg'),
//     require('../images/img/pattern7.jpg'),
//     require('../images/img/pattern8.jpg'),
//     require('../images/img/pattern9.jpg')
// ]
/*const INIT_ELEMENT_SHAPES = [
    require('../images/elements/circle.svg'),
    require('../images/elements/star.svg'),
    require('../images/elements/square.svg'),
    require('../images/elements/diamond.svg'),
    require('../images/elements/hexagon.svg'),

]

const INIT_ELEMENT_CLIPARTS = [
    require('../images/animals_mascots/angels/BAS_Angels_  A w wings & halo black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_  A w wings & halo R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A against black circle R -1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings001 R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings002 R1-1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings002 R1-2.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings003 R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ Angels script black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ Angels script w wings & halo R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ block letters black w geometric wings clear R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ block letters clear w geometric wings black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_angel icon praying R-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_angelfish R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_cross w black wings & halo R1-1.gif'),
]*/
const optionStyles = (styles) => {
    return {
      ...styles,
      cursor: 'pointer'
    };
  };
class LeftPanel extends Component {
    state = {
        displaybgColorPicker: false,
        displaygrad1ColorPicker: false,
        displaygrad2ColorPicker: false,
        canvasScale: 1,
        SCALE_FACTOR: 1.2,
        bgcolArray: [],
        backgroundcolor: "",
        grad1color: "black",
        grad2color: "black",
        apiImg: [],
        clipartData: [],
        clipartImages: [],
        subcat: "",
        page: 1,
        searchkey: "sport",
        activeTab: "1",
        imgactiveTab: "1",
        unsplashImg: [],
        unsplashsearchkey: "woods",
        pagenum: 1,
        isOpen: false,
        isDPIOpen: false,
        isLargeImage: false,
        uploadImageWidth: null,
        imageDPI: null,
        data: [],
        offset: 0,
        perPage: 2,
        currentPage: 0,
        templatesValues: null,
        showPopover: [],
        frontValues: [],
        colorCat : 'standard',
        selectedImages : [],
        clipartCategories : [],
        selectedCategory: 1,
        selectedImage: '',
        searchQuery: "",
        newSortedArray : [],
        imageId : []
    };

    constructor(props) {
        super(props);

        // this.iScrollRef = React.createRef();
        this.imgScrollRef = React.createRef();
        this.popoverRef = React.createRef();
        this.shapeUpload = this.shapeUpload.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);

        //this.addBulletText = this.addBulletText.bind(this);
    }
    handleCategorySelect = (event) => {
        this.setState({
          selectedCategory: event.value,
        });
      };
    
      handleImageSelect = (imageUrl) => {
        this.setState({
          selectedImage: imageUrl,
        });
      };
      handleSearchQueryChange = (event) => {
        this.setState({ searchQuery: event.target.value });
      };
    handleOnMouseEnter = (index) => {
        let arrStr = [];
        arrStr[index] = true;
        this.setState({
            showPopover: arrStr,
        });
    };
    handleOnMouseLeave = (index) => {
        let arrStr = [];
        arrStr[index] = false;
        this.setState({
            showPopover: arrStr,
        });
    };
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                //this.getClipart("1", "1");
            }
        );
    };
    getClipart = (type1, type2) => {
        this.setState({ subcat: type2 });
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        //const {clipartImages } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/clipart?cat=${type1}&subcat=${type2}`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response) => {
                //     const data = res.data.data;
                //      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)

                // console.log(slice);
                //     const postData = slice.map(pd => <React.Fragment>
                //         <p>{pd.title}</p>
                //         <img src={pd.thumbnailUrl} alt=""/>
                //     </React.Fragment>)

                //     this.setState({
                //         pageCount: Math.ceil(data.length / this.state.perPage),

                //         postData
                //     })
                if (response.data.data) {
                    const data = [];
                    let clipImages = response.data.data;

                    for (var key in clipImages) {
                        data.push(clipImages[key]);
                    }
                    this.setState({
                        clipartImages: data,
                    });
                }
            });
    };
    openModal = () =>
        this.setState({
            isOpen: true,
        });
    closeModal = () => {
        this.setState({
            isOpen: false,
        });
    };
    openDPIModal = () =>
        this.setState({
            isDPIOpen: true,
            isLargeImage: true,
        });
    closeDPIModal = () => {
        this.setState({
            isDPIOpen: false,
            isLargeImage: false,
        });
    };

    componentDidMount() {
        //this.clipartGallery();
        //this.getClipart("1", "1");
        document.addEventListener('dragover', this.handleDragOver);
        document.addEventListener('drop', this.handleDrop);
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        let apiDynamicUrlAddress = config.default.api1.host;
        let apiUrl = `${apiDynamicUrlAddress}/clipart`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response)=>{
                if(response.data.code === 200)
                {
                    const options = response.data.data.map(category => ({
                        value: category.id,
                        label: category.name,
                        images : category.images,
                        subcategories : category.subcategories
                    }))
                    this.setState({
                        clipartCategories: options
                    });
                }else{
                    this.setState({
                        clipartCategories: []
                    });
                }
            })
            .catch((error)=> {
                toast.error("Clipart API Failed, Try again later", { position: toast.POSITION.TOP_RIGHT });
            })
        
        let bgcolArray = localStorage.getItem("bgcolors");
        if (bgcolArray) {
            bgcolArray = JSON.parse(bgcolArray);
            this.setState({
                bgcolArray: bgcolArray,
            });
        }
        this.imgScrollRef.current.addEventListener("scroll", () => {
            if (
                this.imgScrollRef.scrollTop + this.imgScrollRef.clientHeight >=
                this.imgScrollRef.scrollHeight
            ) {
                this.incermentpage();
            }
        });

        // // REMOVE ME:
        // setTimeout(() => {
        //     this.setBGcolor("#ffffff");
        // }, 100);
        const productNames = Object.keys(templates);

        if (productNames.includes(this.props.templateKey)) {
            // setcanvasBG(
            //     templates[this.props.templateKey],
            //     this.props.allCanvasList
            // );
            // console.log("templates>>>>>", templates[this.props.templateKey]);
            ;
        }
        const templateArray = Object.values(templates);
        const templateArray2D = templateArray.reduce((acc, curr, index) => {
            if (index && (index + 1) % 2 === 0) {
                acc.push([templateArray[index - 1], templateArray[index]]);
            }
            return acc;
        }, []);
        this.setState({
            templatesValues: templateArray2D,
        });

        if (this.props.canvas) {
            this.props.canvas.off('object:added', this.functionTextValues)
            this.props.canvas.on('object:added', this.functionTextValues)
        }
    }
    componentWillUnmount() {
        document.removeEventListener('dragover', this.handleDragOver);
        document.removeEventListener('drop', this.handleDrop);
      }
    componentDidUpdate() {
        /** Image Gallery masonary */
        const Masonry = require("masonry-layout");
        const imgloaded = require("imagesloaded");
        const container = document.querySelector("#uploadedImgDiv");
        const gridElement = document.querySelector(".grid");
        var imgLoad = imgloaded(container);
        imgLoad.on("always", onImageLoad);
        function onImageLoad() {
            setTimeout(() => {
                var msnry = new Masonry(gridElement, {
                    itemSelector: ".grid-item",
                    gutter: 5,
                    fitWidth: true,
                    columnWidth: 3,
                });
            }, 2000);
        }

        if (this.props.canvas) {
            this.props.canvas.off('object:added', this.functionTextValues)
            this.props.canvas.on('object:added', this.functionTextValues)
            this.props.canvas.off('object:modified', this.functionTextValues)

            this.props.canvas.on('object:modified', this.functionTextValues)
        }
    }

    functionTextValues = (e) => {
        this.updateTextValues(e)
    }

    updateTextValues = () => {
        if (this.props.canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let valueFront = [];
            this.setState({ frontValues: [] })

            frontCanvas.getObjects().map((item) => {
                if (item.type == "textbox") {
                    // this.setState({frontArray:[...this.state.frontArray,item]});
                    valueFront.push(item)
                }

            })

            this.setState({ frontValues: valueFront })
            //  this.setState({frontArray:[]})

        }
    }

    clipartGallery = () => {
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };

        //const {clipartgallery } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/clipartgallery`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        clipartData: response.data.data,
                    });
                }
            })
            .catch((err) => { });
    };

    /* addBulletText = () => {
        var canvas = this.props.canvas;
        const fontSize = 10;
        const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Integer nec odio.
    Praesent libero. Sed cursus ante dapibus diam.`;
        const t0 = new fabric.Textbox(text, {
            name: "list",
            fontFamily: "sans-serif",
            fontSize: fontSize,
            lineHeight: 1.40,
            left: 75,
            top: 50,
            width: 350,
            objectCaching: false,
            isWrapping: false,
            listType: "bullet",
            listBullet: "✱",
            listCounter: 0
        });
        console.log('text object t0 ', t0)
        const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
            const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
                bullet = this.listType === "bullet"
                        ? [this.listBullet]
                        : [this.listCounter + 1 + "."],
                bulletLeft = left - style0.fontSize - 2;
            console.log('bullet ', style0, bullet )
            console.log('bulletLeft ', bulletLeft , this.isWrapping)
            if (line.length) {
                if (!this.isWrapping) {
                    // render the bullet
                    this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                    this.isWrapping = !this.isEndOfWrapping(lineIndex);
                    if (!this.isWrapping) this.listCounter++;
                } else if (this.isEndOfWrapping(lineIndex)) {
                    this.isWrapping = false;
                    this.listCounter++;
                }
            }
            if (lineIndex === this.textLines.length - 1) {
                this.isWrapping = false;
                this.listCounter = 0;
            }
            // render the text line
            console.log('line ', line)
            this._renderChars(method, ctx, line, left, top, lineIndex);
        }
        t0._renderTextLine = renderTextLine;
        canvas.add(t0);
        canvas.renderAll();
    } */
    //Common method to addtext
    addText = (type, text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;

        let fontSize = DEFAULT_FONT_PROPS.text_size;
        if (type === "heading") {
            fontSize = DEFAULT_FONT_PROPS.heading_size;
        } else if (type === "title") {
            fontSize = DEFAULT_FONT_PROPS.title_size;
        }
        let top = 50;
        let left = 50;

        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: DEFAULT_FONT_PROPS.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: fontSize,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox(text, textProperties);
        textObj.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;

            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;

            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        }
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
        });
        // Create a rotation display for each text object
        // const rotationDisplay = new fabric.Textbox(`${getAngleText(textObj.angle)}`, {
        //     top: textObj.top,
        //     left: textObj.left,
        //     selectable: false,
        //     evented: false,
        //     visible: false,
        //     fontSize: 20,
        // });
        // textObj.rotationDisplay = rotationDisplay;
        // canvas.add(rotationDisplay);
        // canvas.add(textObj);
        // canvas.on('object:rotating', (event) => {
        //     const rotatedObject = event.target;
    
        //     // Assuming that the associated rotation display is stored as a property of the text object
        //     const rotationDisplay = rotatedObject.rotationDisplay;
        //     const offset = calculateOffset(rotatedObject);
        //     rotatedObject.set({
        //         angle: rotatedObject.angle,
        //     });
    
        //     rotationDisplay.set({
        //         text: `${getAngleText(textObj.angle)}`,
        //         top: rotatedObject.top + offset.y,
        //         left: rotatedObject.left + offset.x,
        //         visible: true
        //     });
    
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // });
        // canvas.on('object:moving', (event) => {
        //     const movedObject = event.target;
        //     // Assuming that the associated rotation display is stored as a property of the text object
        //     const rotationDisplay = movedObject.rotationDisplay;
        //     const offset = calculateOffset(movedObject);
        //     rotationDisplay.set({
        //         top: movedObject.top + offset.y,
        //         left: movedObject.left + offset.x,
        //         visible: false // Hide the rotation display during moving
        //     });
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // });
        // canvas.on('object:scaling', (event) => {
        //     const scaledObject = event.target;
        //     // Assuming that the associated rotation display is stored as a property of the text object
        //     const rotationDisplay = scaledObject.rotationDisplay;
        //     const offset = calculateOffset(scaledObject);
        //     rotationDisplay.set({
        //         top: scaledObject.top + offset.y,
        //         left: scaledObject.left + offset.x,
        //         visible: false // Hide the rotation display during moving
        //     });
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // })
        // canvas.on('object:selected', (event) => {
        //     const selectedObject = event.target;
    
        //     // Assuming that the associated rotation display is stored as a property of the text object
        //     const rotationDisplay = selectedObject.rotationDisplay;
    
        //     rotationDisplay.set({
        //         visible: true // Show the rotation display when the object is selected
        //     });
    
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // });
        // canvas.on('before:selection:cleared', (event) => {
        //     // Hide the rotation display before the selection is cleared
        //     rotationDisplay.set({
        //         visible: false
        //     });
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // });
        // canvas.on('mouse:out', (event) => {
        //     // Hide the rotation display when the mouse leaves the canvas
        //     const rotationDisplay = textObj.rotationDisplay;
        //     rotationDisplay.set({
        //         visible: false
        //     });
        //     canvas.requestRenderAll(); // Ensure canvas is re-rendered
        // });
        // selectObject(canvas);
        // function getAngleText(angle) {
        //     // Handle the case when the angle is exactly 0
        //     return (angle === 0 || angle === 360) ? '0°' : `${Math.round(angle)}°`;
        // }
        // function calculateOffset(obj) {
        //     // Calculate the offset based on the bounding box dimensions
        //     const bbox = obj.getBoundingRect();
        //     const scale = obj.scaleX; // Consider the scaling factor
        //     // Adjust this offset as needed
        //     const offset = { x: 0, y: -70 * scale };

        //     return fabric.util.rotatePoint(new fabric.Point(offset.x, offset.y), {
        //         x: 0,
        //         y: 0
        //     }, fabric.util.degreesToRadians(obj.angle));
        // }
        canvas.add(textObj);
        // selectObject(canvas);
        canvas.renderAll();
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
        //this.addBulletText();
    };
    addSampleText1 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE1_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE1_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE1_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE1_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("Life is an", textProperties);
        var textObj1 = new fabric.Textbox("Adventure", textProperties1);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top: textObj.top + 20
        });
        canvas.add(textObj,textObj1);
        var selectedObjects = [textObj, textObj1];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    addSampleText2 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE2_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE2_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#81DAB8",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE2_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE2_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#81DAB8",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties2 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE2_FONT_PROPS3.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE2_FONT_PROPS3.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#81DAB8",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("Save", textProperties);
        var textObj1 = new fabric.Textbox("the", textProperties1);
        var textObj2 = new fabric.Textbox("Date", textProperties2);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        textObj2.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
            textObj2.top = canvasVerticalPosition;
            textObj2.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
            textObj2.top = canvasVerticalPosition;
            textObj2.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj.top + 20
        });
        textObj2.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj1.top + 20
        });
        canvas.add(textObj,textObj1,textObj2);
        var selectedObjects = [textObj, textObj1,textObj2];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    addSampleText3 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE3_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE3_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE3_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE3_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties2 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE3_FONT_PROPS3.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE3_FONT_PROPS3.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("MARKETING SHARE", textProperties);
        var textObj1 = new fabric.Textbox("30.65%", textProperties1);
        var textObj2 = new fabric.Textbox("We project our market share to increase from 32.65% to 30.65 % in 2022", textProperties2);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        textObj2.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
            textObj2.top = canvasVerticalPosition;
            textObj2.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
            textObj2.top = canvasVerticalPosition;
            textObj2.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj.top + 30
        });
        textObj2.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj1.top + 30
        });
        canvas.add(textObj,textObj1,textObj2);
        var selectedObjects = [textObj, textObj1,textObj2];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    addSampleText4 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE4_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE4_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#50E3C2",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE4_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE4_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#4248BE",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("Just Keep", textProperties);
        var textObj1 = new fabric.Textbox("TRYING", textProperties1);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top :textObj.top + 30
        });
        canvas.add(textObj,textObj1);
        var selectedObjects = [textObj, textObj1];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    addSampleText5 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE5_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE5_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#2C78CC",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE5_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE5_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#BB2B2B",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("BUY", textProperties);
        var textObj1 = new fabric.Textbox("NOW", textProperties1);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
            textObj1.top = canvasVerticalPosition;
            textObj1.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj.top + 25
        });
        canvas.add(textObj,textObj1);
        var selectedObjects = [textObj, textObj1];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    addSampleText6 = (type,text, isVerse) => {
        isVerse = !!isVerse;
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    
        let top = 50;
        let left = 50;
    
        const textProperties = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE6_FONT_PROPS1.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE6_FONT_PROPS1.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "red",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties1 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE6_FONT_PROPS2.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE6_FONT_PROPS2.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "red",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        const textProperties2 = {
            type : 'textbox',
            id: Math.floor(Math.random() * 1000),
            breakWords: true,
            fontFamily: SAMPLE6_FONT_PROPS3.font,
            left: fabric.Canvas.originalWidth * 0.05,
            top: top,
            fontSize: SAMPLE6_FONT_PROPS3.title_size,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "red",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle: "",
            lineHeight: 1,
            width: 120,
            textAlign: "center",
            selectionEnd: "",
            styles: {},
            isDefault : false,
            charSpacing : 1
        };
        var HideControls = {
            'mb': false,
            'mt': false,
          };
        var textObj = new fabric.Textbox("Never", textProperties);
        var textObj1 = new fabric.Textbox("Lose", textProperties1);
        var textObj2 = new fabric.Textbox("Hope", textProperties2);
        textObj.setControlsVisibility(HideControls);
        textObj1.setControlsVisibility(HideControls);
        textObj2.setControlsVisibility(HideControls);
        //text._renderTextLine = this.renderTextLine;
        //textObj.setCoords()
        canvas.setActiveObject(textObj);
        canvas.calcOffset();
        // canvas.centerObject(textObj);
    
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
    
        if (type === "heading") {
            //set -25% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        } else if (type === "description") {
            //add +15% from vertically center position
            canvasVerticalPosition =
                canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        } else if (type === "title") {
            canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
            canvasHorizontalPosition =
                canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
    
            textObj.top = canvasVerticalPosition;
            textObj.left = canvasHorizontalPosition;
        }
    
        textObj.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : fabric.Canvas.originalHeight / 2,
        });
        textObj1.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj.top + 30
        });
        textObj2.set({
            originX: "center",
            originY: "center",
            left: fabric.Canvas.originalWidth / 2,
            top : textObj1.top + 30
        });
        canvas.add(textObj, textObj1, textObj2);
        var selectedObjects = [textObj, textObj1,textObj2];
        this.displayCommonControls(selectedObjects);
        // selectObject(canvas);
    
        canvas.renderAll();
    
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    }
    displayCommonControls(selectedObjects, fontSize) {
        var canvas = this.props.canvas;
        // Check if more than one object is selected
        if (selectedObjects.length > 1) {
          var box = new fabric.ActiveSelection(selectedObjects, {
            canvas: canvas
          });
          box.set({
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            width: 120,
            textAlign: "center",
        });
          box.setControlsVisibility({
            'mb': false,
            'mt': false
          });
          canvas.setActiveObject(box);
          canvas.requestRenderAll();
        }
    }
    addVerse = (title, textStr) => {
        var canvas = this.props.canvas;
        if (canvas) {
            const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;

            //this.addText('title', title, true)
            let fontSize = DEFAULT_FONT_PROPS.text_size;
            textStr = `${title}\n\n${textStr}`;

            // const textProperties = {
            //     id: Math.floor(Math.random() * 1000),
            //     fontFamily: DEFAULT_FONT_PROPS.font,
            //     left: 100,
            //     top: 100,
            //     type: "text",
            //     fontSize,
            //     strokeWidth: 1,
            //     strokeLineJoin: "bevil",
            //     fill: "#000000",
            //     subTargetCheck: true,
            //     transparentCorners: false,
            //     cornerColor: "#1babeb",
            //     cornerStrokeColor: "#1babeb",
            //     borderColor: "#1babeb",
            //     cornerSize: 5,
            //     padding: 5,
            //     cornerStyle: "circle",
            //     listStyle:"",
            //     lineHeight:1,
            //     textAlign:'center'
            // };
            const query = new URLSearchParams(window.location.search);

            let type = query.get("type");

            const textProperties = {
                id: Math.floor(Math.random() * 1000),
                fontFamily: DEFAULT_FONT_PROPS.font,
                left: 100,
                top: 100,
                fontSize: type === "3up" ? 3 : 6,
                fill: "#000000",
                textAlign: "center",
                width: fabric.Canvas.originalWidth,
            };

            var text = new fabric.Textbox(textStr, textProperties);
            var canvasVerticalPosition = canvasVerticalCenter;
            // var scaleHeight=fabric.Canvas.originalHeight-(fabric.Canvas.originalHeight*0.3)
            // var scaleWidth=fabric.Canvas.originalWidth-(fabric.Canvas.originalWidth*0.3)
            // var scale;
            // if(scaleHeight<text.height){
            //     scale=scaleHeight/text.height
            // }

            // else if(scaleWidth<text.width){
            //     scale=scaleWidth/text.width
            // }
            text.set({
                originX: "center",
                originY: "center",
                left: fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                // scaleX:scale,
                // scaleY:scale,
            });

            if (canvas) {
                text.setCoords();
                canvas.add(text);
                canvas.setActiveObject(text);
                // canvas.centerObject(text);
                selectObject(canvas);
                canvas.requestRenderAll();

                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );

                let backCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );

                removeStates();
                removeStates2();
                addToHistory(frontCanvas);
                addToHistory2(backCanvas);
            }
        }
    };

    /* addHeadingtxt = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Heading', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 24,
            //width: 100,
            //height: 100,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 5,
            padding: 5,
            cornerStyle: 'circle'
        });

        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */
    // clearText=(e)=> {
    //     var canvas = this.props.canvas;
    //     if (e.target.type === "i-text") {
    //       if (e.target.text === "Add Heading") {
    //         e.target.text = "";
    //         e.target.hiddenTextarea.value = '';  // NEW
    //         canvas.renderAll();
    //       };
    //     }
    //   }

    /* addSubheadingtxt = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Title', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 12,
            width: 100,
            height: 100,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 8,
            padding: 5,
            cornerStyle: 'circle'
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */

    /* addText = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Description', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 18,
            width: 200,
            height: 200,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 8,
            padding: 5,
            cornerStyle: 'circle'
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */

    setCanvasFill = (bgcolor) => {
        console.log('aaa')
        var canvas = this.props.canvas;
        // var canvasObj = this.props.canvasObj;
        // if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        // canvas=canvasObj[0]
        // else
        // canvas=canvasObj[1]
        const rgbaColor = `rgba(${bgcolor.rgb.r}, ${bgcolor.rgb.g}, ${bgcolor.rgb.b}, ${bgcolor.rgb.a})`;
        deleteCanvasBg(this.props.canvas);
        canvas.backgroundColor = rgbaColor;
        canvas.renderAll();
        this.props.triggerState()
        //saveCanvasState(canvas);
        
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let backCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );

            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    };

    dynamicBGcolors = (bgcol) => {
        var bgcolArray = this.state.bgcolArray;
        bgcolArray.push(bgcol);
        bgcolArray = unique(bgcolArray);
        // console.log(bgcolArray);
        this.setState({
            bgcolArray: bgcolArray,
        });
        this.setState({
            backgroundcolor: bgcol,
        });
        localStorage.setItem("bgcolors", JSON.stringify(bgcolArray));
    };

    // showUploadPopup = () => {
    //     const options = {
    //         accept: 'image/*',
    //         //fromSources: ['local_file_system'],
    //         maxSize: 1024 * 1024,
    //         maxFiles: 1,
    //         onFileUploadFinished: this.setcanvasBG
    //     }
    //     const filestack = client.init('A002ziWzKRKms2ktqgtQmz', options);
    //     const picker = filestack.picker(options);
    //     picker.open();

    // }
    getImageTypeFromDataURL = (url) => {
        // Split the URL by "/"
        const urlParts = url.split('/');

        // Get the last part of the URL, which is the filename
        const filename = urlParts[urlParts.length - 1];

        // Split the filename by "." to get the file extension
        const filenameParts = filename.split('.');

        // Get the last part of the filenameParts, which is the file extension
        const fileExtension = filenameParts[filenameParts.length - 1];

        // Convert the file extension to lowercase (optional)
        const lowercaseFileExtension = fileExtension.toLowerCase();

        return lowercaseFileExtension
      }
    addImageOnCanvas = async (src,id, val, imgName) => {
        var imageType = this.getImageTypeFromDataURL(src);
        var canvas = this.props.canvas;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2; 
        const self = this;
        let isImageLoaded = false;
        if (imageType === "svg") {
            fabric.loadSVGFromURL(src, function(objects, options) {
                var svgObj = fabric.util.groupSVGElements(objects, options);
                svgObj.src = src
                svgObj.crossOrigin = 'anonymous'
                svgObj.crossDomain = true;
                let canvasVerticalPosition;
                let canvasHorizontalPosition;
                canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
                canvasHorizontalPosition =
                    canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
                svgObj.top = canvasVerticalPosition;

                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                svgObj.setControlsVisibility(HideControls);
                const imageWidth = svgObj.width;
                // if (imageWidth > IMAGE_MAX_WIDTH) {
                //     self.setState({
                //         uploadImageWidth: imageWidth,
                //         isLargeImage: true,
                //     });
                //     return false;
                // }
                //calculate image DPI
                const canvasWidthInInch = parseFloat(self.props.templateSize.width);
                // const imageDpi = getDpi(imageWidth, canvasWidthInInch);
                // self.setState({
                //     imageDPI: imageDpi,
                // });
                // if (imageDpi < DPI_THRESHOLD) {
                //     self.setState({
                //         isDPIOpen: true,
                //     });
                // }
                // Set the position of the SVG object
                svgObj.set({
                    type : 'image',
                    id: id,
                    strokeWidth: "",
                    strokeLineJoin: "bevil",
                    subTargetCheck: true,
                    transparentCorners: false,
                    hasControls: true,
                    hasBorders: true,
                    cornerColor: "#1babeb",
                    cornerStrokeColor: "#1babeb",
                    borderColor: "#1babeb",
                    cornerSize: 8,
                    padding: 5,
                    cornerStyle: "circle",
                    originX: "center",
                    originY: "center",
                    left: fabric.Canvas.originalWidth / 2,
                    isDefault : false,
                    src : src,
                    imgName : imgName
                });
                self.props.setImages(svgObj);
                //self.props.imageObject(svgObj,'add')
                var scale;
                if (svgObj.width > fabric.Canvas.originalWidth) {
                    scale = fabric.Canvas.originalWidth / 2 / svgObj.width;
                    svgObj.set({ scaleX: scale, scaleY: scale });
                } else if (svgObj.height > fabric.Canvas.originalHeight) {
                    scale = fabric.Canvas.originalHeight / 2 / svgObj.height;
                    svgObj.set({ scaleY: scale, scaleX: scale });
                }
                // if(imgType != 'clipart'){
                //     if(val !== 'duplicate')
                //     {
                //         if(svgObj?.src === src)
                //         {
                //             self.props.uploadedImages('original',0,svgObj, src, id,'',imgName)
                //         }
                //     }else{
                //         self.props.uploadedImages('duplicate',0,svgObj, src, id,'', imgName)
                //     }
                // }
              
                // Adjust the position and size of the object to fit within the canvas
                svgObj.setCoords();
                // Add the SVG object to the canvas
                canvas.add(svgObj);
                canvas.setActiveObject(svgObj);
                selectObject(canvas);
                // Render the canvas
                canvas.renderAll();
                if (canvas) {
                    let frontCanvas = getCanvasBySide(
                        self.props.allCanvasList,
                        "front"
                    );
        
                    let backCanvas = getCanvasBySide(self.props.allCanvasList, "back");
                    removeStates();
                    removeStates2();
                    addToHistory(frontCanvas);
                    addToHistory2(backCanvas);
                }
            })
        } 
        else{
            var image = new Image();
            image.src = src;
            image.crossOrigin = "anonymous";
            image.crossDomain = true;
            image.onload = () => {
                if (isImageLoaded) {
                    return;
                }
                isImageLoaded = true;
                var img = new fabric.Image(image);
                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                img.setControlsVisibility(HideControls);
                const imageWidth = img.width;
                // if (imageWidth > IMAGE_MAX_WIDTH) {
                //     this.setState({
                //         uploadImageWidth: imageWidth,
                //         isLargeImage: true,
                //     });
                //     return false;
                // }
                //calculate image DPI
                const canvasWidthInInch = parseFloat(this.props.templateSize.width);
                // const imageDpi = getDpi(imageWidth, canvasWidthInInch);
                // this.setState({
                //     imageDPI: imageDpi,
                // });
                // if (imageDpi < DPI_THRESHOLD) {
                //     this.setState({
                //         isDPIOpen: true,
                //     });
                // }
                let canvasVerticalPosition;
                let canvasHorizontalPosition;
                canvasVerticalPosition =
                canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
                canvasHorizontalPosition =
                    canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
                img.top = canvasVerticalPosition;
                img.set({
                    type : 'image',
                    id: id,
                    strokeWidth: "",
                    strokeLineJoin: "bevil",
                    fill: "#6c757d",
                    subTargetCheck: true,
                    transparentCorners: false,
                    hasControls: true,
                    hasBorders: true,
                    cornerColor: "#1babeb",
                    cornerStrokeColor: "#1babeb",
                    borderColor: "#1babeb",
                    cornerSize: 8,
                    padding: 5,
                    cornerStyle: "circle",
                    originX: "center",
                    originY: "center",
                    left: fabric.Canvas.originalWidth / 2,
                    isDefault : false,
                    src : src,
                    imgName : imgName,
                    name : imgName
                });
                this.props.setImages(img);
                //this.props.imageObject(img,'add')
                var scale;
                if (img.width > fabric.Canvas.originalWidth) {
                    scale = fabric.Canvas.originalWidth / 2 / img.width;
                    img.set({ scaleX: scale, scaleY: scale });
                } else if (img.height > fabric.Canvas.originalHeight) {
                    scale = fabric.Canvas.originalHeight / 2 / img.height;
                    img.set({ scaleY: scale, scaleX: scale });
                }
                // if(imgType != 'clipart'){
                // if(val !== 'duplicate')
                //     {
                //         if(img?._element?.currentSrc === src)
                //         {
                //             self.props.uploadedImages('original',0,img, src, id,'',imgName)
                //         }
                //     }else{
                //         self.props.uploadedImages('duplicate',0,img, src, id,'',imgName)
                //     }
                // }
                img.setCoords();
                canvas.add(img);
                canvas.setActiveObject(img);
                selectObject(canvas);
                canvas.renderAll();
                if (canvas) {
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
        
                    let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                    removeStates();
                    removeStates2();
                    addToHistory(frontCanvas);
                    addToHistory2(backCanvas);
                }
            };
        }
    };

    shapeUpload = async (event) => {
        var props = this.props;
        var canvas = this.props.canvas;
        if (event.target.files.length > 0) {
            const apiDynamicUrlAddress = config.default.api1.host;
            const apiUrl = `${apiDynamicUrlAddress}/uploadImage`;
            const currentDate = new Date();
    
            // Format the date and time
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
            // Create the formatted date and time string
            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
            const dataImage = [];
    
            // Use a Promise to wait for all FileReader tasks to complete
            const readerPromises = Array.from(event.target.files).map((file) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
    
                    reader.onload = (e) => {
                        const imageData = {
                            fileName: file.name,
                            file: e.target.result,
                        };
                        const previewData = {
                            fileName: file.name,
                            file: e.target.result,
                            isLoading : true
                        };
                        dataImage.push(imageData);
                        this.props.previewLoaderImage('',previewData)
                        resolve(); // Resolve the Promise when the FileReader task is done
                    };
    
                    reader.readAsDataURL(file);
                });
            });
    
            // Wait for all FileReader tasks to complete before making the API call
            Promise.all(readerPromises)
                .then(() => {
                    const query = new URLSearchParams(window.location.search);
                    const userId = query.get("u");
                    let payload;
                    if(userId)
                    {
                        payload = {
                            date: formattedDate,
                            userId : userId,
                            role : this.props.isAdmin ? 'admin' : 'user',
                            images: dataImage,
                        };
                    }else{
                        payload = {
                            date: formattedDate,
                            images: dataImage,
                        };
                    }
                    axios
                        .post(apiUrl, payload)
                        .then((response) => {
                            if (response.data.code === 200) {
                                response.data.data.forEach((imageData) => {
                                    this.addImageOnCanvas(imageData.ImagePath, imageData.id,'', imageData.fileName);
                                    if(!userId)
                                    {
                                        this.state.imageId.push(imageData.id)
                                        this.props.imgGallery(this.state.imageId)
                                    }else{
                                        this.props.imgGallery()
                                    }
                                    this.props.previewLoaderImage('response',imageData)
                                });
                                toast.success("Images Uploaded Successfully", { position: toast.POSITION.TOP_RIGHT });
                            } else {
                                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                            }
                        })
                        .catch((error) => console.log(error));
                })
                .catch((error) => console.log(error));
        }
        document.getElementById("gallery-upload").value = "";
    };
    deleteImage = (id) => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("u");
        const apiDynamicUrlAddress = config.default.api1.host;
        const apiUrl = `${apiDynamicUrlAddress}/deleteGalleryImage?id=${id}`;
        axios
        .delete(apiUrl)
        .then((response) => {
            if (response.status === 200) {
                if(!userId)
                {
                    const indexToDelete = this.state.imageId.findIndex(image => image === id);
                    const indexToDelete1 = this.props?.previewLoader.findIndex(image => image.id === id);

                    // Check if the image with the given id was found
                    if (indexToDelete !== -1) {
                        // Use splice to remove the image from the array
                        this.state.imageId.splice(indexToDelete, 1);
                        this.props.imgGallery(this.state.imageId)
                        toast.success("Images Deleted Successfully", { position: toast.POSITION.TOP_RIGHT });
                    }
                    if(indexToDelete1 !== -1)
                    {
                        this.props.previewLoader.splice(indexToDelete1, 1);
                    }
                }else{
                    this.props.imgGallery()
                }
            }else{
                toast.error("Images Delete Failed", { position: toast.POSITION.TOP_RIGHT });
            }
        })
        .catch((error) => console.log(error));
    }
    removeImg = (id) => {
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/removeImg?id=${id}`;
        axios
            .get(apiUrl, {
                crossDomain: true,
                enablePreflight: false,
            })
            .then((response) => {
                console.log("Response for delete", response);
                this.props.imgGallery();
            })
            .catch((err) => {
                console.log("Image Gallery Error", err);
            });
    };
    readImage = (input) => {
        if (input.files) console.log(input.files[0]);
        var canvas = this.props.canvas;
        var ctx = canvas.getContext("2d");
        //if (!this.files || !this.files[0]) return;

        const FR = new FileReader();
        FR.addEventListener("load", (evt) => {
            const img = new Image();
            img.addEventListener("load", () => {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.drawImage(img, 0, 0);
                ctx.globalCompositeOperation = "destination-in";
                ctx.beginPath();
                ctx.arc(75, 75, 50, 0, Math.PI * 2);
                //ctx.fillRect(25, 25, 100, 100);
                ctx.beginPath();
                ctx.moveTo(75, 40);
                ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
                ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
                ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
                ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
                ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
                ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
                ctx.fillStyle = "#0095DD";
                ctx.fill();
                // reset to default
                ctx.globalCompositeOperation = "source-over";
                // closePath is useless here
                //ctx.closePath();
            });
            img.src = evt.target.result;
        });
        FR.readAsDataURL(this.files[0]);
    };
    uploadIcon = () => {
        const options = {
            accept: "image/svg+xml",
            //fromSources: ['local_file_system'],
            maxSize: 1024 * 1024,
            maxFiles: 1,
            onFileUploadFinished: this.addSVG,
        };
        const filestack = client.init("A002ziWzKRKms2ktqgtQmz", options);
        const picker = filestack.picker(options);
        picker.open();
    };

    handleUploadError = (e) => {
        console.log(e);
    };
    regularPolygonPoints(sideCount, radius) {
        var sweep = (Math.PI * 2) / sideCount;
        var cx = radius;
        var cy = radius;
        var points = [];
        for (var i = 0; i < sideCount; i++) {
            var x = cx + radius * Math.cos(i * sweep);
            var y = cy + radius * Math.sin(i * sweep);
            points.push({ x: x, y: y });
        }
        return points;
    }
    starPolygonPoints(spikeCount, outerRadius, innerRadius) {
        //var rot = Math.PI / 2 * 3;
        var cx = outerRadius;
        var cy = outerRadius;
        var sweep = Math.PI / spikeCount;
        var points = [];
        var angle = 0;

        for (var i = 0; i < spikeCount; i++) {
            var x = cx + Math.cos(angle) * outerRadius;
            var y = cy + Math.sin(angle) * outerRadius;
            points.push({ x: x, y: y });
            angle += sweep;

            x = cx + Math.cos(angle) * innerRadius;
            y = cy + Math.sin(angle) * innerRadius;
            points.push({ x: x, y: y });
            angle += sweep;
        }
        return points;
    }
    fillImage = (flg) => {
        var canvas = this.props.canvas;
        var shape;
        var points;
        const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
        const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
        let canvasVerticalPosition;
        let canvasHorizontalPosition;
        canvasVerticalPosition = canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
        canvasHorizontalPosition = canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
        if (flg === 1) {
            shape = new fabric.Circle({
                type : 'circle',
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 140,
                height: 140,
                strokeWidth: "",
                stroke: "",
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false,
            });
        } else if (flg === 2) {
            shape = new fabric.Rect({
                type : 'rect',
                id: Math.floor(Math.random() * 1000),
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 200,
                height: 200,
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false
            });
        } else if (flg === 3) {
            shape = new fabric.Polygon([
                { x: 100, y: 0 },
                { x: 116, y: 46 },
                { x: 183, y: 53 },
                { x: 132, y: 87 },
                { x: 150, y: 150 },
                { x: 100, y: 120 },
                { x: 50, y: 150 },
                { x: 68, y: 87 },
                { x: 17, y: 53 },
                { x: 84, y: 46 },
              ]);
          
              // Customize hexagon properties (e.g., fill color, stroke color)
              shape.set({
                layerType : 'star',
                type : 'polygon',
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 150,
                height: 150,
                strokeWidth: "",
                stroke: "",
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false
              });
        }else if (flg === 4) {
            shape = new fabric.Polygon([
                { x: 100, y: 50 },
                { x: 150, y: 0 },
                { x: 200, y: 50 },
                { x: 200, y: 100 },
                { x: 150, y: 150 },
                { x: 100, y: 100 },
              ]);
          
              // Customize hexagon properties (e.g., fill color, stroke color)
              shape.set({
                layerType : 'hexagon',
                type : 'polygon',
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 150,
                height: 150,
                strokeWidth: "",
                stroke: "",
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false
              });
        }else if (flg === 5) {
            shape = new fabric.Polygon([
                { x: 100, y: 100 },
                { x: 200, y: 100 },
                { x: 150, y: 0 },
              ]);
          
              // Customize triangle properties (e.g., fill color, stroke color)
              shape.set({
                layerType : 'triangle',
                type : 'polygon',
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 100,
                height: 100,
                strokeWidth: "",
                stroke: "",
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false
              });
        }else if (flg === 6) {
            shape = new fabric.Polygon([
                { x: 100, y: 0 },
                { x: 200, y: 50 },
                { x: 160, y: 150 },
                { x: 40, y: 150 },
                { x: 0, y: 50 },
              ]);
          
              // Customize pentagon properties (e.g., fill color, stroke color)
              shape.set({
                layerType : 'pentagon',
                type : 'polygon',
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left:  fabric.Canvas.originalWidth / 2,
                top: canvasVerticalPosition,
                originX : "center",
                originY : "center",
                width: 200,
                height: 200,
                strokeWidth: "",
                stroke: "",
                fill: "#000",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
                isDefault : false
              });
        }
        
        canvas.add(shape);
        canvas.setActiveObject(shape);
        //canvas.centerObject(shape);
        selectObject(canvas);
        canvas.renderAll();
        if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let backCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
    };
    addSVG = (result) => {
        var canvas = this.props.canvas;
        var svg = result;
        fabric.Image.fromURL(svg, (objects) => {
            //var loadedObject = fabric.util.groupSVGElements(objects);
            objects.set({
                id: Math.floor(Math.random() * 1000),
                scaleX: 300,
                scaleY: 300,
                opacity: 1,
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
            objects.src = svg;
            canvas.add(objects);
            canvas.setActiveObject(objects);
            objects.scaleToWidth(150);
            objects.hasRotatingPoint = true;
            saveCanvasState(canvas);
            selectObject(canvas);
            canvas.renderAll();
        });
    };

    grad1colorOpen = () => {
        this.setState({
            displaygrad1ColorPicker: !this.state.displaygrad1ColorPicker,
        });
    };

    grad1colorClose = () => {
        this.setState({
            displaygrad1ColorPicker: false,
        });
        this.dynamicBGcolors(this.props.backgroundColor);
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    grad2colorOpen = () => {
        this.setState({
            displaygrad2ColorPicker: !this.state.displaygrad2ColorPicker,
        });
    };

    grad2colorClose = () => {
        this.setState({
            displaygrad2ColorPicker: false,
        });
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    bgcolorOpen = () => {
        this.setState({
            displaybgColorPicker: !this.state.displaybgColorPicker,
        });
    };

    bgcolorClose = () => {
        this.setState({
            displaybgColorPicker: false,
        });
        this.dynamicBGcolors(this.props.backgroundColor);
    };

    setVerticalgradient = (color) => {
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    setRadialgradient = (color) => {
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "radial"
        );
    };

    setGradient1BGcolor = (color) => {
        this.setState({
            grad1color: color.hex,
        });
    };

    setGradient2BGcolor = (color) => {
        this.setState({
            grad2color: color.hex,
        });
    };

    setGradientBGcolor = (colone, coltwo, type) => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        // if (
        //     canvasObj[2] === undefined ||
        //     canvasObj[canvasObj.length - 1] === "front"
        // )
        //     canvas = canvasObj[0];
        // else canvas = canvasObj[1];
        // if (!colone || !coltwo) return;
        deleteCanvasBg(this.props.canvas);
        this.props.triggerState()
        if (type === "vertical") {
            var verticalgrad = new fabric.Gradient({
                type: "linear",
                coords: {
                    x1: 0,
                    y1: canvas?.height / 4,
                    x2: 0,
                    y2: canvas?.height / 2 + canvas?.height / 4,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            if (canvas) {
                canvas.backgroundColor = verticalgrad;
                canvas.renderAll();
            }
        }
        if (type === "radial") {
            var radialgrad = new fabric.Gradient({
                type: "radial",
                coords: {
                    r1: canvas?.width / 2,
                    r2: canvas?.width / 4,
                    x1: canvas?.width / 2 - 1,
                    y1: canvas?.height / 2 - 1,
                    x2: canvas?.width / 2,
                    y2: canvas?.height / 2,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            if (canvas) {
                canvas.backgroundColor = radialgrad;
                canvas.renderAll();
            }
        }
        if (type === "horizontal") {
            var horizontalgrad = new fabric.Gradient({
                type: "linear",
                coords: {
                    x1: canvas?.width / 4,
                    y1: 0,
                    x2: canvas?.width / 2 + canvas?.width / 4,
                    y2: 0,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            if (canvas) {
                canvas.backgroundColor = horizontalgrad;
                canvas.renderAll();
            }
        }

        let frontCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "front"
        );

        let backCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "back"
        );

        removeStates();
        removeStates2();
        addToHistory(frontCanvas);
        addToHistory2(backCanvas);
    };
    setBGcolor = (color) => {
        deleteCanvasBg(this.props.canvas);
        this.props.triggerState()
        var canvas = this.props.canvas;
        if (canvas) {
            // var canvasObj = this.props.canvasObj;
            // if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
            // canvas=canvasObj[0]
            // else
            // canvas=canvasObj[1]
            canvas.backgroundColor = color;
            canvas.renderAll();
            // saveCanvasState(canvas);

            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );

            let backCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
            // setTimeout(() => {
            //     addToHistory(frontCanvas)
            //     addToHistory2(backCanvas)

            // }, (500))

            // removeStates()
            // removeStates2()

        }


    };

    refreshCanvas = (canvas) => {
        canvas.renderAll(canvas);
        saveCanvasState(canvas);
    };

    applyBGPattern = (result) => {
        deleteCanvasBg(this.props.canvas);
        var canvas = this.props.canvas;
        canvas.setBackgroundColor(
            {
                source: result,
            },
            this.refreshCanvas.bind(this, canvas)
        );
    };

    incermentpage = () => {
        this.setState(
            {
                pagenum: this.state.pagenum + 1,
            },
            () => { }
        );
    };

    searchImage = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            this.setState(
                {
                    searchkey: e.target.value,
                },
                () => { }
            );
        }
    };

    incerment = () => {
        this.setState(
            {
                page: this.state.page + 1,
            },
            () => { }
        );
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    addMasking = (maskUrl) => {
        let selectedImageObject1 = this.props.canvas.getActiveObject();
        if (!selectedImageObject1) {
            return;
        }

        fabric.Image.fromURL(
            maskUrl,
            (maskImg) => {
                var tempCanvas = new fabric.Canvas("canvas-element", {
                    width: maskImg.width,
                    height: maskImg.height,
                });

                var canvas = this.props.canvas;

                var rect = new fabric.Rect({
                    left: 0,
                    top: 0,
                    width: maskImg.width,
                    height: maskImg.height,
                    fill: "white",
                    borders: "none",
                    strokeWidth: 0,
                });

                tempCanvas.add(rect);

                maskImg.set({ left: 0, top: 0 });

                tempCanvas.add(maskImg);

                tempCanvas.renderAll();

                let group = new fabric.Group([rect, maskImg]);
                // if (fabric.isWebglSupported()) {
                //     fabric.textureSize = fabric.maxTextureSize;
                // }

                fabric.enableGLFiltering = false;
                let imageTextureSize =
                    selectedImageObject1.width * selectedImageObject1.height;
                if (imageTextureSize > fabric.textureSize) {
                    fabric.textureSize = imageTextureSize;
                }
                const tempurl = maskImg.toDataURL({ format: "png" });

                console.log("tempurl", tempurl);
                fabric.Image.fromURL(
                    tempurl,
                    (groupimage) => {
                        let filteration = new fabric.Image.filters.BlendColor(
                            {
                                color: "white",
                                mode: "tint",
                                alpha: 1,
                            },
                            { crossOrigin: "anonymous" }
                        );

                        groupimage.filters.pop();
                        groupimage.filters.push(filteration);
                        groupimage.applyFilters();

                        canvas.renderAll();

                        setTimeout(() => {
                            if (selectedImageObject1.type === "image") {
                                var filter =
                                    new fabric.Image.filters.BlendImage(
                                        {
                                            id: Math.floor(
                                                Math.random() * 1000
                                            ),
                                            image: groupimage,
                                            mode: "multiply",
                                            alpha: 1,
                                            name: "multiMask",
                                        },
                                        { crossOrigin: "anonymous" }
                                    );

                                if (selectedImageObject1.filters) {
                                    let index =
                                        selectedImageObject1.filters.findIndex(
                                            (obj) => {
                                                return obj.id === filter.id;
                                            }
                                        );
                                    selectedImageObject1.filters.splice(
                                        index,
                                        1
                                    );
                                }
                                selectedImageObject1.filters.push(filter);
                                selectedImageObject1.applyFilters();

                                canvas.renderAll();

                                if (canvas) {
                                    let frontCanvas = getCanvasBySide(
                                        this.props.allCanvasList,
                                        "front"
                                    );

                                    let backCanvas = getCanvasBySide(
                                        this.props.allCanvasList,
                                        "back"
                                    );

                                    setTimeout(() => {
                                        removeStates();
                                        removeStates2();
                                        addToHistory(frontCanvas);
                                        addToHistory2(backCanvas);
                                    }, 200);
                                }
                            }
                        }, 1000);
                    },
                    { crossOrigin: "anonymous" }
                );
            },
            { crossOrigin: "anonymous" }
        );

        //  var extend = fabric.util.object.extend,
        // filters = fabric.Image.filters,
        // createClass = fabric.util.createClass;

        // filters.ReplaceColor = createClass(filters.BaseFilter, {

        //     type: 'ReplaceColor',

        //     color: '#000000',
        //     replacementColor: [255, 255, 255, 1],

        //     fragmentSource: 'precision highp float;\n' +
        //         'uniform sampler2D uTexture;\n' +
        //         'uniform vec4 uLow;\n' +
        //         'uniform vec4 uHigh;\n' +
        //         'uniform vec4 uRep;\n' +
        //         'varying vec2 vTexCoord;\n' +
        //         'void main() {\n' +
        //         'gl_FragColor = texture2D(uTexture, vTexCoord);\n' +
        //         'if(all(greaterThan(gl_FragColor.rgb,uLow.rgb)) && all(greaterThan(uHigh.rgb,gl_FragColor.rgb))) {\n' +
        //         'gl_FragColor.rgb = uRep.rgb;\n' +
        //         'gl_FragColor.a = uRep.a;\n' +
        //         '}\n' +
        //         '}',

        //     distance:0.5,

        //     useAlpha: false,

        //     applyTo2d: function (options) {
        //         var imageData = options.imageData,
        //             data = imageData.data, i,
        //             distance = this.distance * 255,
        //             r, g, b,
        //             source = new fabric.Color(this.color).getSource(),
        //             lowC = [
        //                 source[0] - distance,
        //                 source[1] - distance,
        //                 source[2] - distance,
        //             ],
        //             highC = [
        //                 source[0] + distance,
        //                 source[1] + distance,
        //                 source[2] + distance,
        //             ];

        //         for (i = 0; i < data.length; i += 4) {
        //             r = data[i];
        //             g = data[i + 1];
        //             b = data[i + 2];
        //             if (r > lowC[0] &&
        //                 g > lowC[1] &&
        //                 b > lowC[2] &&
        //                 r < highC[0] &&
        //                 g < highC[1] &&
        //                 b < highC[2]) {
        //                 data[i] = this.replacementColor[0];
        //                 data[i + 1] = this.replacementColor[1];
        //                 data[i + 2] = this.replacementColor[2];
        //                 data[i + 3] = this.replacementColor[3];
        //             }
        //         }
        //     },

        //     getUniformLocations: function (gl, program) {
        //         return {
        //             uLow: gl.getUniformLocation(program, 'uLow'),
        //             uHigh: gl.getUniformLocation(program, 'uHigh'),
        //             uRep: gl.getUniformLocation(program, 'uRep')
        //         };
        //     },

        //     sendUniformData: function (gl, uniformLocations) {
        //         var source = new fabric.Color(this.color).getSource(),
        //             distance = parseFloat(this.distance),
        //             lowC = [
        //                 0 + source[0] / 255 - distance,
        //                 0 + source[1] / 255 - distance,
        //                 0 + source[2] / 255 - distance,
        //                 1
        //             ],
        //             highC = [
        //                 source[0] / 255 + distance,
        //                 source[1] / 255 + distance,
        //                 source[2] / 255 + distance,
        //                 1
        //             ],
        //             repC = [
        //                 this.replacementColor[0] / 255,
        //                 this.replacementColor[1] / 255,
        //                 this.replacementColor[2] / 255,
        //                 this.replacementColor[3]
        //             ];
        //         gl.uniform4fv(uniformLocations.uLow, lowC);
        //         gl.uniform4fv(uniformLocations.uHigh, highC);
        //         gl.uniform4fv(uniformLocations.uRep, repC);
        //     },

        //     toObject: function () {
        //         return extend(this.callSuper('toObject'), {
        //             color: this.color,
        //             replacementColor: this.replacementColor,
        //             distance: this.distance
        //         });
        //     }
        // });

        // fabric.Image.filters.ReplaceColor.fromObject = fabric.Image.filters.BaseFilter.fromObject;
    };

    imagetoggle(tab) {
        if (this.state.imgactiveTab !== tab) {
            this.setState({
                imgactiveTab: tab,
            });
        }
    }

    setTemplateKey(templateKey) {
        this.props.setTemplateKey(templateKey);
    }

    setActiveTab(activeTabKey) {
        this.props.setActiveTab(activeTabKey);
    }

    closeMasking = () => {
        document.getElementById("vertical-tab-five-tab").click();
    };

    closeMaskingMobile = () => {
        document.getElementById("imageTabMobile").click();
    };
    colorCategory = (val) => {
        if(val == 'standard')
        {
            this.setState({
                colorCat : "standard"
            })
        }
        if(val == 'recommend')
        {
            this.setState({
                colorCat : "recommend"
            })
        }
        if(val == 'custom')
        {
            this.setState({
                colorCat : "custom"
            })
        }
    }
    handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const apiDynamicUrlAddress = config.default.api1.host;
        const apiUrl = `${apiDynamicUrlAddress}/uploadImage`;
        const currentDate = new Date();

        // Format the date and time
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Create the formatted date and time string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        const dataImage = [];

        // Use a Promise to wait for all FileReader tasks to complete
        const readerPromises = Array.from(files).map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const imageData = {
                        fileName: file.name,
                        file: e.target.result,
                    };
                    const previewData = {
                        fileName: file.name,
                        file: e.target.result,
                        isLoading : true
                    };
                    dataImage.push(imageData);
                    this.props.previewLoaderImage('',previewData)
                    resolve(); // Resolve the Promise when the FileReader task is done
                };

                reader.readAsDataURL(file);
            });
        });

        // Wait for all FileReader tasks to complete before making the API call
        Promise.all(readerPromises)
            .then(() => {
                const query = new URLSearchParams(window.location.search);
                const userId = query.get("u");
                let payload;
                if(userId)
                {
                    payload = {
                        date: formattedDate,
                        userId : userId,
                        role : this.props.isAdmin ? 'admin' : 'user',
                        images: dataImage,
                    };
                }else{
                    payload = {
                        date: formattedDate,
                        images: dataImage,
                    };
                }
                axios
                    .post(apiUrl, payload)
                    .then((response) => {
                        if (response.data.code === 200) {
                            response.data.data.forEach((imageData) => {
                                this.addImageOnCanvas(imageData.ImagePath, imageData.id,'', imageData.fileName);
                                if(!userId)
                                {
                                    this.state.imageId.push(imageData.id)
                                    this.props.imgGallery(this.state.imageId)
                                }else{
                                    this.props.imgGallery()
                                }
                                this.props.previewLoaderImage('response',imageData)
                            });
                            toast.success("Images Uploaded Successfully", { position: toast.POSITION.TOP_RIGHT });
                        } else {
                            toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
                        }
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
      };
    
      handleDragOver = (e) => {
        e.preventDefault();
      };
    handleDragEnd = (result) => {
        if (!result.destination) return;
        this.props.draglayer(result)
        // Generate dynamic indices array based on dataArray length
        // if(newSortedArray.length === 0)
        // {
        //     const indices = Array.from({ length: this.props?.canvas?._objects.length }, (_, index) => index).reverse();

        //     // Sort dataArray based on indices
        //     const sortedArray = indices.map(index => this.props?.canvas?._objects[index]);
        //     const updatedItems = [...sortedArray];
        //     const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        //     updatedItems.splice(result.destination.index, 0, reorderedItem);
        //     const indices1 = Array.from({ length: updatedItems.length }, (_, index) => index).reverse();
        //     const sortedArray1 = indices1.map(index => updatedItems[index]);
        //     this.props.canvas.clear();
        //     sortedArray1.forEach((item)=>{
        //         this.props.canvas.add(item)
        //         this.props.canvas.renderAll()
        //     })
        //     // Set the first item as the active object (selected)
        //     if (sortedArray1.length > 0) {
        //         this.props.canvas.setActiveObject(sortedArray1[0]);
        //     }
        //     this.setState({
        //         newSortedArray : updatedItems
        //     })
        // }else{           
        //     const updatedItems = [...newSortedArray];
        //     const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        //     updatedItems.splice(result.destination.index, 0, reorderedItem);
        //     this.setState({
        //         newSortedArray : updatedItems
        //     })
        //     const indices1 = Array.from({ length: updatedItems.length }, (_, index) => index).reverse();
        //     const sortedArray1 = indices1.map(index => updatedItems[index]);
        //     this.props.canvas.clear();
        //     sortedArray1.forEach((item)=>{
        //         this.props.canvas.add(item)
        //         this.props.canvas.renderAll()
        //     })
        //     // Set the first item as the active object (selected)
        //     if (sortedArray1.length > 0) {
        //         this.props.canvas.setActiveObject(sortedArray1[0]);
        //     }
        // }
        
    };
    // deleteLayer = (object) => {
    //     const { newSortedArray } = this.state
    //     if(this.state.newSortedArray.length > 0)
    //     {
    //         const updatedArray = newSortedArray.filter(item => item !== object);
    //         this.setState({
    //             newSortedArray : updatedArray
    //         })
    //         this.props.canvas.remove(object)
    //     }else{
    //         this.props.canvas.remove(object)
    //         this.props.canvas.renderAll()
    //         this.props.triggerState()
    //     }
        
    // }
    layerActive = (obj) => {
        if(this.props.canvas)
        {
            this.props.canvas.setActiveObject(obj)
            this.props.canvas.renderAll()
        }
    }
    render() {
        const { clipartData, subcat, clipartImages, frontValues,colorCat,newSortedArray } = this.state;
        const activeTab = this.props.activeTab;
        const { selectedCategory, selectedImage,clipartCategories } = this.state;
        const selectedCategoryObj = clipartCategories?.find(
          (category) => category.value === selectedCategory
        );
        // Get the unique images based on the base64 data
        // let uniqueImages = [];
        // const imageFiles = new Set();

        // this.props.selectedImages && this.props.selectedImages.forEach((image) => {
        //     if (!imageFiles.has(image.file)) {
        //     imageFiles.add(image.file);
        //     uniqueImages.push(image);
        //     }
        // });
        const { sortedArray,canvas }= this.props
        return (
            <>
                <div className="side-panel-container largedevice" style={{ height: "auto", marginTop: "36px", width: "250px" }}>
                    <TabPanel tabId="vertical-tab-one">
                        <Container className="text-editer text-gallery">
                            <Row className="img-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Text
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    sm="12"
                                    style={{ backgroundColor: "#fff", height : "378px", overflow: "hidden" }}
                                >
                                    <div className="dcl-upload-button" 
                                     onClick={() => {
                                        this.addText(
                                            "title",
                                            "Type text here",
                                            false
                                        );
                                    }}
                                    >
                                        {" "}
                                        <label
                                            className=" dcl-button dcl-button--upload dcl-button--full-width"
                                        >
                                            Add Text
                                        </label>
                                    </div>
                                    <div className="sample1-text">
                                        {/* <div className="front-side-title">
                                            Front Side
                                        </div>
                                        <div className="text-outer">
                                            {frontValues.length === 0 && <p>Text</p>}
                                        </div>
                                        <div className="add-text-outer">
                                            {this.props.canvas && <Textlayer key={Math.floor(Math.random() * 1000)} canvas={this.props.canvas} frontValues={frontValues} />}
                                        </div> */}
                                        <div onClick={()=> {
                                        this.addSampleText1(
                                            "title","",false
                                        );}}>
                                            <p className="sample-text-p1">Life is an</p>
                                            <p className="sample-text-p2">Adventure</p>
                                        </div>
                                       <div style={{marginTop : "-8px"}} onClick={()=> {
                                        this.addSampleText2(
                                            "title","",false
                                        );}}>
                                            <p className="sample1-text-p1">Save</p>
                                            <p className="sample1-text-p2">the</p>
                                            <p className="sample1-text-p3">Date</p>
                                       </div>
                                    </div>
                                    <div className="sample2-text">
                                        <div style ={{width : "60%"}} onClick={()=> {
                                            this.addSampleText3(
                                                "title","",false
                                            );}}>
                                                <p className="sample2-text-p1">MARKETING SHARE</p>
                                                <p className="sample2-text-p2">30.65%</p>
                                                <p className="sample2-text-p3">We project our market share to increase from 32.65% to 30.65 % 
                                                in 2022.
                                                </p>
                                        </div>
                                        <div onClick={()=> {
                                            this.addSampleText4(
                                                "title","",false
                                            );}}>
                                                <p className="sample3-text-p1">Just Keep</p>
                                                <p className="sample3-text-p2">TRYING</p>
                                        </div>
                                    </div>
                                    <div className="sample3-text">
                                        <div onClick={()=> {
                                            this.addSampleText5(
                                                "title","",false
                                            );}}>
                                                <p className="sample4-text-p1">BUY</p>
                                                <p className="sample4-text-p2">NOW</p>
                                        </div>
                                        <div onClick={()=> {
                                            this.addSampleText6(
                                                "title","",false
                                            );}}>
                                                <p className="sample5-text-p1">Never</p>
                                                <p className="sample5-text-p2">Lose</p>
                                                <p className="sample5-text-p3">Hope</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel
                        tabId="vertical-tab-three"
                        style={{}}
                    >
                        <Container className="text-editer clip-gallery">
                            <Row className="img-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Clip&nbsp;Art
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row
                                        style={{
                                            backgroundColor: "white",
                                        }}
                                    >
                                    <Col sm="12">
                                        {selectedCategoryObj ?
                                        <>
                                        <div className="dcl-upload-button">
                                            <input type="text" placeholder="Search" value={this.state.searchQuery} onChange={this.handleSearchQueryChange} />
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </div>
                                        <div className="dcl-browse-button">
                                            <label>Browse by category</label>
                                            {/* <select
                                            value={selectedCategory}
                                            onChange={(e) => this.handleCategorySelect(Number(e.target.value))}
                                            >
                                            {clipartCategories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                {category.name}
                                                </option>
                                            ))}
                                            </select> */}
                                            <Select
                                                className="clipart-dropdown"
                                                value={selectedCategoryObj}
                                                onChange={this.handleCategorySelect}
                                                options={clipartCategories}
                                                styles={{
                                                    option: optionStyles, // Apply the custom styles to the options
                                                }}
                                            />
                                        </div>
                                            <div className="clipimage-container-outer" style={{overflow : selectedCategoryObj.images.filter((image) =>
                                                image.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                            ).length < 4 && this.state.searchQuery !== "" && "hidden" }}>
                                            {selectedCategoryObj.images
                                                .filter((image) =>
                                                image.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                                )
                                                .map((image) => (
                                                <div className="clipimage-container">
                                                    <div className="clipimage-container-inner">
                                                        <img
                                                        key={image.name}
                                                        src={image.url}
                                                        alt={image.name}
                                                        className="clipart-img"
                                                        />
                                                        <i className="fa fa-plus add-clipart" onClick={() => this.addImageOnCanvas(image.url,'','',image.name)}></i>
                                                    </div>
                                                </div>
                                                ))}
                                            {selectedCategoryObj.images.filter((image) =>
                                                image.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                            ).length === 0 && this.state.searchQuery !== "" && (
                                                <div style={{position: "absolute"}}>No Clipart Images found</div>
                                            )}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="clip-gallery-no-image-icon">
                                                <LuImageOff/>
                                            </div>
                                            <p className="clip-gallery-no-image-text">No Clipart Images found</p>
                                        </>
                                        }
                                    </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <div className="pixabaysection">
                                                <Form className="searchbar">
                                                    <Input
                                                        type="text"
                                                        onKeyPress={(event) =>
                                                            this.searchUnsplashimg(
                                                                event
                                                            )
                                                        }
                                                        placeholder="Search Images"
                                                    />
                                                </Form>
                                                <div
                                                    ref={this.imgScrollRef}
                                                    className="scroller"
                                                    id="scroll-1"
                                                >
                                                    {this.state.unsplashImg?.map(
                                                        (photo, index) => {
                                                            return (
                                                                <span
                                                                    className="image-wrapper"
                                                                    key={index}
                                                                    onClick={() =>
                                                                        this.addImageOnCanvas(
                                                                            photo
                                                                                .urls
                                                                                .regular
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        className="pixabay"
                                                                        src={
                                                                            photo
                                                                                .urls
                                                                                .regular
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-five">
                        <Container className="text-editer image-gallery" style={{overflowY : (this.props?.selectedImages.length > 4) || (this.props?.previewLoader.length > 4) ? "scroll" : ""}}>
                            {/* <Nav tabs> */}
                            <Row className="img-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Image Gallery
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <div className="dcl-upload-button" onDrop={this.handleDrop} onDragOver={this.handleDragOver}>
                                                {" "}
                                                <label
                                                    htmlFor="gallery-upload"
                                                    className=" dcl-button dcl-button--upload dcl-button--full-width"
                                                >
                                                    Upload Image
                                                </label>
                                                <form>
                                                    <input
                                                        id="gallery-upload"
                                                        type="file"
                                                        onChange={
                                                            this.shapeUpload
                                                        }
                                                        accept=".jpg, .jpeg, .png, .svg, .gif"
                                                        multiple
                                                    />
                                                </form>
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.props.selectedImages && this.props.selectedImages.length > 0 ? 
                                        <Row className='image-preview'>
                                            <div className="uploads-title">Uploads ({this.props.selectedImages.length})</div>
                                            {this.props.selectedImages.map((image, index) => {
                                                const imageObject = this.props.canvas._objects.find(obj => obj.id === image.id);
                                                const isUsed = (imageObject !== undefined);
                                                return(
                                                    <div className="image-container">
                                                    <div className={`used-image-outer ${isUsed ? 'used' : ''}`}>
                                                        {isUsed && <span className="used-image">Used</span>}
                                                    </div>
                                                    <div>
                                                        <img
                                                        className={this.props.selectedImages.length > 4 ? "image-upload-gallery-fox" : "image-upload-gallery"}
                                                        key={index}
                                                        src={image.image_url}
                                                        alt={`Selected Preview ${index + 1}`}
                                                        />
                                                    </div>
                                                    <div className="icon-outer">
                                                        <i onClick={() => this.deleteImage(image.id)} className="fa fa-trash" aria-hidden="true"></i>
                                                        <i className="fa fa-plus" onClick={() => this.addImageOnCanvas(image.image_url,image.id,'duplicate',image.fileName)}></i>
                                                    </div>
                                                    </div>
                                                )
                                            })}
                                        </Row>
                                        : 
                                        <Row>
                                            {this.props?.previewLoader.length === 0 &&
                                            <>
                                            <p className="img-text">
                                                Click and drag an image from your gallery to the design area. Supported file type (.jpg, .jpeg, .png, .svg, .gif)
                                            </p>
                                            <div className="img-upload">
                                                <i className="fas fa-image fa-2x text-muted "></i>
                                            </div>
                                            </>
                                            }
                                        </Row>
                                    }
                                    <Row className='loader-image-preview'>
                                        {this.props?.previewLoader.map((image, index) => {
                                            return(
                                                <div className="loader-image-container">
                                                {image.isLoading &&
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                }
                                                </div>
                                            )
                                        })}
                                    </Row>
                                    {/* <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <div
                                                id="uploadedImgDiv"
                                                style={{ maxHeight: "500px" }}
                                            >
                                                {
                                                    <div className="grid">
                                                        {this.props.imggallery.map(
                                                            (
                                                                message,
                                                                index
                                                            ) => (
                                                                <span
                                                                    className="grid-item"
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        className="image"
                                                                        src={`${HOST_SITE}/${message.imageUrl}`}
                                                                        onClick={() =>
                                                                            this.addImageOnCanvas(
                                                                                `${HOST_SITE}/${message.imageUrl}`
                                                                            )
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <span className="imtip">
                                                                        <i
                                                                            className="fas fa-trash"
                                                                            style={{
                                                                                color: "#6C757D",
                                                                            }}
                                                                            onClick={() =>
                                                                                this.removeImg(
                                                                                    message.id
                                                                                )
                                                                            }
                                                                        ></i>
                                                                    </span>
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row> */}
                                </TabPane>
                            </TabContent>
                            {/* <Modal
                                show={
                                    this.state.isDPIOpen ||
                                    this.state.isLargeImage
                                }
                                onHide={this.closeDPIModal}
                                size="default"
                                id="lowDPI"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {this.state.isDPIOpen ? (
                                            <span style={{ fontSize: "24px" }}>
                                                Low DPI!
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.isLargeImage ? (
                                            <span style={{ fontSize: "24px" }}>
                                                Large Image
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </Modal.Title>
                                    <div
                                        className=""
                                        style={{
                                            position: "absolute",
                                            marginLeft: "60%",
                                        }}
                                    >
                                        &nbsp;
                                    </div>{" "}
                                    <span
                                        title="close"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.closeDPIModal}
                                    >
                                        {" "}
                                        X
                                    </span>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.isDPIOpen ? (
                                        <p>
                                            <span>
                                                DPI of an uploaded image is{" "}
                                                <strong>
                                                    {this.state.imageDPI}
                                                </strong>
                                            </span>
                                            <span className="text-danger d-inline-block mt-2">
                                                <strong>Warning:</strong> It is
                                                recommended to upload an image
                                                with minimum{" "}
                                                <strong>{DPI_THRESHOLD}</strong>{" "}
                                                DPI
                                            </span>
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.isLargeImage ? (
                                        <p>
                                            <span>
                                                Width of uploaded image is{" "}
                                                <strong>
                                                    {
                                                        this.state
                                                            .uploadImageWidth
                                                    }
                                                </strong>{" "}
                                                pixels
                                            </span>
                                            <span className="text-danger d-inline-block mt-2">
                                                <strong>Error:</strong> Image
                                                width must be less than{" "}
                                                <strong>
                                                    {IMAGE_MAX_WIDTH}
                                                </strong>{" "}
                                                pixels
                                            </span>
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </Modal.Body>
                                <Modal.Footer
                                    style={{ justifyContent: "center" }}
                                >
                                    <button
                                        type="button"
                                        id="closeDPI"
                                        className={`btn btn-primary`}
                                        onClick={this.closeDPIModal}
                                    >
                                        Ok
                                    </button>
                                </Modal.Footer>
                            </Modal> */}
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-eight">
                        <Container className='color-gallery'>
                            <Row className="color-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Add Background Color
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" style={{cursor : 'pointer'}}  onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <Row style={{background : "#fff"}}>
                                <div className="color-outer">
                                    <button style={{background : colorCat === 'standard' ? "#fff" : '#e1e6eb', border : colorCat === 'standard' ? "1px solid #00b5e2" : '#e1e6eb'}} onClick={() => this.colorCategory('standard')}>Standard</button>
                                    <button style={{background : colorCat === 'recommend' ? "#fff" : '#e1e6eb', border : colorCat === 'recommend' ? "1px solid #00b5e2" : '#e1e6eb'}} onClick={() => this.colorCategory('recommend')}>Recommended</button>
                                    <button style={{background : colorCat === 'custom' ? "#fff" : '#e1e6eb', border : colorCat === 'custom' ? "1px solid #00b5e2" : '#e1e6eb'}} onClick={() => this.colorCategory('custom')}>Custom</button>
                                </div>
                            </Row>
                            {colorCat === 'standard' ? 
                            <Row>
                                <Col>
                                    <p className="first-title">Solid Colors</p>
                                    <div className="solid-colors">
                                        {

                                            INIT_SOLID_COLORS?.map((item) => (
                                                <span
                                                    key={item}
                                                    className={item === (canvas && canvas.backgroundColor) ? 'activeSolidcolor solidcolor' : "solidcolor"}
                                                    style={{
                                                        backgroundColor: item,
                                                    }}
                                                    onClick={() =>
                                                        this.setBGcolor(item)
                                                    }
                                                />
                                            ))}


                                        {this.state.bgcolArray?.map(
                                            (colorval, index) => {
                                                return colorval ? (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            background:
                                                                colorval,
                                                        }}
                                                        className="solidcolor"
                                                        onClick={() =>
                                                            this.setBGcolor(
                                                                colorval
                                                            )
                                                        }
                                                    ></span>
                                                ) : null;
                                            }
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            :
                            ''}
                            {colorCat === 'recommend' ? 
                            <Row>
                                <Col>
                                    <p className="first-title">Gradients</p>
                                    <div className="gradients-colors">
                                        <span
                                            className={canvas && canvas.backgroundColor.colorStops && canvas.backgroundColor.colorStops[0].color === '#62ff00' ? "activeSolidcolor grdcol1 grdcolor" : "grdcol1 grdcolor"}
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#62ff00",
                                                    "yellow",
                                                    "vertical"
                                                )
                                            }
                                        />
                                        <span
                                            className={canvas && canvas.backgroundColor.colorStops && canvas.backgroundColor.colorStops[0].color === '#FF0000' ? "activeSolidcolor grdcol2 grdcolor" : "grdcol2 grdcolor"}
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#FF0000",
                                                    "yellow",
                                                    "horizontal"
                                                )
                                            }
                                        />
                                        <span
                                            className={canvas && canvas.backgroundColor.colorStops && canvas.backgroundColor.colorStops[0].color === '#ff9900' ? "activeSolidcolor grdcol3 grdcolor" : "grdcol3 grdcolor"}
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#ff9900",
                                                    "#39d4cd",
                                                    "horizontal"
                                                )
                                            }
                                        />
                                        <span
                                            className={canvas && canvas.backgroundColor.colorStops && canvas.backgroundColor.colorStops[0].color === '#FF1919' ? "activeSolidcolor grdcol4 grdcolor" : "grdcol4 grdcolor"}
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#FF1919",
                                                    "#fff",
                                                    "horizontal"
                                                )
                                            }
                                        />

                                    </div>
                                </Col>
                            </Row>
                            : 
                            ''}
                            {colorCat === 'custom' ? 
                            <Row className="custom-color">
                                <Col>
                                    <p className="first-title">Solid Colors</p>
                                    <div className="solid-colors">
                                        <div className="popover">
                                            <div
                                                className="cover"
                                                onClick={this.bgcolorClose}
                                            />
                                            <SketchPicker
                                                color={
                                                    canvas.backgroundColor.colorStops ? canvas.backgroundColor.colorStops[0].color : canvas.backgroundColor
                                                }
                                                onChange={
                                                    this.setCanvasFill
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            :
                            ''}
                        </Container>
                    </TabPanel>
                    <TabPanel
                        tabId="vertical-tab-four"
                        className="shape-gallery"
                    >
                        <Container className="text-editer">
                        <Row className="img-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Shapes
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{
                                                backgroundColor: "white",
                                            }}
                                        >
                                            {/* <p className='first-title'>Shapes</p> */}
                                            <div className="patterns shapes">
                                                <div className="patterns shapes-inner">
                                                <i
                                                    className="shape fas fa-circle "
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(1)}></i>
                                                </div>
                                                <div className="patterns shapes-inner">
                                                <i
                                                    className="shape fas fa-square "
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(2)}></i>
                                                </div>
                                                <div className="patterns shapes-inner">
                                                <i
                                                    className="shape fas fa-star "
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(3)}></i>
                                                </div>
                                                <div className="patterns shapes-inner">
                                                <i
                                                    className="shape bi bi-hexagon-fill"
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(4)}></i>
                                                </div>
                                                <div className="patterns shapes-inner">
                                                <i
                                                    className="shape bi bi-triangle-fill"
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(5)}></i>
                                                </div>
                                                <div className="patterns shapes-inner">
                                                 <i
                                                    className="shape bi bi-pentagon-fill"
                                                ></i>
                                                <i className="add-shape fa fa-plus" onClick={() => this.fillImage(6)}></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-two">
                        <Container className="text-editer layers-gallery">
                            <Row className="img-title">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        fontSize: "14px",
                                        color: "#697582",
                                        maxWidth: "max-content"
                                    }}
                                >
                                    Layers
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "max-content",
                                        width: "auto"
                                    }}
                                >
                                    <i className="fa fa-times" onClick={() => this.props.toggleSidebar(!this.props.collapse)}></i>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" style={{ backgroundColor: "#fff", overflow: "auto" }}>
                                    {sortedArray.length > 0 ? (
                                    <DragDropContext onDragEnd={this.handleDragEnd}>
                                        <Droppable droppableId="layer-cards">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {sortedArray.map((item, i) => (
                                                item.name != 'selectionRect' && (
                                                <Draggable key={i} draggableId={`item-${i}`} index={i} isDragDisabled={item.isLocked}>
                                                {(provided) => (
                                                    <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`layer-card ${item.isLocked ? 'locked' : ''}`}
                                                    onClick={(e) => {
                                                        if (!e.target.classList.contains('fa-trash-alt')) {
                                                        this.layerActive(item);
                                                        }
                                                    }}
                                                    >
                                                    {item.type === 'textbox' && (
                                                        <div className='layer-shapes' style={{color : item.fill, fontFamily: item.fontFamily}}>
                                                        {item.text}
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={(e) => {
                                                            if (!item.isLocked) {
                                                                e.stopPropagation();
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {item.type === 'circle' && (
                                                        <div className='layer-shapes'>
                                                        <i style={{fontSize: "1.5rem", color : item.fill,cursor : item.isLocked && 'no-drop'}} class="shape fas fa-circle "></i>
                                                        <p>Circle</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {item.type === 'image' && (
                                                        <div className='layer-shapes'>
                                                        <img src={item.src}/>
                                                        <p style={{wordBreak : "break-all"}}>{item.imgName ? item.imgName : 'Default Image'}</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {item.type === 'rect' && (
                                                        <div className='layer-shapes'>
                                                         <i style={{fontSize: "1.5rem", color : item.fill,cursor : item.isLocked && 'no-drop'}} class="shape fas fa-square "></i>
                                                         <p>Square</p>
                                                         <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                     {(item.layerType === 'star' || item?.points?.length === 10) && (
                                                        <div className='layer-shapes'>
                                                        <i style={{fontSize: "1.5rem", color : item.fill,cursor : item.isLocked && 'no-drop'}} class="shape fas fa-star "></i>
                                                        <p>Star</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {(item.layerType === 'hexagon' || item?.points?.length === 6) && (
                                                        <div className='layer-shapes'>
                                                        <i style={{fontSize: "1.5rem", color : item.fill, cursor : item.isLocked && 'no-drop' }} class="shape bi bi-hexagon-fill"></i>
                                                        <p>Hexagon</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {(item.layerType === 'triangle' || item?.points?.length === 3) && (
                                                        <div className='layer-shapes'>
                                                        <i style={{fontSize: "1.5rem", color : item.fill,cursor : item.isLocked && 'no-drop'}} class="shape bi bi-triangle-fill"></i>
                                                        <p>Triangle</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {(item.layerType === 'pentagon' || item?.points?.length === 5) && (
                                                        <div className='layer-shapes'>
                                                        <i style={{fontSize: "1.5rem", color : item.fill,cursor : item.isLocked && 'no-drop'}} class="shape bi bi-pentagon-fill"></i>
                                                        <p>Pentagon</p>
                                                        <i className="far fa-trash-alt text arrow" style={{cursor : item.isLocked && 'no-drop'}} onClick={() => {
                                                            if (!item.isLocked) {
                                                                this.props.deleteLayer(item);
                                                            }}}
                                                        ></i>
                                                        </div>
                                                    )}
                                                    {/* Your card content here */}
                                                    
                                                    </div>
                                                )}
                                                </Draggable>
                                                )
                                            ))}
                                            {provided.placeholder}
                                            </div>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                    ) : (
                                    <div>No Layers Found</div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                </div>
                <div className="mobilenav" style={{ width: "100%" }}>
                    <TabContent
                        activeTab={activeTab}
                        style={{
                            backgroundColor: "#F1F1F1",
                            border: "0.5px solid grey",
                        }}
                    >
                        <TabPane tabId="1">
                            <Container
                                className="text-editer"
                                style={{ backgroundColor: "#DCF0F7" }}
                            >
                                <Row className="mb-2">
                                    <Col
                                        sm="12"
                                        className={`d-flex justify-content-center`}
                                        style={{
                                            height: "50px",
                                            paddingTop: "5%",
                                            backgroundColor: "#dcf0f7",
                                        }}
                                    >
                                        Click on Text to add on canvas
                                    </Col>
                                </Row>
                                <Row className="mb-2 white-bg grey-border">
                                    <Col align="left">
                                        <h1
                                            className="big-title pt-2"
                                            onClick={() => {
                                                this.addText(
                                                    "heading",
                                                    "Add Heading"
                                                );
                                                this.setActiveTab("");
                                            }}
                                            style={{
                                                border: "1px solid grey",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            Add heading
                                        </h1>
                                    </Col>
                                </Row>
                                <Row className="mb-2 white-bg grey-border">
                                    <Col align="left mb-2">
                                        <h3
                                            className="sub-title pt-2"
                                            onClick={() => {
                                                this.addText(
                                                    "title",
                                                    "Add Title"
                                                );
                                                this.setActiveTab("");
                                            }}
                                            style={{
                                                border: "1px solid grey",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            Add title
                                        </h3>
                                    </Col>
                                </Row>
                                <Row className="white-bg grey-border">
                                    <Col align="left">
                                        <h5
                                            className="text pt-2"
                                            style={{
                                                border: "1px solid grey",
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                            onClick={() => {
                                                this.addText(
                                                    "description",
                                                    "Add Description"
                                                );
                                                this.setActiveTab("");
                                            }}
                                        >
                                            Add description
                                        </h5>
                                    </Col>
                                </Row>
                            </Container>
                        </TabPane>
                        <TabPane tabId="3">
                            <Container className="text-editer">
                                {/* <Nav tabs> */}
                                <Row>
                                    <Col
                                        sm="12"
                                        className={`d-flex justify-content-center`}
                                        style={{
                                            height: "50px",
                                            paddingTop: "5%",
                                            paddingBottom: "5%",
                                            backgroundColor: "#dcf0f7",
                                        }}
                                    >
                                        Image Gallery
                                    </Col>
                                </Row>
                                <TabContent activeTab={this.state.imgactiveTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col
                                                sm="12"
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <p className="first-title">
                                                    &nbsp;
                                                </p>
                                                <div className="dcl-upload-button">
                                                    {" "}
                                                    <label
                                                        htmlFor="gallery-upload"
                                                        className=" dcl-button dcl-button--upload dcl-button--full-width"
                                                        onClick={() => {
                                                            this.props.toggle(
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        Upload Image
                                                    </label>
                                                    <input
                                                        id="gallery-upload"
                                                        type="file"
                                                        onChange={
                                                            this.shapeUpload
                                                        }
                                                        accept="image/*"
                                                        multiple
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                sm="12"
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <div
                                                    id="uploadedImgDiv"
                                                    style={{
                                                        maxHeight: "300px",
                                                        overflow: "scroll",
                                                    }}
                                                >
                                                    {
                                                        <div className="grid">
                                                            {this.props.imggallery.map(
                                                                (
                                                                    message,
                                                                    index
                                                                ) => (
                                                                    <span
                                                                        className="grid-item"
                                                                        key={
                                                                            index +
                                                                            1000
                                                                        }
                                                                    >
                                                                        <img
                                                                            className="image"
                                                                            src={`${HOST_SITE}/${message.imageUrl}`}
                                                                            onClick={() => {
                                                                                this.addImageOnCanvas(
                                                                                    `${HOST_SITE}/${message.imageUrl}`
                                                                                );
                                                                                this.setActiveTab(
                                                                                    ""
                                                                                );
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                        <span
                                                                            className=""
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                left: "-20px",
                                                                                top: "-30px",
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="fas fa-trash"
                                                                                style={{
                                                                                    color: "#6C757D",
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.removeImg(
                                                                                        message.id
                                                                                    )
                                                                                }
                                                                            ></i>
                                                                        </span>
                                                                    </span>
                                                                )
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                                {/* <Modal
                                    show={
                                        this.state.isDPIOpen ||
                                        this.state.isLargeImage
                                    }
                                    onHide={this.closeDPIModal}
                                    size="default"
                                    id="lowDPI"
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header>
                                        <Modal.Title>
                                            {this.state.isDPIOpen ? (
                                                <span
                                                    style={{ fontSize: "24px" }}
                                                >
                                                    Low DPI!
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.isLargeImage ? (
                                                <span
                                                    style={{ fontSize: "24px" }}
                                                >
                                                    Large Image
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Modal.Title>
                                        <div
                                            className=""
                                            style={{
                                                position: "absolute",
                                                marginLeft: "60%",
                                            }}
                                        >
                                            &nbsp;
                                        </div>{" "}
                                        <span
                                            title="close"
                                            style={{ cursor: "pointer" }}
                                            onClick={this.closeDPIModal}
                                        >
                                            {" "}
                                            X
                                        </span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {this.state.isDPIOpen ? (
                                            <p>
                                                <span>
                                                    DPI of an uploaded image is{" "}
                                                    <strong>
                                                        {this.state.imageDPI}
                                                    </strong>
                                                </span>
                                                <span className="text-danger d-inline-block mt-2">
                                                    <strong>Warning:</strong> It
                                                    is recommended to upload an
                                                    image with minimum{" "}
                                                    <strong>
                                                        {DPI_THRESHOLD}
                                                    </strong>{" "}
                                                    DPI
                                                </span>
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.isLargeImage ? (
                                            <p>
                                                <span>
                                                    Width of uploaded image is{" "}
                                                    <strong>
                                                        {
                                                            this.state
                                                                .uploadImageWidth
                                                        }
                                                    </strong>{" "}
                                                    pixels
                                                </span>
                                                <span className="text-danger d-inline-block mt-2">
                                                    <strong>Error:</strong>{" "}
                                                    Image width must be less
                                                    than{" "}
                                                    <strong>
                                                        {IMAGE_MAX_WIDTH}
                                                    </strong>{" "}
                                                    pixels
                                                </span>
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer
                                        style={{ justifyContent: "center" }}
                                    >
                                        <button
                                            type="button"
                                            id="closeDPI"
                                            className={`btn btn-primary`}
                                            onClick={this.closeDPIModal}
                                        >
                                            Ok
                                        </button>
                                    </Modal.Footer>
                                </Modal> */}
                            </Container>
                        </TabPane>
                        <TabPane tabId="4">
                            <Container className="text-editer">
                                <Row className="mb-2">
                                    <Col
                                        sm="12"
                                        className={`d-flex justify-content-center`}
                                        style={{
                                            height: "50px",
                                            paddingTop: "5%",
                                            backgroundColor: "#dcf0f7",
                                        }}
                                    >
                                        Click on Verse to add on canvas
                                    </Col>
                                </Row>
                                <Row className="white-bg ">
                                    <Col
                                        align="left"
                                        className="p-0"
                                        style={{ position: "relative" }}
                                    >
                                        <ListGroup variant="flush">
                                            {VERSES.map((verse, index) => (
                                                <OverlayTrigger
                                                    key={index}
                                                    trigger={["hover", "focus"]}
                                                    placement="right"
                                                    overlay={
                                                        <Popover id="popover-basic">
                                                            <Popover.Header as="h3">
                                                                {verse.title}
                                                            </Popover.Header>
                                                            <Popover.Body>
                                                                <p
                                                                    style={{
                                                                        whiteSpace:
                                                                            "pre-line",
                                                                        textAlign:
                                                                            "center",
                                                                        overflowX:
                                                                            "scroll",
                                                                    }}
                                                                >
                                                                    {verse.data}
                                                                </p>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <button
                                                        className={`button-as-li`}
                                                        style={{
                                                            backgroundColor:
                                                                "white",
                                                            border: "0.5px solid black",
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                            marginTop: "2px",
                                                            marginBottom: "5px",
                                                        }}
                                                        variant="secondary"
                                                        onClick={() => {
                                                            this.addVerse(
                                                                verse.title,
                                                                verse.data
                                                            );
                                                            this.setActiveTab(
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        {verse.title}
                                                    </button>
                                                </OverlayTrigger>
                                            ))}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </TabPane>
                        <TabPane tabId="6">
                            <Container
                                className="text-editer"
                                style={{ height: "30%" }}
                            >
                                <Row className="mb-2">
                                    <Col
                                        sm="12"
                                        style={{
                                            height: "50px",
                                            paddingTop: "5%",
                                            paddingLeft: "30%",
                                            backgroundColor: "#dcf0f7",
                                            paddingBottom: "50px",
                                        }}
                                    >
                                        <span style={{ marginLeft: "10%" }}>
                                            Mask Image
                                        </span>
                                        <button
                                            className="btn btn-light"
                                            style={{
                                                marginLeft: "35%",
                                                marginTop: "0%",
                                            }}
                                            onClick={this.closeMaskingMobile}
                                        >
                                            <i className="fas fa-close fa-1x text-muted"></i>
                                        </button>
                                    </Col>
                                </Row>
                                <Row
                                    className="mb-2 white-bg"
                                    style={{
                                        marginLeft: `${parseInt(
                                            fabric.Canvas.originalWidth / 3
                                        )}px`,
                                        height: "300px",
                                        overflow: "scroll",
                                    }}
                                >
                                    {MASK_IMAGES.map((image, imgIndex) => {
                                        const url = image;
                                        return (
                                            <span key={imgIndex}>
                                                <img
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                    }}
                                                    className="card px-2"
                                                    key={image}
                                                    src={url}
                                                    alt="Mask_Image"
                                                    onClick={(image) => {
                                                        this.addMasking(url);
                                                        this.props.toggle("");
                                                    }}
                                                />
                                            </span>
                                        );
                                    })}
                                </Row>
                            </Container>
                        </TabPane>
                    </TabContent>
                </div>
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="xl"
                    id="modal-content1"
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>Clip Art</span>
                        </Modal.Title>
                        <div
                            className=""
                            style={{ position: "relative", marginLeft: "60%" }}
                        >
                            <input
                                type="text"
                                name="search"
                                value=""
                                id="clipartSearchTerms"
                                placeholder="search clipart"
                                className="clipartSearchTerms"
                            />
                            &nbsp;&nbsp;
                            <button
                                type="button"
                                id="search-clipart"
                                className="btn btn-primary btn-search"
                            >
                                <i className="fa fa-search"></i>
                                &nbsp;&nbsp;Search
                            </button>
                        </div>{" "}
                        <span
                            title="close"
                            style={{ cursor: "pointer" }}
                            onClick={this.closeModal}
                        >
                            X
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div
                                className="row-md-6 "
                                style={{
                                    border: "1px solid #e1e6eb",
                                    width: "32%",
                                    paddingLeft: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#fff",
                                    overflowY: "scroll",
                                    height: "200px",
                                    fontSize: "18px",
                                }}
                            >
                                Categories
                                {clipartData?.categories &&
                                    clipartData.categories?.map(
                                        (obj, offset) => (
                                            <div className="space" key={offset}>
                                                <DropdownButton
                                                    id="category"
                                                    title={obj.type}
                                                >
                                                    {obj.subcategories &&
                                                        obj.subcategories.map(
                                                            (obj2, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    <Dropdown.Item
                                                                        as="button"
                                                                        id="subcategory"
                                                                        className="category"
                                                                        // onClick={() =>
                                                                        //     this.getClipart(
                                                                        //         obj.type,
                                                                        //         obj2.type
                                                                        //     )
                                                                        // }
                                                                    >
                                                                        {
                                                                            obj2.type
                                                                        }
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                </div>
                                                            )
                                                        )}
                                                </DropdownButton>
                                            </div>
                                        )
                                    )}
                            </div>
                            <div
                                className="row-md-6"
                                style={{ width: "68%", paddingLeft: "10px" }}
                            >
                                <div className="parent">
                                    {subcat !== 1 ? (
                                        <label>Results for {subcat}</label>
                                    ) : (
                                        <label>Results for Angel</label>
                                    )}
                                </div>
                                <div className="angel-row">
                                    {clipartImages?.map((name, offset) => (
                                        <div className="child" key={offset}>
                                            <img
                                                src={name.url}
                                                onClick={() =>
                                                    this.addImageOnCanvas(
                                                        name.url
                                                    )
                                                }
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </div>

                                {/* <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}/> */}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* {

          this.state.successElement &&
          <div className="alert alert-success" style={{width:'100%'}}>
            <strong>Success!</strong>&nbsp;&nbsp;&nbsp;New Symptoms has been updated.
           <span style={{color:'black'}}>{resultTitle}</span>
          </div>
        } */}
                        {/* {

      this.state.errElement &&
      <div className="alert alert-danger"  style={{width:'100%'}}>
    <strong>Danger!</strong>&nbsp;&nbsp;&nbsp;New symptoms could not be added.
  </div>
      } */}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default LeftPanel;
